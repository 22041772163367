import { render, staticRenderFns } from "./SavedSearches.vue?vue&type=template&id=50a5f195&scoped=true"
import script from "./SavedSearches.vue?vue&type=script&lang=js"
export * from "./SavedSearches.vue?vue&type=script&lang=js"
import style0 from "./SavedSearches.vue?vue&type=style&index=0&id=50a5f195&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a5f195",
  null
  
)

export default component.exports