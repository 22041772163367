<template>
    <div>
      <h3 style="font-size: 18px; margin-bottom: 16px;">Review Summary & Send</h3>
      <h5 style="font-size: 14px;">Guardian emails</h5>
      <p>9 guardian emails for 6 students</p>
      <h5 style="font-size: 14px; margin-top: 16px;">smartFORMS</h5>
      <p>6 smartFORMS from 1 smartFORM template</p>
      <h5 style="font-size: 14px; margin-top: 16px;">Communication settings</h5>
      <ul>
        <li>smartFORM will be sent in English, not in the student’s home language.</li>
        <li>Do not prompt guardians to acknowledge that they have reviewed the form. </li>
      </ul>
      <h3 style="font-size: 18px; margin-top: 20px;">What happens next?</h3>
      <p>
        Once you email the smartFORMS, it may take a few hours to send all emails.
        <br>
        You will be notified when they have all been sent. You can track which guardians have acknowledged receipt.
      </p>
      <div class="notification">
        <div class="left-side"></div>
        <div class="message">
            <h5>To protect student privacy, links to smartFORMS sent via email will expire after 7 days on {{ expirationDate }}.</h5>
        </div>
      </div>
      <fe-btn
          style="margin-top: 20px; margin-left: -4px;"
          usage="secondary"
          @click="$emit('goToPreview')"
        >
        Back
      </fe-btn>
      <fe-btn
        style="margin-top: 20px; margin-left: -4px;"
        usage="primary"
      >
        Email Forms To 9 Guardians
      </fe-btn>
    </div>
</template>
<script>  
    export default {
      name: 'SendEmail',
      props: {
        expirationDate: {
            type: String,
            required: true
        }
      },
    };
</script>
<style>
  .notification {
      display: flex;
      align-items: center;
  }

  .left-side {
      width: 10px;
      height: 34px;
      background-color: #FFBD00;
  }

  .message {
      background-color: #FFF9E3;
      padding: 10px;
      height: 34px;
      align-items: center;
      font-size: 14px;
  }
</style>
