<template lang="html">
    <fe-student-card v-show="activeStudent.show"
        :studentRecord="activeStudent.rec"
        :imageUrl="imageUrl"
        :modal="activeStudent.modal"
        @close="activeStudent.show = activeStudent.modal = false"
        fullNameField="student_full_name"
        schoolField="school_name"
        gradeField="grade_desc"
        :style="{
            position: 'absolute',
            left: `${left}px`,
            top: `${top}px`,
            padding: '1px 6px 1px 6px',
        }"
    >
        <!-- <code style="overflow:auto;max-height:200px;font-size:8px;">{{JSON.stringify(chips,null,2)}}</code> -->
        <template v-for="item in chips">
            <template v-if="item.type=='tag'">
                <fe-chip v-for="tag in item.filter" :key="tag.value"
                    @mouseover="highlightedFilter=item.filterId" @mouseleave="highlightedFilter=null"
                >
                    <v-icon :color="tag.color">fas fa-tag</v-icon> {{tag.value}}
                </fe-chip>
            </template>

            <template v-else-if="['disability','ethnicity','gender','meal_status'].includes(item.type)">
                <fe-chip v-for="tag in getValidFilters(item)" :key="tag.value"
                    @mouseover="highlightedFilter=item.filterId;log(item.filter,tag)" @mouseleave="highlightedFilter=null"
                >
                    {{tag.value}}
                </fe-chip>
            </template>

            <template v-else-if="!hasExtraValues(item)">
                <fe-chip v-for="tag in item.filter" :key="tag.value+item.type"
                    @mouseover="highlightedFilter=item.filterId" @mouseleave="highlightedFilter=null"
                >
                    {{ [tag.value, changeCase(item.type)].filter(Boolean).join(' : ') }}
                </fe-chip>
            </template>

            <fe-chip v-else
                @mouseover="highlightedFilter=item.filterId" @mouseleave="highlightedFilter=null"
            >
                {{item.text}}
                <template #tooltip >
                    <!-- <v-list two-line dense dark> -->
                        <template v-for="filter in item.filter">
                            <v-list-item
                                v-for="(extraValue, key) in filter.extra_values"
                                :key="getComplexKey(key+extraValue,filter)"
                                dark dense
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ changeCase(key) }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="Array.isArray(extraValue)">
                                            <span v-for="(value, paramIndex) in extraValue" class="mr-1" :key="value.name">
                                                {{ `${value.name}${paramIndex < param.value.length - 1 ? ',' : ''}` }}
                                            </span>
                                        </span>
                                        <span v-else-if="isDecimalValue(extraValue)">
                                            {{ roundNumericValue(extraValue) }}
                                        </span>
                                        <span v-else>
                                            {{ extraValue  }}
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    <!-- </v-list> -->
                </template>
            </fe-chip>
        </template>

        <template #footer>
            <div @click="goToStudentProfile(activeStudent.rec.student_id)">
                <fe-btn>View Student Profile</fe-btn>
            </div>
        </template>
    </fe-student-card>
</template>

<script>
import { mapLocalState } from '@/util/vuexHelper'
import * as Util from '../Util'

export default {
    name: 'StudentCard',
    inject: ['localStore'],

    computed: {
        ...mapLocalState(['params','activeStudent','highlightedFilter']),
        chips() {
            // let chips = Util.studentFilterText(this,this.params.filters, this.studentRec)
            // return chips
            return Util.studentFilterText(this,this.params.filters, this.activeStudent.rec)
        },
        left() {
            if(this.$el) {
                return Math.min(this.activeStudent.x + 15, this.$parent.$el.clientWidth - this.$el.offsetWidth + 7)
            } else {
                return this.activeStudent.x + 15
            }
        },
        top() {
            if(this.$el) {
                return this.activeStudent.y + this.$el.offsetHeight + 40 < this.$parent.$el.clientHeight
                    ? this.activeStudent.y + 40 + this.voff : this.activeStudent.y - this.$el.offsetHeight - 20 + this.voff
            } else {
                return this.activeStudent.y + 40 + this.voff
            }
        },
        voff() {
            return this.activeStudent.modal ? -48 : 0
        }
    },

    mounted() {
        this.throttleUrl = this.$_.throttle(this.setNewImageUrl,350)
    },

    watch: {
        activeStudent(v) {
            if(this.studentRec != v.rec) {
                // we are setting this here so computed chips is not always fired on just position changes
                this.studentRec = v.rec
            }

            this.throttleUrl(
                v.rec && v.rec.student_id
                    ? this.$axios.defaults.baseURL
                    + 'students.php?action=get&property=student_image&id='
                    + v.rec.student_id
                    : null
            )

        }
    },

    methods: {
        log(filter,tag) {},
        getComplexKey(key,filter) {
            if(filter.supporting.form_instance_created) key += filter.supporting.form_instance_created
            return key
        },
        getValidFilters(item) {
            return item.filter.filter(x => x.value)
        },
        changeCase(text) {
            return String(text) && text.includes('_') ? this.$_.startCase(text) : text
        },
        hasExtraValues(item) {
            return item.filter?.length && item.filter[0].extra_values
        },
        setNewImageUrl(v) {
            this.imageUrl = v
        },
        isDecimalValue(v) {
            return v && typeof v == 'string' && !isNaN(parseFloat(v)) && v.indexOf('.') > -1
        },
        roundNumericValue(v) {
            return this.$_.isBlank(v) ? null : (
                isNaN(v) || typeof v == 'boolean' ? String(v) : parseFloat(v).toFixed(2)
            )
        },
        goToStudentProfile(studentId) {
            this.$router.push({ path: `/StudentProfile/${studentId}` })
        },
    },

    data() {
        return {
            imageUrl: null,
            studentRec: null,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
