<template>
    <fe-stepper-filter
        title="District Search" style="height:620px;"
        @apply="next" @close="$emit('close'); clearFilters()"
        :applyDisabled="!isNextValid"
        :applyBtnText="this.view.id==='population'?'Next':'Search'"
    >
        <template #toolbar>
            <stepper-navigation :items="navs" v-model="view" :nextDisabled="!isNextValid"/>
        </template>

        <template #footer>
            <div class="d-flex" style="width: 100%;">
                <div class="mt-3 ml-4" v-html="searchDisplay"></div>
                <v-spacer/>
                <fe-btn v-if="view.id == 'population' && isSearchValid" usage="secondary" @click="emit">Search</fe-btn>
            </div>
        </template>

        <keep-alive>
            <template v-if="view.id=='population'">
                <student-stepper-panel
                    ref="studentStepper"
                    v-model="population"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    byId
                    style="height: 480px;"
                    @selected="checkValid"
                    @searchDisplayChange="searchDisplayPopulation = $event"
                />
            </template>

            <template v-if="view.id=='data'">
                <multi-data-stepper-panel
                    v-model="selections"
                    :surfaceFirstSelections="surfaceFirstSelections"
                    byId
                    style="height: 480px;"
                    @selected="checkValid"
                    @searchDisplayChange="searchDisplayData = $event"
                />
            </template>
        </keep-alive>

        <embargoed-data-agreement
            v-if="showEmbargoAcceptDialog"
            :embargoes="embargoes"
            @accept="onAcceptEmbargo"
        />
    </fe-stepper-filter>
</template>

<script>
    import * as Util from './Util'
    import EmbargoedDataAgreement from '@/components/common/EmbargoedDataAgreement'
    import StudentStepperPanel from '@/components/common/stepper/StudentStepperPanel'
    import AssessmentStepperPanel from '@/components/common/stepper/AssessmentStepperPanel'
    import IncidentStepperPanel from '@/components/common/stepper/IncidentStepperPanel'
    import MultiDataStepperPanel from '@/components/common/stepper/MultiDataStepperPanel'
    import StepperNavigation from './StepperNavigation'
    import hybridMixin from './mixins/hybrids'

    export default {
        name: 'DistrictStepper',

        mixins: [hybridMixin],

        components: {
            EmbargoedDataAgreement,
            StudentStepperPanel,
            AssessmentStepperPanel,
            IncidentStepperPanel,
            MultiDataStepperPanel,
            StepperNavigation
        },

        props: {
            state: {
                type: Object,
                required: false
            },
            byId: {
                type: Boolean,
                default: true
            },
            value: {
                type: Object,
                default: null
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            // Useful for pre-checking attendance toggle when editing an existing saved search
            includeAttendance: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            'view.id'(v, prevVal) {
                if (prevVal == 'population') {
                    this.population = {
                        ...this.population,
                        ...this.$refs.studentStepper.getSubmitParams(true) // only want the filters - this.population holds school year, school et al. already
                    }
                }

                this.checkValid()
            },
            population: {
                deep: true,
                handler(v) {
                    this.format()
                }
            }
        },
        data() {
            return {
                population: {
                    advancedFilters: {}
                },
                formattedParams: {},
                view: {
                    id: ''
                },
                navs: [{
                    id: 'population',
                    name: 'Student Population'
                }, {
                    id: 'data',
                    name: 'Data Types'
                },],
                selections: {
                    assessment: {
                        school_year_id: [],
                        category_id: [],
                        data_point_type_id: [],
                        sub_category_id: [],
                        data_point_name_id: [],
                    },
                    incident: {
                        incident_behavior_type_id: [],
                        incident_behavior_id: [],
                        incident_response_id: [],
                    },
                    attendance: false,
                },
                searchDisabled: true,
                allowNext: false,
                searchDisplayPopulation: '<b>Select School Year</b>',
                searchDisplayData: '<b>Select Categories</b>',
                showEmbargoAcceptDialog: false,
                embargoes: [],
            }
        },
        computed: {
            formValid() {
                return false
            },
            schoolYearId() {
                return this.population.school_year?.id
            },
            searchDisplay() {
                if (this.view.id == 'population') {
                    return this.searchDisplayPopulation
                } else {
                    return this.searchDisplayData
                }
            },
            isPopulationValid() {
                if (!this.population?.school_year_id || !this.population?.school_id?.length || !this.population?.grade_id?.length) {
                    return false
                } else {
                    return true
                }
            },
            isDataValid() {
                let sel = this.selections
                let assmt = sel.assessment?.category_id?.length && sel.assessment?.data_point_type_id?.length && sel.assessment?.sub_category_id?.length && sel.assessment?.data_point_name_id?.length
                let inc = sel.incident?.incident_behavior_type_id?.length && sel.incident?.incident_behavior_id?.length && sel.incident?.incident_response_id?.length

                return (inc || assmt || this.selections.attendance)
            },
            isNextValid() {
                return (this.view.id == 'population' && this.isPopulationValid) || (this.view.id != 'population' && this.isSearchValid)
            },
            isSearchValid() {
                return this.isPopulationValid && this.isDataValid
            },
        },

        mounted() {
            this.view = this.navs[0]
            this.loadBase()

            this.population = this.$_.merge(
                this.population,
                this.value?.populationParams,
                {
                    advancedFilters: {
                        ...this.value?.advancedFilterParams,
                        // For whatever reason, this ell value is different on backend/FE model
                        ell: this.value?.searchParams?.ell_flag || undefined,
                        demographics: Util.buildDemographicsObject(this.value?.advancedFilterParams),
                    }
                }
            )

            this.selections.assessment = this.$_.merge(this.selections.assessment, this.value?.assessmentParams)
            this.selections.incident = this.$_.merge(this.selections.incident, this.value?.incidentParams)
            this.selections.attendance = this.includeAttendance || this.selections.attendance
        },
        methods: {
            checkValid() {
                if (this.view.id == 'population') {
                    this.searchDisabled = !this.isPopulationValid
                } else if (this.view.id == 'data') {
                    this.searchDisabled = !this.isDataValid
                }
            },

            clearFilters() {
                this.population={}
                this.assessment={}
                this.incident={}
                this.view='population'
            },

            next() {
                switch (this.view.id) {
                    case 'population':
                        this.view = this.navs[1]
                        break
                    default:
                        this.emit()
                        break
                }
            },

            loadBase() {
                this.dialog=true

                // hybrid demographics
                this.loadEll()
                this.loadEthnicities()
                this.loadGenders()
                this.loadDisabilities()
                this.loadMealStatus()
            },

            format() {
                let assessment = this.selections.assessment
                let incident   = this.selections.incident
                let attendance = this.selections.attendance

                // population already has _id on everything thanks to StudentStepper naming conventions
                let p = this.population
                if (p.advancedFilters.disability_type_id && p.advancedFilters.disability_type_id[0].allIds) {
                    p.advancedFilters.disability_type_id = p.advancedFilters.disability_type_id[0].allIds
                    p.advancedFilters.disability_code = 'ALLDIS' // use for stepper display if All Disabilities was chosen
                }
                if (p.advancedFilters.meal_status_id && p.advancedFilters.meal_status_id[0].allIds) {
                    p.advancedFilters.meal_status_id = p.advancedFilters.meal_status_id[0].allIds
                    p.advancedFilters.status_code = 'ALLSTATUS' // use for stepper display if Any Status was chosen
                }
                if (p.advancedFilters.intervention_level && p.advancedFilters.intervention_level[0].allIds) {
                    p.advancedFilters.intervention_level = p.advancedFilters.intervention_level[0].allIds
                    p.advancedFilters.intervention_code = 'ALLINT' // use for stepper display if All Interventions was chosen
                }

                // HYBRID DEMOS BACKEND PAYLOAD
                // make sure we're sending values to BE for queries (not groups or display_names)
                // ETHNICITY
                if (p.advancedFilters.ethnicity) {
                    let ethnicities = ''
                    let selected = p.advancedFilters.ethnicity.split(',')
                    this.ethnicity.forEach(x => {
                        if (selected.indexOf(x.display_name) > -1 || selected.indexOf(x.value) > -1) {
                            if (x.display_name_group) {
                                x.display_name_group.forEach(n => {
                                    if (ethnicities.indexOf(n) === -1) {
                                        ethnicities += n + ',' // add name if it doesn't exist in list
                                    }
                                })
                            } else {
                                ethnicities += x.value + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = ethnicities.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.ethnicity = tmp.slice(0, -1).join(',')
                }
                // GENDER
                if (p.advancedFilters.gender) {
                    let genders = ''
                    let selected = p.advancedFilters.gender.split(',')
                    this.gender.forEach(x => {
                        if (selected.indexOf(x.display_name) > -1 || selected.indexOf(x.value) > -1) {
                            if (x.display_name_group) {
                                x.display_name_group.forEach(n => {
                                    if (genders.indexOf(n) === -1) {
                                        genders += n + ',' // add name if it doesn't exist in list
                                    }
                                })
                            } else {
                                genders += x.value + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = genders.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.gender = tmp.slice(0, -1).join(',')
                }
                // DISABILITY
                if (
                    p.advancedFilters.disability_type_id &&
                    p.advancedFilters.disability_code !== 'ALLDIS' &&
                    p.advancedFilters.disability_code !== 'NODIS' &&
                    p.advancedFilters.disability_type_id[0].id !== -1 &&
                    p.advancedFilters.disability_type_id[0].id !== 0
                ) {
                    let disabilities = ''
                    let selected = p.advancedFilters.disability_type_id.split(',')
                    this.disability.forEach(x => {
                        if (selected.indexOf(x.id.toString()) > -1) {
                            if (x.display_name_group) {
                                disabilities += x.display_name_group.toString() + ','
                            } else {
                                disabilities += x.id + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = disabilities.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.disability_type_id = tmp.slice(0, -1).join(',')
                } else if (p.advancedFilters.disability_code === 'ALLDIS' || (p.advancedFilters.disability_type_id && p.advancedFilters.disability_type_id[0].code === 'ALLDIS')) {
                    p.advancedFilters.disability_code = 'ALLDIS'
                }
                // ELP LEVEL
                if (p.advancedFilters.ell_level_id) {
                    let levels = []
                    let selected = p.advancedFilters.ell_level_id
                    this.elpLevel.forEach(x => {
                        if (selected.indexOf(x.id) > -1) {
                            if (x.display_name_group) {
                                levels = levels.concat(x.display_name_group)
                            } else {
                                levels.push(x.id)
                            }
                        }
                    })
                    levels = [...new Set(levels)] // remove duplicates
                    p.advancedFilters.ell_level_id = levels.slice().join(',') // .slice.join prevents infinite loop
                }
                // MEAL STATUS
                if (
                    p.advancedFilters.meal_status_id &&
                    p.advancedFilters.status_code !== 'ALLSTATUS' &&
                    p.advancedFilters.status_code !== 'NOSTATUS' &&
                    p.advancedFilters.meal_status_id[0].id !== -1 &&
                    p.advancedFilters.meal_status_id[0].id !== 0
                ) {
                    let status = ''
                    let selected = p.advancedFilters.meal_status_id.split(',')
                    this.mealStatus.forEach(x => {
                        if (selected.indexOf(x.id.toString()) > -1) {
                            if (x.display_name_group) {
                                status += x.display_name_group.toString() + ','
                            } else {
                                status += x.id + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = status.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.meal_status_id = tmp.slice(0, -1).join(',')
                }

                // the other data requires formatting with an _id on the end to
                // match param code values (e.g. DATA_POINT_TYPE_ID) on the backend
                let i = {
                    incident_behavior_type_id: incident?.incident_behavior_type_id || [],
                    incident_behavior_id: incident?.incident_behavior_id || [],
                    incident_response_id: incident?.incident_response_id || []
                }

                let a = {
                    school_year_id: p.school_year_id,
                    category_id: assessment?.category_id || [],
                    data_point_type_id: assessment?.data_point_type_id || [],
                    sub_category_id: assessment?.sub_category_id || [],
                    data_point_name_id: assessment?.data_point_name_id || []
                }

                return {
                    population: p,
                    assessment: (a.category_id.length>0 || a.data_point_type_id.length>0 || a.sub_category_id.length > 0) ? a : null,
                    incident: i.incident_behavior_type_id.length > 0 || i.incident_behavior_id.length > 0 ? i : false,
                    attendance: attendance
                }
            },

            emit() {
                let params = this.format()
                if (this.$hasSecurity('VIEW_EMBARGOED_DATA') && params.assessment) {
                    this.$axios.get(`targetScores.php?action=get&property=data_point_embargoes&` + this.$objectToParams(params.assessment)).then(res => {
                        if (res.data.data_point_embargo?.length) {
                            this.showEmbargoAcceptDialog = true
                            this.embargoes = res.data.data_point_embargo
                        } else {
                            this.doSearch()
                        }
                    })
                } else {
                    this.doSearch()
                }
            },

            onAcceptEmbargo() {
                this.showEmbargoAcceptDialog = false
                this.doSearch()
            },

            doSearch() {
                let assessment = this.selections.assessment
                let incident   = this.selections.incident
                let attendance = this.selections.attendance

                if (this.state?.windowUUID) {
                    this.$store.commit('global/removeDockedWindow', this.$store.getters['global/dockableWindows'].find(w => w.uuid === this.state?.windowUUID))
                }

                this.$emit('apply', this.format(), this.state)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
