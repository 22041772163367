<template>
    <div class="d-flex flex-wrap" v-observe-visibility="visibilityChanged">
        <fe-filter-btn
            title="Cohort Year"
            :items="schoolYears"
            rootProperty="years"
            itemValue="id"
            itemText="name"
            :multiple="false"
            closeOnSelect
            v-model="localValue.cohort_school_year_id"
            @input="updateYear"
            ref="cohortYearFilter"
            showFirstSelection
        />

        <fe-filter-btn
            ref="userFilter"
            title="User"
            v-if="scope == 'Class'"
            :url="teacherURL"
            v-model="localValue.user_id"
            itemValue="user_id"
            itemText="user_full_name"
            rootProperty="courses"
            :multiple="false"
            showFirstSelection
            buffered
        />

        <fe-filter-btn
            ref="courseFilter"
            title="Course"
            v-if="scope == 'Class'"
            :disabled="!cohortYear.id || !lv.user_id.included.length"
            v-model="localValue.course_id"
            :items="classList"
            rootProperty="courses"
            itemValue="course_id"
            itemText="course_name"
            :closeOnSelect="false"
            showFirstSelection
        />

        <fe-filter-btn
            ref="periodFilter"
            title="Period"
            v-if="scope == 'Class'"
            :disabled="!cohortYear.id || !lv.user_id.included.length || !lv.course_id.included.length"
            :url="cohortYear.id && lv.user_id.included.length && lv.course_id.included.length ? periodURL : null"
            v-model="localValue.period"
            itemValue="period"
            itemText="period"
            :closeOnSelect="false"
            rootProperty="courses"
            showFirstSelection
        />

        <fe-filter-btn
            ref="schoolFilter"
            v-if="scope!='Class'"
            :title="scope=='District' || scope=='Grade' ? 'All Schools' : 'School'"
            multipleTitle="Schools"
            :url="$models.getUrl('school', 'read')+'&incl_groups=1&school_year_id='+cohortYear.id"
            rootProperty="schools"
            itemValue="id"
            itemText="name"
            :closeOnSelect="false"
            :multiple="scope!='Class'?true:false"
            :disabled="!localValue.cohort_school_year_id.included.length"
            v-model="localValue.cohort_school_id"
            showFirstSelection
        />

        <fe-filter-btn
            ref="gradeFilter"
            v-if="scope!='Class'"
            :title="scope=='District' ? 'All Grades' : 'Grade'"
            multipleTitle="Grades"
            :url="$models.getUrl('grade', 'read')+`&school_year_id=${cohortYear.id}&school_id=${cohortSchool}`"
            rootProperty="grades"
            itemValue="id"
            itemText="name"
            :closeOnSelect="scope === 'Grade' ? true : false"
            :multiple="scope === 'District' ? true : false"
            :disabled="(scope === 'Grade' ? !lv.cohort_school_year_id.included.length : false)"
            v-model="localValue.cohort_grade_id"
            showFirstSelection
        />

        <v-divider
            class="mx-2"
            inset
            vertical
        />

        <fe-filter-btn
            v-if="scope != 'District'"
            ref="yearFilter"
            title="Assessment Year"
            :items="schoolYears"
            rootProperty="years"
            itemValue="id"
            itemText="name"
            multiple
            :closeOnSelect="false"
            v-model="localValue.school_year_id"
            showFirstSelection
        />

        <fe-filter-btn
            title="Categories"
            :items="categories"
            rootProperty="category"
            itemValue="id"
            itemText="name"
            :closeOnSelect="false"
            v-model="localValue.category_id"
            showFirstSelection
        >
            <template v-slot:extra="{item}">
                <fe-tooltip tooltip="There are some incident types that have been restricted from your visibility by your district.">
                    <v-icon v-if="item.id == 'incidents' && hasRestrictedIncidentTypes" style="margin-left: 125px;">far fa-exclamation-circle</v-icon>
                </fe-tooltip>
            </template>
        </fe-filter-btn>

        <fe-filter-btn
            ref="fltrAssessments"
            title="Assessment Suite"
            :url="$models.getUrl('assessment', 'read')+'&hidden=0&property=assessments'"
            itemValue="id"
            itemText="name"
            :closeOnSelect="false"
            v-model="localValue.data_point_type_id"
            showFirstSelection
        />

        <v-divider
            class="mx-2"
            inset
            vertical
        />


        <fe-btn usage="secondary" @click="showAdvanced=true">Filters</fe-btn>

        <fe-dialog
            title="More Filters"
            v-if="showAdvanced"
            @accept="() => {}"
            @close="showAdvanced=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Apply"
            width="640"
        >
            <advanced-filters
                :schoolGroups="schoolGroups"
                v-model="advanced"
                :activeStatus="scope==='Class' ? 'Active' : null"
                @result="updateAdvanced"
                :resetEntry="false"
                :queryParams="queryParams"
            />
        </fe-dialog>


        <fe-btn usage="primary" :disabled="!canSearch" @click="doSearch">Search</fe-btn>

        <embargoed-data-agreement
            v-if="showEmbargoAcceptDialog"
            :embargoes="embargoes"
            @accept="onAcceptEmbargo"
        />

        <fe-crud
            v-if="scope == 'Class' && cohortYear.id && lv.user_id.included.length"
            ref="classCrud"
            autoload
            :config="classCrudConfig"
            :failOnEmptyResponse="false"
            :readParams="{school_year_id: cohortYear.id, user_id: lv.user_id.included[0].user_id}"
            @read="classList = $event.length > 1 ? $_.sortBy($event, ['course_name']) : $event"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import AdvancedFilters from '@/components/common/AdvancedFilters'
    import EmbargoedDataAgreement from '@/components/common/EmbargoedDataAgreement'
    import advancedFiltersMixin from '@/mixins/advancedFilters'

    export default {
        name: 'SummarySearchBar',
        components: {
            AdvancedFilters,
            EmbargoedDataAgreement,
        },
        mixins: [ advancedFiltersMixin ],
        props: {
            value: {
            },
            scope: {
                type: String,
                default: 'District'
            }
        },
        computed: {
            ...mapState('global', ['currentYear', 'userPreferences', 'sessionUser']),
            lv() {
                return this.localValue
            },
            canSearch() {
                let valid = true
                this.requiredFields[this.scope].forEach(f => {
                    if (!this.lv[f].included.length) valid = false
                })
                return valid
            },
            cohortYear() {
                if (this.lv.cohort_school_year_id?.included.length) {
                    return this.lv.cohort_school_year_id.included[0]
                }
                return {}
            },
            cohortSchool() {
                if (this.lv.cohort_school_id?.included.length) {
                    return this.lv.cohort_school_id.included.map(r=>r.id).join(',')
                }
                return null
            },
            cohortGrade() {
                if (this.lv.cohort_grade_id?.included.length) {
                    return this.lv.cohort_grade_id.included.map(r=>r.id).join(',')
                }
                return null
            },
            cohortCourse() {
                if (this.lv.course_id?.included.length) {
                    return this.lv.course_id.included.map(r=>r.course_id).join(',')
                }
                return {}
            },
            schoolGroups() {
                if (this.lv.cohort_school_id?.included.length) {
                    return this.lv.cohort_school_id.included.filter(r=>r.school_group_id!=null).length ? true : false
                }
            },
            teacherURL() {
                if (this.cohortYear.id) {
                    return 'courseHistory.php?action=get&property=user_id&school_year_id=' + this.cohortYear.id
                }
                return null
            },
            courseURL() {
                if (this.cohortYear.id && this.lv.user_id?.included?.length) {
                    return 'courseHistory.php?action=get&property=course_id&school_year_id=' + this.cohortYear.id + '&user_id=' + this.lv.user_id.included[0].user_id
                }
                return null
            },
            periodURL() {
                if (this.cohortYear.id && this.lv.user_id?.included?.length && this.lv.course_id?.included?.length) {
                    return 'courseHistory.php?action=get&property=period&school_year_id=' + this.cohortYear.id + '&user_id=' + this.lv.user_id.included[0].user_id + '&course_id=' + this.cohortCourse
                }
                return null
            },
            hasRestrictedIncidentTypes() {
                return (!!this.sessionUser.user?.has_restricted_incident_types)
            },
            getAdvancedDems() {
                return this.advancedDemographics
            },
            queryParams() {
                let params = {
                    school_year_id: this.cohortYear.id,
                    school_id: this.cohortSchool,
                    grade_id: this.cohortGrade
                }
                return params
            },
            classCrudConfig() {
                if (this.cohortYear.id && this.lv.user_id?.included.length) {
                    let cfg = this.$_.cloneDeep(this.$models.courseList)
                    cfg.read.params.school_year_id = this.cohortYear.id
                    cfg.read.params.user_id = this.lv.user_id.included[0].user_id
                    return cfg
                }
            },
        },
        watch: {
            value(v) {
                this.localValue = v
            },
            localValue(v) {
                this.$emit('input', v)
            },
            'localValue.cohort_school_year_id'(v) {
                if (v?.included.length) {
                    if ((this.scope == 'District' || this.scope == 'Grade' )) {
                        if (this.localValue.cohort_school_id?.included) {
                            this.$refs.schoolFilter.clearSelections()
                            this.$nextTick(() => {
                                this.selectDefaultSchool()
                            })
                        }
                        if (this.localValue.cohort_grade_id?.included) this.$refs.gradeFilter.clearSelections()
                    }
                    if (this.scope == 'Class') {
                        if (this.localValue.user_id?.included) this.$refs.userFilter.clearSelections()
                        if (this.localValue.course_id?.included) {
                            this.$refs.courseFilter.clearSelections()
                            this.classList = []
                        }
                        if (this.localValue.period?.included) this.$refs.courseFilter.clearSelections()
                    }
                }
            },
            'localValue.cohort_school_id'(v) {
                if (!v.included.length && this.localValue.cohort_grade_id?.included?.length) this.$refs.gradeFilter.clearSelections()
            },
            'localValue.user_id'(v) {
                if (v?.included.length) {
                    if (this.localValue.course_id?.included) this.$refs.courseFilter.clearSelections()
                    if (this.localValue.period?.included) this.$refs.periodFilter.clearSelections()
                    if ((this.cohortYear.id || this.localValue.cohort_school_year_id?.included.length) && this.scope == 'Class' && this.classList.length) this.$nextTick(() => this.$refs.classCrud.read())
                }
            },
            'localValue.course_id'(v) {
                if (v.included.length && this.localValue.period?.included) this.$refs.periodFilter.clearSelections()
            },
            'userPreferences.DEFAULT_SCHOOL'(v) {
                this.selectDefaultSchool()
            },
        },
        data() {
            return {
                loaded: false,
                advanced: {
                    group_by: {
                        school:  true,
                        grade: false,
                        gender: true,
                        ethnicity: true
                    },
                    incl_hidden_data_point: false,
                    student_active_flag: null,
                    student_active_date: null,
                },
                categories: [],
                showAdvanced: false,
                requiredFields: {
                    District: ['cohort_school_year_id'],
                    Grade: ['cohort_school_year_id', 'cohort_grade_id', 'school_year_id'],
                    Class: ['user_id', 'course_id', 'cohort_school_year_id']
                },
                localValue: {
                    school_year_id: {
                        included: []
                    }
                },
                schoolYears: [],
                showEmbargoAcceptDialog: false,
                embargoes: [],
                advancedDemographics: [],
                classList: [],
                demographicOptions: [],
            }
        },
        created() {
            this.localValue=this.value

            this.setDefaults()
        },
        mounted() {
            let me = this
            this.$modelGet('category').then(r => {
                this.categories = r.concat([{id: 'incidents', name: 'Incidents'}, { id: 'attendance', name: 'Attendance'}])
            })
            this.$modelGet('demographicOption').then(r => {
                this.demographicOptions = r
            })
            this.$log('search-bar scope: ', this.scope)
            if (this.scope=='Class') {
                this.advanced.student_active_flag = { id: 1 }
            } else {
                this.advanced.student_active_flag = { id: null }
            }

            this.$modelGet('schoolYear').then(r => {
                this.schoolYears = r

                // This is needed to handle the case when a school year is not active for the current date
                let curr = r.find(yr => yr.id===this.currentYear.id)

                if (curr) {
                    if (this.$refs.yearFilter) {
                        this.$refs.yearFilter.clearSelections()
                        this.$refs.yearFilter.selectItem(curr, true)
                    }

                    if (this.$refs.cohortYearFilter) {
                        this.$refs.cohortYearFilter.clearSelections()
                        this.$refs.cohortYearFilter.selectItem(curr, true)
                    }
                }
                this.loaded=true
            })

            this.selectDefaultSchool()
            this.advancedDemographics = []
        },
        methods: {
            visibilityChanged(visible, entry) {
                if (visible) this.$refs.fltrAssessments.queryUrl()
            },

            getGroupBy(group) {
                let p = {
                    group_by: [],
                    chart_group_by: []
                }
                let chartArr = ['school', 'schoolgroup']
                let groupArr = ['ethnicity', 'school', 'grade', 'gender', 'schoolgroup']

                chartArr.forEach(a => {
                    if (group[a]===false) {
                        p.chart_group_by.push(a)
                    }
                })

                groupArr.forEach(a=> {
                    if (group[a]===false) {
                        p.group_by.push(a)
                    }
                })

                return p
            },

            getSearchObject() {
                let noIds = ['ethnicity', 'gender']

                let p = {}
                Object.keys(this.localValue).forEach(field=> {
                    if (field==='user_id') {
                        p[field] = this.localValue[field].included.map(r=>r.user_id)
                    } else if (field==='course_id') {
                        p[field] = this.localValue[field].included.map(r=>r.course_id)
                    } else if (field==='period') {
                        let string = ''

                        this.localValue[field].included.forEach(r => {
                            if (string === '') string = r.period
                            else string += '||' + r.period
                        })
                        p[field] = string
                    } else {
                        p[field] = this.localValue[field].included.map(r=>r.id)
                    }

                })

                // Class tab defaults to
                if (this.scope === 'Class' && !this.advanced.student_active_flag) {
                    this.advanced.student_active_flag = { id: 1 }
                }

                Object.keys(this.advanced).forEach(field => {
                    let id = 'id'
                    if (field === 'gender' || field === 'ethnicity') {
                        id = 'value'
                    } else if (noIds.indexOf(field) > -1) {
                        id = field
                    }

                    if (field === 'demographics') {
                        // if exists, make sure we send all members of display_name_group
                        let demos = this.advanced.demographics
                        if (demos) {
                            for (let d in demos) {
                                let id = d.split('_')[1]
                                let option = this.demographicOptions.find(x => x.demographic_id == id && x.id == demos[d])
                                if (option && option.display_name_group) {
                                    demos[d] = option.display_name_group.toString()
                                }
                            }
                        }
                        Object.assign(p, demos)
                    } else if (Array.isArray(this.advanced[field])) {
                        let af = this.$_.cloneDeep(this.advanced[field])
                        let fieldArr = []
                        af.forEach(a => {
                            // handle display name groups
                            if (a?.display_name_group) {
                                let dg = a.display_name_group
                                dg.forEach(g => {
                                    a.rcExclude ? fieldArr.push("!" + g) : fieldArr.push(g)
                                })
                            } else {
                                let arr = a.rcExclude ? "!" + a[id] : a[id]
                                fieldArr.push(arr)
                            }
                        })
                        p[field] = fieldArr
                    } else if (field === 'group_by') {
                        p = {...p, ...this.getGroupBy(this.advanced[field])}
                    } else if (field === 'student_active_flag' && this.advanced.student_active_flag) {
                        p.student_active_flag = this.advanced.student_active_flag.id
                    } else if (field === 'student_active_date' && this.advanced.student_active_date) {
                        p.student_active_date = this.advanced.student_active_date
                    } else if (field == 'ell_flag') {
                        p[field] = this.advanced[field]
                    } else if (field == 'incl_hidden_data_point' && this.advanced.incl_hidden_data_point) {
                        p[field] = 1 //this.advanced[field]
                    }
                })

                Object.keys(p).forEach(key => {
                    if (key != 'student_active_flag' && !p[key] || (Array.isArray(p[key]) && p[key].length===0)) {
                        delete p[key]
                    }
                })

                return p
            },

            updateYear(v) {
                if (this.loaded && v.included && this.scope != 'District') {
                    // this.localValue.school_year_id.included = [v]
                    this.$refs.yearFilter.clearSelections()
                    this.$refs.yearFilter.selectItem(v.included[0], true)
                }
            },

            setDefaults() {
                let arr = ['cohort_school_year_id', 'cohort_school_id', 'cohort_grade_id', 'category_id', 'data_point_type_id', 'school_year_id', 'user_id', 'course_id','period']
                arr.forEach(f => {
                    if (!this.localValue[f]) {
                        this.$set(this.localValue, f, { included: [] })
                        if (f == 'cohort_school_id') {
                            this.selectDefaultSchool()
                        }
                    }
                })

                // if (!this.lv.cohort_school_year_id.included.length && this.activeSchoolYear) {
                //     this.lv.cohort_school_year_id.included.push(this.activeSchoolYear)
                //     if (this.scope != 'District') this.lv.school_year_id.included = [this.activeSchoolYear]
                // }
            },

            selectDefaultSchool() {
                if (this.$refs.schoolFilter) {
                    if (this.$refs.schoolFilter.value.included.length > 0) {
                        // Refuse to update selected school with default if a selection
                        // already exists (even if it's the previous default?)
                        return
                    }

                    let pref = this.userPreferences.DEFAULT_SCHOOL;
                    if (pref && pref.user_value) {
                        this.$modelGet('school', { id: pref.user_value, incl_groups: 1 }).then(r => {
                            let school = r.find(item => item.id == pref.user_value)

                            if (school) {
                                this.$refs.schoolFilter.clearSelections()
                                this.$refs.schoolFilter.selectItem(school, true)
                            }
                        })
                    }
                }
            },

            onAcceptEmbargo() {
                this.showEmbargoAcceptDialog = false
                this.$emit('search', this.lv)
            },

            doSearch() {
                // Commenting out till we decide what we want to do for larger districts
                // if (this.scope == 'District' && !this.lv.cohort_school_id.included.length) {
                //     return this.$errorPrompt({
                //             title: 'Please refine your search',
                //             message: 'The search is too broad. Please select additional search criteria to narrow your results.',
                //         })
                // }
                if (this.$hasSecurity('VIEW_EMBARGOED_DATA')) {
                    this.$axios.get(`targetScores.php?action=get&property=data_point_embargoes&` + this.$objectToParams(this.getSearchObject())).then(res => {
                        if (res.data.data_point_embargo?.length) {
                            this.showEmbargoAcceptDialog = true
                            this.embargoes = res.data.data_point_embargo
                        } else {
                            this.$emit('search', this.lv)
                        }
                    })
                } else {
                    this.$emit('search', this.lv)
                }
            },

            updateAdvanced(demographic, val) {
                let value = val && val.entry ? val.entry : null
                let found = this.advancedDemographics.find(dem => dem.id == demographic.id)
                if (!value) {
                    // if null (removed from filters), remove from advancedDemographics
                    if (found) {
                        this.advancedDemographics = this.advancedDemographics.filter(dem => dem.id != demographic.id)
                        return
                    }
                }
                let dems = this.$store.getters['global/demographicsSearchable']
                if (demographic.value_type == 'boolean') {
                    // change 0,1 to No,Yes
                    value = value && value == 1 ? 'Yes' : 'No'
                } else if (demographic.value_type == 'option') {
                    // change number values to strings
                    let options = dems.find(dem => dem.id == demographic.id).options
                    let values = value.split(',')
                    let newVal = ''
                    values.forEach(val => {
                        let option = options.find(o => o.id == val)
                        if (!option.display_name_group) {
                            newVal += (option.display_name || option.value) + ','
                        } else {
                            // don't include any duplicate aliases
                            if (newVal.indexOf(option.display_name) === -1) {
                                newVal += option.display_name + ','
                            }
                        }
                    })
                    newVal = newVal.substring(0, newVal.length - 1);
                    value = newVal
                }
                // if already in array, update value; else push
                if (found) found.value = value
                else this.advancedDemographics.push({id: demographic.id, name: demographic.name, value: value})
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
