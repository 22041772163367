<template>
    <div>
      <h3 style="font-size: 18px; margin-bottom: 4px;">Communication Settings</h3>
      <div>Choose to translate forms and have guardians acknowledge reviewing forms.</div>
      <div>
        <v-checkbox class="mt-4" label="Translate the linked smartFORMS into the student’s home language, if other than English."></v-checkbox>
        <v-checkbox label="Prompt guardians to acknowledge they have reviewed each form. "></v-checkbox>
      </div>
      <fe-btn
          style="margin-top: 20px; margin-left: -4px;"
          usage="secondary"
          @click="$emit('goToReview')"
        >
        Back
      </fe-btn>
      <fe-btn
        style="margin-top: 20px; margin-left: -4px;"
        usage="primary"
        @click="$emit('goToPreview')"
      >
        Continue
      </fe-btn>
    </div>
  </template>
  
  <script>  
  export default {
    name: 'CommunicationSettings',
  };
  </script>
  
  