<template>
    <div class="flex-fill flex-column no-scroll" style="position:relative; width: auto;">
        <fe-grid
            ref="grid"
            class="flex-grow-1 grid smartforms-student-forms-list-grid"
            domLayout="normal"
            :showToolbar="true"
            :columnDefs="headers"
            :showAddRowBtn="false"
            :showFullscreen="false"
            :showSaveAsDefault="false"
            :gridOptionOverrides="gridOptions"
            :csvExportProcessCallback="exportData"
            :bordered="false"
            :showDensityToolbar="false"
            :emptyState="{
                text: 'No smartFORMS found',
                imageSrc: '/images/empty-state-horn.png',
                imageStyle: 'max-width:50%; max-height:50%;',
            }"
            displayDensity="medium"
            @selectionChanged="doSelection"
            @rowDoubleClicked="loadItem"
            @search="loadData"
            @searchColumn="loadData"
            :frameworkComponents="frameworkComponents"
            @cellClicked="doCellClick"
            @gridReady="onGridReady"
            style="height: calc(100vh - 300px)"
        >
            <template #after-search-toolbar-items>
                <div class="d-flex">
                    <v-sheet :max-width="$vuetify.breakpoint.width > 1500 ? 900 : $vuetify.breakpoint.width < 1500 && $vuetify.breakpoint.width > 1270 ? 600
                        : $vuetify.breakpoint.width < 1270 && $vuetify.breakpoint.width > 1000 ? 400 : 200">
                        <v-slide-group :show-arrows="!$vuetify.breakpoint.width > 1500">
                            <v-slide-item v-slot="{ active, toggle }">
                                <form-date-filter
                                    class="moveup"
                                    v-model="filters.date"
                                    @input="onFilterDate"
                                />
                            </v-slide-item>
                            <v-slide-item v-slot="{ active, toggle }">
                                <fe-filter-btn
                                    v-intersect="refreshTemplates"
                                    class="moveup"
                                    title="Form Template"
                                    :items="templates"
                                    itemText="name"
                                    itemValue="id"
                                    :value="filters.templates"
                                    @input="onFilterTemplate"
                                    multiple :closeOnSelect="false"
                                >
                                    <template #extra="{item}">
                                        <div v-if="item.disabled" class="inactive ml-auto" >Inactive</div>
                                    </template>
                                </fe-filter-btn>
                            </v-slide-item>
                            <v-slide-item v-slot="{ active, toggle }">
                                <fe-filter-btn
                                    class="moveup"
                                    title="Form Status"
                                    :items="statuses"
                                    itemText="name"
                                    itemValue="id"
                                    :value="filters.status"
                                    @input="onFilterStatus"
                                    multiple :closeOnSelect="false"
                                >
                                </fe-filter-btn>
                            </v-slide-item>
                            <v-slide-item v-slot="{ active, toggle }">
                                <fe-filter-btn
                                    class="moveup"
                                    title="School"
                                    itemText="name"
                                    itemValue="id"
                                    :value="filters.school"
                                    @input="onFilterSchool"
                                    multiple :closeOnSelect="false"
                                    :url="$models.getUrl('school', 'read')"
                                    rootProperty="schools"
                                >
                                </fe-filter-btn>
                            </v-slide-item>
                            <v-slide-item v-slot="{ active, toggle }">
                                <fe-filter-btn
                                    class="moveup"
                                    title="Grade"
                                    itemText="name"
                                    itemValue="id"
                                    :value="filters.grade"
                                    @input="onFilterGrade"
                                    multiple :closeOnSelect="false"
                                    :url="$models.getUrl('grade', 'read')+'&school_id=['+filters.school.included.map(x => x.id).join(',')+']'"
                                    rootProperty="grades"
                                >
                                </fe-filter-btn>
                            </v-slide-item>
                            <v-slide-item v-slot="{ active, toggle }">
                                <fe-filter-btn
                                    class="moveup"
                                    title="Active"
                                    :items="[{id: 1, name: 'Active'}, {id: 0, name: 'Inactive'}, {id: 2, name: 'Both'}]"
                                    itemText="name"
                                    itemValue="id"
                                    :value="filters.active"
                                    @input="onFilterActive"
                                    multiple :closeOnSelect="false"
                                >
                                </fe-filter-btn>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>

                    <fe-btn usage="primary" @click="onSearch">Search</fe-btn>
                </div>

                <fe-crud
                    ref="crudTemplates"
                    autoload
                    :config="$models.form"
                    :readParams="{ all: 1, }"
                    @read="templates = $event.sort(formSort).sort((a,b)=>a.disabled - b.disabled)"
                />

                <fe-crud
                    ref="crudStatus"
                    autoload
                    :config="$models.formInstanceStatus"
                    @read="statuses = $event"
                />
            </template>

            <template #toolbar-items>
                <v-slide-y-transition>
                    <div v-if="selections.length > 0" class="d-flex selection-toolbar background-default">
                        <div class="mt-3 ml-5">{{selections.length}} Form{{ selections.length > 1 ? 's' : '' }} Selected</div>
                        <div class="ml-auto">
                            <v-btn
                                text class="white--text"
                                @click="setStatusRecords = selections"
                                v-show="!hideSetStatus"
                                data-test="bulk-set-status-btn"
                            >
                                <i v-if="guardianEmailDisplay" class="fas fa-check-circle mr-2"></i>
                                Set Status
                            </v-btn>
                            <v-btn
                                text class="white--text"
                                @click="copyRecords = selections"
                                data-test="bulk-copy-btn"
                            >
                                <i v-if="guardianEmailDisplay" class="fal fa-clone mr-2"></i>
                                Bulk Copy
                            </v-btn>
                            <v-btn
                                text class="white--text"
                                @click="checkLanguage(selections)"
                                data-test="bulk-download-btn"
                            >
                                <i v-if="guardianEmailDisplay" class="fal fa-arrow-alt-to-bottom mr-2 mt-1"></i>
                                Download Zip File
                            </v-btn>
                            <v-btn
                                text class="white--text"
                                @click="selectFolderDialog.show = true;
                                selectFolderDialog.record = null"
                                data-test="bulk-move-btn"
                            >
                                <i v-if="guardianEmailDisplay" class="fal fa-folder-plus mr-2"></i>
                                Move to Folder
                            </v-btn>
                            <v-btn
                                v-if="guardianEmailDisplay"
                                text class="white--text"
                                @click="$router.push({ name: 'Guardian Email Setup' })"
                                data-test="bulk-guardian-email-btn"
                            >
                                <i class="fal fa-share mr-2"></i>
                                Set Up Guardian Emails
                            </v-btn>
                            <v-btn
                                text
                                class="white--text"
                                @click="doDownload(selections, true)"
                                v-show="downloadAllEnabled"
                                data-test="bulk-download-btn"
                            >
                                <i class="fal fa-arrow-alt-to-bottom mr-2 mt-1"></i>
                                Download All Files
                            </v-btn>
                            <v-btn
                                text
                                class="white--text"
                                @click="confirmDestroy"
                                v-show="!hideDelete"
                                data-test="bulk-delete-btn"
                            >
                                <i v-if="guardianEmailDisplay" class="fal fa-trash mr-2"></i>
                                Delete
                            </v-btn>
                            <v-btn
                                text
                                class="white--text"
                                @click="unSelect(null)"
                                data-test="cancel-btn"
                            >
                                <i v-if="guardianEmailDisplay" class="fal fa-times mr-2"></i>
                                Cancel
                            </v-btn>
                        </div>
                    </div>
                </v-slide-y-transition>
            </template>

            <template #cellDialog="{cell}">
                <v-list>
                    <v-list-item @click="renameRecord = cell.data" :disabled="canDoRename" data-test="rename-btn">Rename</v-list-item>
                    <v-list-item @click="copyRecords = [cell.data]" data-test="copy-btn">Copy</v-list-item>
                    <v-list-item @click="checkLanguage([cell.data], true)" data-test="print-btn">Print</v-list-item>
                    <v-list-item
                        v-if="guardianEmailDisplay"
                        @click="$router.push({ name: 'Guardian Email Setup' })"
                        data-test="guardian-email-btn"
                    >
                        Set up Guardian Email
                    </v-list-item>
                </v-list>
            </template>

        </fe-grid>

        <copy-form-dialog :records="copyRecords" @close="copyRecords=null" @reload="onReload" />
        <set-status-dialog :records="setStatusRecords" @close="setStatusRecords=null" @changed="onChanged" />
        <rename-dialog :record="renameRecord" @close="renameRecord=null" @changed="onChanged" />


        <fe-crud
            ref="permissionsCrud"
            :config="permissionConfig"
            autoload
            @read="v => userPermissions = v"
        />

        <fe-crud
            :config="$models.formInstance"
            @read="v => items = v"
            ref="crud"
            :autoload="false"
        />

        <fe-dialog
            width="500"
            v-if="homeLanguage.show"
            title="smartFORMS Download Language"
            @dismiss="clearLanguage()"
            @close="clearLanguage()"
            @accept="clearSelections(); clearLanguage(); doDownload();"
            acceptButtonText="Download"
            dismissButtonText="Cancel"
            persistent
        >
            <div>
                <v-select
                    flat solo dense
                    :items="languages"
                    itemText="name"
                    itemValue="id"
                    class="mt-2"
                    v-model="downloadLanguage"
                />
                <div v-if="downloadLanguage == 1 && hasNotMappedLanguage">
                    You are downloading forms with Home Languages that have not been configured for translations.
                    These forms will download to English until the language<span v-if="notMappedLanguages.length > 1">s</span>
                    <span v-for="(lang, index) in notMappedLanguages">
                        <span style="font-weight: bold;"> {{ lang }}</span><span v-if="notMappedLanguages.length > 1 && index !== notMappedLanguages.length - 1">, </span>
                    </span>
                    <span v-if="notMappedLanguages.length > 1"> have</span><span v-else> has</span> been setup in System Configurations.
                </div>
            </div>
        </fe-dialog>

        <!-- Add to folder dialog -->
        <fe-dialog
            v-if="selectFolderDialog.show"
            title="Move to Folder"
            :acceptButtonDisabled="!selectFolderDialog.record"
            @dismiss="selectFolderDialog.show = false;"
            @close="selectFolderDialog.show = false;"
            @accept="setFolder(); clearSelections()"
            acceptButtonText="Save"
            dismissButtonText="Cancel"
            persistent
        >
            <v-form @submit.prevent>
                <fe-label>Folder</fe-label>
                <fe-remote-combo
                    v-if="selectFolderDialog.show"
                    rootProperty="folders"
                    :items="formFolderItems"
                    v-model="selectFolderDialog.record"
                />
            </v-form>
        </fe-dialog>
    </div>
</template>

<script>
import lodash from 'lodash'
import SmartFormMixin from './smartFormMixin'
import FormDateFilter from './components/FormDateFilter'
import CopyFormDialog from './components/dialogs/CopyFormDialog'
import SetStatusDialog from './components/dialogs/SetStatusDialog'
import RenameDialog from './components/dialogs/RenameDialog'
import SmartForm from './SmartForm'
import { mapState } from 'vuex'
import checkboxHeader from './checkboxHeader'

const BLOCKSIZE = 10000

// todo:
// add loading overlay
// fix double load at start
// think about search button
export default {
    name: 'StudentFormsList',
    components: { FormDateFilter, CopyFormDialog, SetStatusDialog, RenameDialog, SmartForm },
    mixins: [ SmartFormMixin ],
    props: {},
    data() {
        // student_full_name does not sort
        let me = this
        return {
            selections: [],
            setStatusRecords: null,
            renameRecord: null,
            copyRecords: null,
            gridOptions: {
                immutableData: true,
                getRowNodeId: v => {
                    return `i${v.form_instance_id}f${v.folder_id||''}s${v.student_id||''}`
                },
                rowHeight: 40,
                datasource: this,
                maxConcurrentDatasourceRequests: 3,
                rowModelType: 'infinite',
                cacheBlockSize: BLOCKSIZE,
                blockLoadDebounceMillis: 500,
            },
            filters: {
                date: null,
                templates: {
                    included: [],
                },
                status: {
                    included: [],
                },
                school: {
                    included: [],
                },
                grade: {
                    included: [],
                },
                active: {
                    included: [{
                        id: 1,
                        name: 'Active'
                    }],
                },
            },
            totalCount: 0,
            templates: [],
            headers: [{
                // headerCheckboxSelection: true,
                headerComponent: 'checkboxHeader',
                headerComponentParams: {
                    selectAll : this.selectAllRows.bind(this),
                    selectAllCheckbox: this.selectAllCheckbox,
                },
                checkboxSelection: true,
                width: 70,
                minWidth: 70,
                colId: 'checkbox_column',
            }, {
                headerName: 'Form Name',
                field: 'form_instance_name',
                width: 400,
                cellRenderer(params) {
                    let data = params.data
                    if(!data) return ''
                    let icon = 'far fa-file'
                    if (data.form_instance_locked_flag) icon = 'far fa-file-pdf'
                    if (data.form_instance_status_lock_flag) icon = 'fas fa-lock'

                    return '<i style="margin-right: 10px;" class="' + icon + '"></i>' + params.value
                },
            }, {
                headerName: "Status",
                field: "form_instance_status_name",
                sortable: true,
            },{
                headerName: "School Year",
                field: "school_year_name",
                sortable: true,
            }, {
                headerName: 'Student',
                field: 'student_full_name',
                width: 200,
            }, {
                headerName: 'Template',
                width: 300,
                field: 'form_name',
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                },
            }, {
                headerName: 'Last Updated',
                field: 'form_instance_last_updated',
                maxWidth: 200,
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                },
                cellRenderer: v => this.$dayjs(v.value).format('LLL'),
            }, {
                headerName: 'Created',
                field: 'created',
                maxWidth: 200,
                dateColumnFormat: 'LLL',
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px'
                },
                cellRenderer: v => this.$dayjs(v.value).format('LLL'),
            }, {
                headerName: 'Owner',
                field: 'form_instance_created_by_full_name',
                maxWidth: 180,
                cellStyle: {
                    color: '#9297A6',
                    fontSize: '12px',
                },
                cellRenderer: v => {
                    if(!v.data) return ''
                    return v.data.form_instance_created_by_full_name
                },
            }, {
                headerName: 'School',
                width: 300,
                field: 'school_name',
                hide: true,
            }, {
                headerName: 'Grade',
                width: 100,
                field: 'grade_name',
                hide: true,
            }, {
                headerName: 'Active',
                width: 100,
                field: 'inactive_date',
                hide: true,
                cellRenderer: v => {
                    // if date is in the past return No
                    return v.data?.inactive_date && new Date(v.data?.inactive_date) <= new Date() ? 'No' : 'Yes'
                },
            }, {
                maxWidth: 70,
                cellRenderer: v => '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>',
                onCellClicked: v =>  this.$refs.grid.setDialogCell(v),
                cellStyle: {
                    display: 'flex'
                },
                colId: 'actions'
            }],
            userPermissions: {},
            hideSetStatus: false,
            hideDelete: false,
            frameworkComponents: {
                checkboxHeader: checkboxHeader,
            },
            selectAllCheckbox: false,
            statuses: [],
            canDoRename: true,
            homeLanguage: {
                show: false,
            },
            languages: [
                { id: 0, name: 'English' },
                { id: 1, name: 'Home Language' },
            ],
            downloadLanguage: 0,
            selected:[],
            hasNotMappedLanguage: false,
            notMappedLanguages: [],
            printing: false,
            columnApi: null,
            selectFolderDialog: {
                show: false,
                record: {}
            },
            allFolders: [],
            loading: false,
        }
    },
    computed: {
        ...mapState('global', ['sessionUser']),
        permissionConfig() {
            let cfg = this.$_.cloneDeep(this.$models.formPermissions)
            cfg.read.params.user_id = this.sessionUser.user.id
            return cfg
        },
        formTranslationsEnabled() {
            return this.$store.getters['flags/flags']['e-c-form-translations'] === true
        },
        downloadAllEnabled() {
            return this.$store.getters['flags/flags']['ec-smartform-download-all'] === true
        },
        formFolderItems() {
            if (!this.selectedFolder) {
                // If user is viewing top level, don't show an "unparent" option
                return this.allFolders
            } else {
                return [
                    { id: null, name: "Do not place this in any folder." },
                    ...this.allFolders
                ]
            }
        },
        guardianEmailDisplay() {
            return this.$store.getters['flags/flags']['ec-guardian-emails']
        },
    },
    watch: {
        selections(v) {
            let canedit = []
            let candelete = []
            // if any selected forms don't have edit permissions, hide Set Status from toolbar
            // if any selected forms don't have delete permissions, hide Delete from toolbar
            if (Object.keys(this.userPermissions).length) {
                Object.keys(this.userPermissions[this.sessionUser.user.id]).forEach(key => {
                    if (this.userPermissions[this.sessionUser.user.id][key].can_edit) canedit.push(parseInt(key))
                    if (this.userPermissions[this.sessionUser.user.id][key].can_delete) candelete.push(parseInt(key))
                })
            }
            this.hideSetStatus = v.filter(sel => !canedit.includes(sel.form_id)).length
            this.hideDelete = v.filter(sel => !candelete.includes(sel.form_id)).length
        },
        'homeLanguage.show'(v) {
            if(v) {
                this.downloadLanguage = 0
            }
        },
        'selectFolderDialog.show'(v) {
            if (!v) return

            this.$axios.get(this.$models.getUrl('formFolder', 'read'))
                .then(response => {
                    this.allFolders = response.data.folders
                })
        },
        loading(v) {
            this.$emit('loading', v)
        }
    },
    created() {
        window.addEventListener('beforeunload', this.editHeaders)
    },
    mounted() {
        document.querySelector('.smartforms-student-forms-list-grid').id = 'student-grid'
    },
    methods: {
        loadData: lodash.debounce(function(e) {
            this.$refs.grid.gridOptions.api.setDatasource(this)
        },500),
        clearSelections() {
            this.$refs.grid?.gridApi?.deselectAll()
        },
        onSearch() {
            this.loadData()
        },
        onReload(e) {
            debugger
            if(!e || !e.deferred) this.clearSelections()
            // don't reload the page
        },
        onFilterDate(v) {
            this.filters.date = v
            // this.loadData()
        },
        onFilterTemplate(v) {
            this.filters.templates = v
            // this.loadData()
        },
        onFilterStatus(v) {
            this.filters.status = v
        },
        onFilterSchool(v) {
            this.filters.school = v
        },
        onFilterGrade(v) {
            this.filters.grade = v
        },
        onFilterActive(v) {
            if (v.included.find(x => x.id == 2)) {
                this.filters.active.included = [
                    { id: 0, name: 'Inactive' },
                    { id: 1, name: 'Active' }
                ]
            } else {
                this.filters.active.included = v.included
            }
        },
        refreshTemplates(e) {
            if(e[0].isIntersecting) this.$refs.crudTemplates?.read()
        },
        onChanged(recs) {
            this.reloadRecs(recs)
            this.setStatusRecords = null
            this.renameRecord = null
        },
        reloadRecs(records) {
            let recs = [].concat(records)
            this.$refs.crud.read({
                property: 'student_instance',
                form_instance_id: recs.map(x => x.form_instance_id).join(','),
            }).then(r => {
                let newRecs = r.data.form_instances
                let index = {}
                this.$refs.grid.gridApi.forEachNode(n => index[n.data.form_instance_id]=n)
                newRecs.forEach(x => index[x.form_instance_id].setData(x))
                this.$refs.grid.gridApi.redrawRows()
                this.unSelect()
            })
        },
        getRows(params) {
            this.loading = true
            this.$refs.crud.read({
                property: 'student_instance',
                start_row: params.startRow,
                end_row: params.endRow,
                sort: params.sortModel[0]?.colId,
                dir: params.sortModel[0]?.sort,
                search: this.$refs.grid.filterText || undefined,
                column: this.$refs.grid.filterText
                    && (this.$refs.grid.filterTextColumn.colId != 'fe-grid-all-columns')
                    && this.$refs.grid.filterTextColumn.colId
                    || undefined,
                form_id: this.filters.templates.included.map(x => x.id).join(','),
                school_id: this.filters.school.included.map(x => x.id).join(','),
                grade_id: this.filters.grade.included.map(x => x.id).join(','),
                active: this.filters.active.included.map(x => x.id).join(','),
                status: this.filters.status.included.map(x => x.id).join(','),
                ...this.filters.date
            }).then(r => {
                this.$emit('loaded', r.data.form_instances)
                // remove any forms from student form list that the user does not have access to view
                let view = []
                this.totalCount = Number(r.data.totalCount) === r.data.totalCount ? r.data.totalCount : r.data.form_instances?.length || BLOCKSIZE
                if(this.totalCount > BLOCKSIZE) this.totalCount = BLOCKSIZE
                if(Object.keys(this.userPermissions).length) {
                    Object.keys(this.userPermissions[this.sessionUser.user.id]).forEach(key => {
                        if (this.userPermissions[this.sessionUser.user.id][key].can_view) view.push(parseInt(key))
                    })
                    r.data.form_instances = r.data.form_instances.filter(i => view.includes(i.form_id))

                    // in order to filter out ag-grid row data that's coming from a datasource
                    // e.g. it's server side model
                    // you have to return new length to grid so it doesn't add empty rows at the bottom
                    let rowsThisPage = r.data.form_instances.slice(params.startRow, params.endRow)
                    let lastRow = -1;
                    if (r.data.form_instances.length <= params.endRow) {
                        lastRow = r.data.form_instances.length
                    }
                    params.successCallback(rowsThisPage, lastRow)
                } else {
                    params.successCallback(r.data.form_instances, this.totalCount)
                }
                this.$refs.grid.gridOptions.api.sizeColumnsToFit()
                if(this.exportParams) {
                    this.$refs.grid.gridOptions.api.exportDataAsCsv(this.exportParams)
                    this.exportParams = null
                    this.$refs.grid.gridOptions.cacheBlockSize = BLOCKSIZE
                }
            }).finally(() => {
                this.loading = false
            })
        },
        exportData(params) {
            this.$refs.grid.gridOptions.cacheBlockSize = this.totalCount
            this.$refs.grid.gridOptions.api.setDatasource(this)
            this.exportParams = params
        },
        doSelection() {
            this.selections = this.$refs.grid.gridApi.getSelectedRows()
        },
        unSelect(keep) {
            this.selectAllCheckbox = true
            this.$refs.grid.gridOptions.api.refreshHeader()
            if(this.$_.isBlank(keep)) {
                this.$refs.grid.gridApi.deselectAll()
            } else {
                this.$refs.grid.gridApi.getSelectedNodes().map(n => {
                    if(!keep.find(rec => rec == n.data)) n.setSelected(false)
                })
            }
        },
        loadItem(row) {
            let rec = row.data
            if (rec.form_instance_locked_flag || rec.form_instance_status_lock_flag) {
                this.print(rec)
            } else {
                let me = this
                this.$store.commit('global/addDockableWindow', {
                    name: rec.form_instance_name,
                    data: rec,
                    component: 'smart-form',
                    attrs: {
                        id: rec.form_instance_id,
                        template_id: rec.form_id,
                    },
                    events: {
                        close() {
                            me.$emit('closeDialog')
                            me.reloadRecs(rec)
                        },
                        toggle: (open) => me.$emit('toggleDialog', open),
                    }
                })
            }
        },
        print(rec, downloadLang = null) {
            this.printing = false
            let targetLanguage = []
            if(rec.hasOwnProperty('home_language_code') && downloadLang === 1) {
                targetLanguage.push(rec.form_instance_id.toString() + ':' + rec.home_language_code.toString())
            }
            let sendTargetLang = ''
            if(this.formTranslationsEnabled && downloadLang === 1) {
                sendTargetLang = '&target_language=' + targetLanguage
            }
            this.$store.commit('global/addDockableWindow', {
                name: rec.form_instance_name,
                component: 'IFrameRenderer',
                attrs: {
                    app: 'bec',
                    url: this.$axios.defaults.baseURL+'formPrint.php?printformid=' + rec.form_instance_id + sendTargetLang
                },
                events: {
                    close: () => this.$emit('closeDialog'),
                    toggle: (open) => this.$emit('toggleDialog', open),
                }
            })
        },
        confirmDestroy() {
            this.$confirmDelete(this.selections, () => {
                let forms = this.selections.filter((rec) => !rec.is_folder)

                if (forms.length > 0) {
                    this.$refs.crud.destroy(forms)
                    .finally(() => {
                        this.clearSelections()
                        this.loadData()
                    })
                }
            })
        },
        formSort(a,b) {
            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
            return 0
        },
        selectAllRows(params) {
            // setSelected(newValue, clearSelection, suppressFinishActions)
            // manually setting selection runs an event for each row, so with 5000+ rows, takes forever
            // splitting it so that only last row actually passes event and makes it fast
            let selectedNodes = []
            this.$refs.grid.gridOptions.api.forEachNode(node => selectedNodes.push(node))
            for (let i = 0; i < selectedNodes.length - 1; i++) {
                params ? selectedNodes[i].setSelected(true, false, true) : selectedNodes[i].setSelected(false, false, true)
            }
            params ? selectedNodes[selectedNodes.length - 1].setSelected(true) : selectedNodes[selectedNodes.length - 1].setSelected(false)
        },
        async hasCustomFormName(v) {
            let resp = await this.$axios.get('form.php?action=get&property=name_config_status&form_id=' + v.form_id)
            this.canDoRename = resp.data.status ? resp.data.status === 1 : false
        },
        doCellClick(v) {
            // can't call promise from :disabled
            // so get value of status on click of ... button
            // and set if user can rename
            if(v.column.colId === 'actions') {
                this.hasCustomFormName(v.data)
            }
        },
        async checkLanguage(selections, print = false) {
            if(print) this.printing = true
            this.hasNotMappedLanguage = false
            this.notMappedLanguages = []
            // if form translations not enabled, go directly to download
            if(!this.formTranslationsEnabled) {
                this.downloadLanguage = 0
                if(!print) this.doDownload(selections)
                else this.print(selections[0])
            } else {
                // grab list of home languages to compare student's home language id
                let languagesList = await new Promise((resolve, reject) => {
                    this.$modelGet('homeLanguage')
                        .then((response) => {
                            resolve(response)
                        })
                })
                // only check home languages if mapping of aws codes is done for at least one language
                if(!languagesList.every(x => x.language_code_id == null)) {
                    // check to see if any of the students selected have home language set
                    this.$axios.get('students.php?action=get&property=student&id=' + selections.map(x => x.student_id).join(','))
                        .then((resp) => {
                            if(resp.data.some(x => x.home_language_id)) {
                                // find single student's home language
                                // if english, don't show modal
                                // else show modal and update list to show specific home language
                                if(selections.length === 1) {
                                    let display = languagesList.find(x => x.id === resp.data[0].home_language_id).home_language_display
                                    let found = languagesList.find(x => x.id === resp.data[0].home_language_id)
                                    let code = 18
                                    if(found) {
                                        if (found.language_code_id) {
                                            code = languagesList.find(x => x.id === resp.data[0].home_language_id).language_code_id
                                        } else {
                                            this.downloadLanguage = 0
                                            this.hasNotMappedLanguage = true
                                            this.notMappedLanguages = [found.home_language_display]
                                        }
                                    }
                                    if(display.toLowerCase() !== 'english' && display.toLowerCase() !== 'eng') {
                                        this.homeLanguage.show = true
                                        this.languages[1].name += ' (' + display + ')'
                                        selections[0].home_language_code = code
                                        this.selected = selections
                                    } else {
                                        this.downloadLanguage = 0
                                        if (!print) this.doDownload(selections)
                                        else this.print(selections[0])
                                    }
                                } else {
                                    // grab array of home language ids from all students, ignoring nulls
                                    let langs = resp.data.map(x => x.home_language_id).filter(Boolean)
                                    // grab english id, and only show modal if at least one student has
                                    // a home language that's not english
                                    let englishFound = languagesList.find(x => x.home_language_display.toLowerCase() === 'english' || x.home_language_display.toLowerCase() === 'eng' )
                                    let english = englishFound ? englishFound.id : null
                                    if(langs.some(x => x !== english)) {
                                        this.homeLanguage.show = true
                                        selections.forEach(sel => {
                                            let hlc = 18
                                            let found = languagesList.find(y => y.id === resp.data.find(x => x.id === sel.student_id)?.home_language_id)
                                            if(found) {
                                                if(found.language_code_id) {
                                                    hlc = languagesList.find(y => y.id === resp.data.find(x => x.id === sel.student_id).home_language_id).language_code_id
                                                } else {
                                                    this.hasNotMappedLanguage = true
                                                    this.notMappedLanguages.push(found.home_language_display)
                                                }
                                            }
                                            sel.home_language_code = hlc
                                        })
                                        this.selected = selections
                                    } else {
                                        this.downloadLanguage = 0
                                        if(!print) this.doDownload(selections)
                                        else this.print(selections[0])
                                    }
                                }
                            } else {
                                this.downloadLanguage = 0
                                if(!print) this.doDownload(selections)
                                else this.print(selections[0])
                            }
                        })
                } else {
                    this.downloadLanguage = 0
                    if(!print) this.doDownload(selections)
                    else this.print(selections[0])
                }
            }
        },
        doDownload(selections = [], allFiles = false) {
            if(this.printing) {
                this.print(selections.length ? selections[0] : this.selected[0], this.downloadLanguage)
                this.printing = false
            } else {
                let filters = {
                    form_id: this.filters.templates.included.map(x => x.id).join(','),
                    school_id: this.filters.school.included.map(x => x.id).join(','),
                    grade_id: this.filters.grade.included.map(x => x.id).join(','),
                    active: this.filters.active.included.map(x => x.id).join(','),
                    status: this.filters.status.included.map(x => x.id).join(','),
                    ...this.filters.date
                }
                this.unSelect(this.downloadFormZip(selections.length ? selections : this.selected, this.downloadLanguage, allFiles, filters))
            }
        },
        clearLanguage() {
            this.homeLanguage.show = false
            this.languages[1].name = 'Home Language'
        },
        onGridReady(grid) {
            this.columnApi = grid.columnApi
            let previousHeaders = window.localStorage.getItem('ec-student-forms-headers') || '[]'
            previousHeaders = JSON.parse(previousHeaders)
            if(previousHeaders.length) {
                previousHeaders.forEach(head => {
                    this.headers.find(x => x.field == head.field).hide = head.hide
                    grid.api.setColumnDefs(this.headers)
                })
            }
        },
        setFolder() {
            let r = [...this.selections]
            r.forEach(rec => {
                rec.parent_folder_id = this.selectFolderDialog.record.id || null // "no folder" is set as id: 0, we'll null it out on the backend
            })
            this.$refs.crud.update(r)
                .then(response => {

                })
                .finally(() => {
                    this.selectFolderDialog.show = false
                    this.loadData()
                })
        },
        editHeaders() {
            this.headers.forEach(head => {
                head.hide = !this.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
            })
            window.localStorage.setItem('ec-student-forms-headers', JSON.stringify(this.headers))
        }
    },
}
</script>

<style lang="scss">
.smartforms-student-list-select-all {
    position: relative;
    right: 10px;
    i {
        color: #050F2D !important;
    }
    .v-icon.v-icon {
        font-size: 21px;
    }
}
</style>

<style lang="scss" scoped>
.moveup ::v-deep button.v-btn {
    margin-top: 0;
}

.inactive {
    text-size: 70%;
    color: #9297A6;
}

.selection-toolbar {
    width: 100%;
    position: absolute;
    left: 0px;
    height: 48px;
    color: white;
    z-index: 10;
    border-radius: 4px;
}

.background-guardian {
    background: #006C90;
}

.background-default {
    background: #66A7BC;
}


.smartforms-student-forms-list-grid {
    ::v-deep .flex-row {
        max-width: 60%;
    }
}
</style>
