<template>
    <div class="container">
      <div>
        <a href="/smartFORMS">Back to smartFORMS</a>
        <h1>Email smartFORMS to Guardians</h1>
      </div>
      <div class="bar">
        <!-- Review Email -->
        <div class="segment" :class="{ active: !choosingSettings && !previewingEmail && !sendingEmail }">
            <i class="far fa-check" v-if="choosingSettings || previewingEmail || sendingEmail" style="margin-right: 6px;"></i>
            Review guardians & forms
        </div>
        <div class="triangle-right" :class="{ active: !choosingSettings && !previewingEmail && !sendingEmail }"></div>
        <!-- Choose Settings -->
        <div class="triangle-left" :class="{ active: choosingSettings }"></div>
        <div class="segment" :class="{ active: choosingSettings }">
            <i class="far fa-check" v-if="previewingEmail || sendingEmail" style="margin-right: 6px;"></i>
            Choose settings
        </div>
        <div class="triangle-right" :class="{ active: choosingSettings }"></div>
        <!-- Preview Email -->
        <div class="triangle-left" :class="{ active: previewingEmail }"></div>
        <div class="segment" :class="{ active: previewingEmail }">
            <i class="far fa-check" v-if="sendingEmail" style="margin-right: 6px;"></i>
            Preview email & more
        </div>
        <div class="triangle-right" :class="{ active: previewingEmail }"></div>
        <!-- Send Email -->
        <div class="triangle-left" :class="{ active: sendingEmail }"></div>
        <div class="segment" :class="{ active: sendingEmail }">Review summary & Send</div>
      </div>
      <review
        v-if="!choosingSettings && !previewingEmail && !sendingEmail"
        :students-count="studentsCount"
        :guardian-contacts-count="guardianContactsCount"
        :smartforms-count="smartformsCount"
        :column-defs="columnDefs"
        :row-data="rowData"
        @goToCommunicationSettings="goToCommunicationSettings"
      />
      <settings v-if="choosingSettings" @goToPreview="goToPreview" @goToReview="goToReview"/>
      <preview v-if="previewingEmail" :expiration-date="getLongExpirationDate()" :short-expiration-date="getShortExpirationDate()" :short-current-date="getShortCurrentDate()"  @goToCommunicationSettings="goToCommunicationSettings" @goToSend="goToSend"/>
      <send v-if="sendingEmail" :expiration-date="getLongExpirationDate()" @goToPreview="goToPreview"/>
    </div>
</template>
<script>
  import {AgGridVue} from "@ag-grid-community/vue"
  import Review from './Review.vue'
  import Settings from "./Settings.vue";
  import Preview from "./Preview.vue";
  import Send from "./Send.vue";

  export default {
    name: 'GuardianEmailSetup',
    components: {
        AgGridVue,
        Review,
        Settings,
        Preview,
        Send,
    },
    data() {
      return {
        selectedTab: 0,
        studentsCount: 6,
        guardianContactsCount: 9,
        smartformsCount: 6,
        choosingSettings: false,
        columnDefs: [
            { headerName: "Student", field: "student" },
            { headerName: "Guardian Contacts", field: "guardianContacts", width: 400 },
            { headerName: "# of Forms", field: "studentFormCount", width: 112 },
            { headerName: "smartFORMS", field: "smartforms", width: 420 },
        ],
        rowData: [
            {
                student: "Valdez, Nate",
                guardianContacts: "davaldez@gmail.com, andrewjavetia@gmail.com",
                studentFormCount: 1,
                smartforms: "FPS | Reading Support Plan-Valdez, Nate",
            },
            {
                student: "Alexander, Phillipe",
                guardianContacts: "davonlang@gmail.com",
                studentFormCount: 1,
                smartforms: "1808test-Alexander, Phillipe",
            },
            {
                student: "Banks, Charley",
                guardianContacts: "imanibanks@gmail.com",
                studentFormCount: 1,
                smartforms: "Year Incident Banks-Banks, Charley",
            },
            {
                student: "Wilkins, Gustav",
                guardianContacts: "kalaniwilkins@gmail.com, malikjwilkins@gmail.com",
                studentFormCount: 1,
                smartforms: "Multi Student Test Absence-Wilkins, Gustav",
            },
            {
                student: "Knox, Helene",
                guardianContacts: "nathangzhang@gmail.com",
                studentFormCount: 1,
                smartforms: "Multi Student Test Absence-Knox, Helene",
            },
            {
                student: "Gordon, Vicky",
                guardianContacts: "artgordonphotography@gmail.com, alexvkendall@gmail.com",
                studentFormCount: 1,
                smartforms: "Multi Student Test Absence-Gordon, Vicky",
            }
        ],
        previewingEmail: false,
        sendingEmail: false,
      };
    },
    mounted() {
        this.choosingSettings = false;
        this.previewingEmail = false;
        this.sendingEmail = false;
    },
    methods: {
        goToReview() {
            this.choosingSettings = false;
            this.previewingEmail = false;
            this.sendingEmail = false;
        },
        goToCommunicationSettings() {
            this.choosingSettings = true;
            this.previewingEmail = false;
            this.sendingEmail = false;
        },
        goToPreview() {
            this.choosingSettings = false;
            this.previewingEmail = true;
            this.sendingEmail = false;
        },
        goToSend() {
            this.choosingSettings = false;
            this.previewingEmail = false;
            this.sendingEmail = true;
        },
        getLongExpirationDate() {
            const today = new Date();
            const nextWeek = new Date(today);
            nextWeek.setDate(today.getDate() + 7);
            return nextWeek.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
        },
        getShortExpirationDate() {
            const today = new Date();
            const nextWeek = new Date(today);
            nextWeek.setDate(today.getDate() + 7);
            return nextWeek.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
        },
        getShortCurrentDate() {
            const today = new Date();
            return today.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
        },
    },
  };
</script>
<style scoped>
    .container {
        position: relative;
        margin: 16px;
    }

    .container > div > a {
        text-decoration: none;
    }

    .container > div > h1 {
        margin-top: 16px;
    }

    .segment {
        flex: 1;
        text-align: center;
        font-weight: bold;
        height: 48px;
        font-size: 14px;
        font-family: CerebriSans-Regular, Cerebri Sans, Roboto, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 400;
        background-color: #ECEDF1;
    }

    .segment.active {
        background-color: #E5F0F4;
    }

    .triangle-right.active {
        border-top: 24px solid white;
        border-left: 50px solid #E5F0F4;
        border-bottom: 24px solid white;
    }

    .triangle-left.active {
        border-top: 24px solid #E5F0F4;
        border-left: 50px solid transparent;
        border-bottom: 24px solid #E5F0F4;
    }

    .count {
        font-size: 20px;
        font-weight: bold;
    }
    
    .bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        padding: 10px;
        margin-left: -8px;
        margin-top: 32px;
        margin-bottom: 28px;
        width: 92vw;
    }
    
    .segment {
        flex: 1;
        text-align: center;
        font-weight: bold;
        height: 48px;
        font-size: 14px;
        font-family: CerebriSans-Regular, Cerebri Sans, Roboto, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 400;
    }
    
    .triangle-right {
        width: 0;
        height: 0;
        border-top: 24px solid white;
        border-left: 50px solid #ECEDF1;
        border-bottom: 24px solid white;
    }
    
    .triangle-left {
        width: 0;
        height: 0;
        border-top: 24px solid #ECEDF1;
        border-left: 50px solid transparent;
        border-bottom: 24px solid #ECEDF1;
        margin-left: -32px;
    }
  </style>
  