<template>
    <div class="flex-fill flex-column d-flex" style="background: #fff;">
        <pin-dialog v-model="pinDialog.show" :pinParams="pinDialog.params" :title="pinDialog.title"/>
        <fe-mask v-show="loading" :showLoader="true" />
        <fe-grid
            :key="gridKey"
            ref="grid"
            class="data-wall-container"
            :class="{ 'standalone-borders': standaloneBorders }"
            @rowSelected="doSelect"
            :columnDefs="sloFilteredColumnDefs"
            @columnVisible="filterModified"
            :frameworkComponents="frameworkComponents"
            :defaultColDefOverrides="defaultColDef"
            :rowData="sloFilteredItems"
            :pinnedBottomRowData="footerRows"
            :showFullscreen="false"
            :showAddRowBtn="false"
            @cellClicked="doCellClick"
            @cellMouseOver="doCellOver"
            @cellMouseOut="doCellOut"
            displayDensity="small"
            :flexColumns="false"
            :searchBar="false"
            :showToolbar="true"
            :title="null"
            @deleted="$emit('show-selection-tools')"
            showScrollbars
            :showDownload="false"
            style="width: 100%; height: 100%;"
            @gridReady="onGridReady"
            :applyColumnDefOrder="true"
        >
            <template v-slot:left-toolbar-items v-if="categoryName">
                <v-flex class="d-flex align-start flex-fill mx-4">
                    <div class="text-left d-flex">
                        <h1 class="mr-2">{{categoryName}} Assessments</h1>
                        <fe-info-tip
                            :tooltip="categoryName"
                        />
                    </div>
                </v-flex>
            </template>
            <template v-slot:toolbar-items>
                <fe-icon-btn v-if="!hidePin" useIcon="far fa-thumbtack" style="margin-top: 2px;" small @click="doPin">
                </fe-icon-btn>

                <menu-btn v-if="!hideMenu" ref="menuBtn" style="margin-top: 9px;">
                    <v-list-item @click="createScatter">
                        Scatter Plot
                    </v-list-item>

                    <v-list-item>
                        <div class="child-menu">
                            <v-list>
                                <div style="max-height: 300px; overflow: auto !important;">
                                    <v-list-item
                                        v-for="(item, index) in dataWallConfigs"
                                        :key="index"
                                        :class="item.id == dataWallConfigId ? 'dataWallConfigItem active' : 'dataWallConfigItem'"
                                        @click="onSelectDataWallConfig(item)"
                                    >
                                        <span>{{ item.name }}{{ item.id == defaultDataWallId ? ' (Default)' : '' }}</span>
                                        <fe-icon-btn v-if="item.user_id == sessionUser.user.id" small useIcon="far fa-pencil" class="min-btn edit-icon" @click.stop="onEditDataWallConfig(item)"/>
                                        <v-spacer/>
                                        <fe-icon-btn v-if="item.id == dataWallConfigId" small useIcon="fas fa-check" class="min-btn"/>
                                    </v-list-item>
                                </div>

                                <v-list-item @click="onCreateDataWallConfig">
                                    <fe-icon-btn small useIcon="fas fa-plus" class="min-btn"/>
                                    <span>Create New Configuration</span>
                                </v-list-item>
                            </v-list>
                        </div>

                        <span>Change Score Detail Display</span>
                        <fe-icon-btn small useIcon="fas fa-chevron-right" class="min-btn"/>
                    </v-list-item>

                    <v-list-item v-if="sloCapable && !sloDisabledMessage" @click="slo.create = true; doCellOut()">
                        Create SLO
                    </v-list-item>
                    <fe-tooltip v-else-if="sloCapable && sloDisabledMessage" :tooltip="sloDisabledMessage">
                        <v-list-item v-on="on" style="opacity: 0.5;">
                            Create SLO
                        </v-list-item>
                    </fe-tooltip>

                    <v-list-item @click="$emit('remove-from-collection'); doCellOut()" v-if="forCollections">
                        Remove From Collection
                    </v-list-item>
                </menu-btn>

                <fe-icon-btn useIcon="fa fa-arrow-alt-to-bottom" style="margin-top: 2px;" small @click="doDownload"/>
            </template>
            <template v-slot:selection-tools>
                <template v-if="!slo.create">
                    <fe-btn
                        usage="ghost"
                        dark
                        useIcon="fas fa-tags"
                        @click="tagStudents"
                    >
                        Add Student Tag
                    </fe-btn>
                    <fe-btn
                        usage="ghost"
                        dark
                        useIcon="fas fa-chalkboard"
                        @click="assignCourse = true; doCellOut()"
                    >
                        Assign Course
                    </fe-btn>
                    <fe-btn
                        usage="ghost"
                        dark
                        useIcon="far fa-hands-heart"
                        @click="createIntervention"
                    >
                        Create Intervention
                    </fe-btn>
                </template>
                <fe-btn usage="ghost" dark @click="gridApi.deselectAll(); doCellOut()">Cancel</fe-btn>
            </template>
        </fe-grid>

        <v-dialog
            v-if="details.show && activePage !== 'undefined'"
            v-model="details.show"
            width="80%"
            :transition="false"
            :retainFocus="false"
            persistent
        >
            <v-card color="white" style="width: 100%; height: 600px;" class="d-flex flex-column">
                <v-card-title class="fe-dialog-card-title">
                    {{ detailsTitle }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dismissDialog">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="fe-dialog-card-text-no-padding flex-grow-1" style="overflow: scroll;">
                    <student-score-group
                        v-if="activePage === 'score_group'"
                        v-show="activePage === 'score_group'"
                        :student="studentData"
                        @score-click="doScoreClick"
                    />
                    <template v-else v-for="(extra, e) in extras">
                        <component
                            v-if="activePage === extra.show && activePage !== 'score_group'"
                            :key="`datawall-extras-item-${e}`"
                            :is="extra.component"
                            :studentInfo="studentData"
                            :params="extra.params"
                            :student="studentData"
                            @score-click="doScoreClick"
                            @closeDialog="dismissDialog"
                            @unsavedChanges="dialogChangesUnsaved = $event"
                            @addTagClick="doAddTagClick ? doAddTagClick() : () => {}"
                            @updateCount="doUpdateCount"
                            @updateGrid="loadData"
                            v-bind="extra.attrs"
                            v-on="extra.listeners"
                            :ref="extra.ref?extra.ref:undefined"
                        />
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>

        <fe-dialog
            v-if="assignCourse"
            title="Assign Courses"
            width="80%"
            height="600"
            @close="assignCourse = false"
            :footer="false"
        >
            <courses v-if="assignCourse" :selectedItems="selectedItems" style="height: 100%;" />
        </fe-dialog>

        <fe-dialog
            title="Scatter Plot"
            v-if="scatterDialog.show"
            @accept="doScatter"
            @close="scatterDialog.show=false"
            persistent
            dismissButtonText="Cancel"
            acceptButtonText="Create"
            :acceptButtonDisabled="scatterDialog.selected.length != 2"
            width="600"
        >
            <selection-list :items="scatterPlotOptions" style="height: 300px" v-model="scatterDialog.selected" multiple>
                <template #row="{item}">
                    {{item.name}}
                </template>
            </selection-list>
        </fe-dialog>

        <fe-student-card
            ref="studentCard"
            :style="{
                position: 'absolute',
                left: `${studentCard.left}px`,
                top: `${studentCard.top}px`,
                padding: '1px 6px 1px 6px',
                zIndex: '10'
            }"
            :imageUrl="studentCard.imageUrl"
            :studentRecord="studentCard.studentRecord"
            v-show="studentCard.show"
            fullNameField="student_full_name"
            schoolField="school_name"
            gradeField="grade_desc"
            genderField="gender"
            @close="() => {}"
        />

        <slo-sidebar
            v-if="slo.create"
            :selections="slo.selections"
            @selections="onChangeSloSelections"
            :columns="raw.columns"
            :records="sloEligibleItems"
            :raw="raw"
            :studentIds="slo.studentIds"
            @setStudentIds="applySloSelections"
            @closed="closeSlo"
        />

    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import EventHeader from './headers/EventHeader.js'
    import ScoreHeader from './headers/ScoreHeader'
    import ScoreColumn from './renderers/ScoreColumn.js'
    import InterventionColumn from './renderers/InterventionColumn'
    import StudentAttachment from '../studentprofile/attachments/Index'
    import FormList from '@/components/modules/smartforms/List'
    import StudentTagList from '../studentprofile/TagList'
    import Message from '@/components/common/Message'
    import StudentScoreGroup from '../studentprofile/ScoreGroup'
    import InterventionCreation from '@/components/modules/intervention/creation/Index'
    import AssessmentGroupSummary from '@/components/modules/manage/assessments/AssessmentGroupSummary'
    import AddTag from '../studentprofile/AddTag'
    import Courses from '@/components/modules/manage/district/Courses'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import SelectionList from '@/components/common/SelectionList'
    import CreateScatterPlot from '@/components/modules/scatterplot/Create'
    import PinDialog from '@/components/common/PinDialog'
    import ScoreDetailsConfigPanel from '@/components/modules/datawall/ScoreDetailsConfigPanel'
    import SloSidebar from '@/components/modules/slo/creation/datawall/Sidebar'
    import CustomTooltip from './renderers/CustomTooltip'
    import scatterMixin from '@/components/modules/analytics/mixins/scatter'

    export default {
        name: 'DataWall',
        mixins: [scatterMixin],

        components: {
            StudentAttachment,
            FormList,
            Message,
            StudentTagList,
            StudentScoreGroup,
            InterventionCreation,
            AssessmentGroupSummary,
            AddTag,
            Courses,
            MenuBtn,
            SelectionList,
            PinDialog,
            ScoreDetailsConfigPanel,
            SloSidebar,
            CustomTooltip,
        },

        props: {
            params: {},
            categoryName: null,
            scope: null,
            showDataWall: null,
            categories: null,
            forCollections: {
                type: Boolean, default: false
            },
            scopeIdx: null,
            hideMenu: { type: Boolean, default: false },
            hidePin: { type: Boolean, default: false },
            standaloneBorders: { type: Boolean, default: false },
            sloCapable: { type: Boolean, default: false },
            sloDisabledMessage: { type: String, default: null },
            suppressDefaultDrilldown: { type: Boolean, default: false },
        },

        data() {
            return {
                slo: {
                    create: false,
                    selections: {},
                    studentIds: []
                },
                createSlo: false,
                columnApi: null,
                gridApi: null,
                columnDefs: [],
                columnLookup: {},
                items: [],
                selectedItems: [],
                raw: [],
                frameworkComponents: {
                    agColumnHeader: ScoreHeader,
                    scoreColumn: ScoreColumn,
                    interventionColumn: InterventionColumn,
                    eventheader: EventHeader,
                    customTooltip: CustomTooltip,
                },
                loading: false,
                field: '',
                studentData: {},
                showExtraWindow: false,
                details: {
                    show: false,
                    title: 'Not Set',
                    records: [],
                    attrs: {},
                    listeners: {}
                },
                scoreGroupData: {},
                dialogChangesUnsaved: false,
                selectedStudent: [],
                tagRef: null,
                assignCourse: false,
                scatterPlotOptions: [],
                scatterDialog: {
                    show: false,
                    selected: []
                },
                defaultColDef: {
                    flex: 1,
                    sortable: true,
                    filter: true,
                    floatingFilter: true,
                    suppressMenu: false,
                    customTooltip: 'customTooltip',
                },
                pinDialog: {
                    show: false,
                    params: {},
                    title: ''
                },
                lastStudentOver: null,
                studentCard: {
                    show: false,
                    studentRecord: {}
                },
                hiddenColumns: [],
                dataWallConfigs: [],
                dataWallConfigId: null,
                scoreFilters: [],
                savedFilters: [],
                targets: {
                    EQUIV: [],
                    AFFECTED: []
                }
            }
        },

        computed: {
            ...mapState('global',['sessionUser', 'currentYear', 'panelObjectEvents', 'userPreferences']),
            defaultDataWallId() {
                return this.userPreferences?.DATAWALL_CONFIG?.user_value || null
            },
            gridKey () { return `datawall-grid-${this.slo.create ? 'with-slo' : ''}` },
            sloFilteredColumnDefs() {
                if (!this.slo.create) return this.columnDefs
                let sel = this.slo.selections
                let cols = this.columnDefs.filter((def) => {
                    if (def.checkboxSelection) return  (sel.assessment && sel.window)
                    else if (def.field == 'student_full_name') return true
                    else if (['district_id', 'state_id'].includes(def.field)) return false
                    else if (def.headerName == 'Extras') return false
                    else if (sel.assessment?.sub_category_id) return (def.raw?.sub_category_id === sel.assessment?.sub_category_id)
                    else return true
                }).map((def) => {
                    if (sel.assessment?.sub_category_id && sel.window?.dataPointId && def.raw?.sub_category_id === sel.assessment?.sub_category_id) {
                        return Object.assign({}, def, {
                            children: def.children.filter(col => col.field == `dp${sel.window?.dataPointId}`)
                        })
                    }
                    else return def
                })
                if (sel.assessment && sel.window) {
                    this.$nextTick(() => {
                        this.gridApi?.columnController?.columnApi?.moveColumn('selectionBox', 0)
                    })
                }
                // this.setHeaders(cols)
                return cols
            },
            sloFilteredItems() {
                let results
                let filtered = []
                if (this.slo.create && this.slo.selections.window && this.slo.selections.targetDescriptors?.length) {
                    let targetDescriptorIds = this.slo.selections.targetDescriptors.map(itm => itm.target_descriptor_id)
                    let key = `${this.slo.selections.window.dataIndex}:Details`
                    results = this.items.filter(itm => (itm[key]?.target_descriptor_id && targetDescriptorIds.includes(itm[key].target_descriptor_id)))
                } else {
                    results = this.items
                }

                let sel = this.slo.selections
                if (sel.assessment && sel.window) {
                    let key = `dp${sel.window.dataPointId}`
                    results = results.filter(itm => itm[key] === 0 || !!itm[key])
                }

                if (this.scoreFilters.length) {
                    this.scoreFilters.forEach(sf => {
                        // get list of student ids already filtered so that
                        // we don't duplicate ones that belong in all of the filtered groups
                        let ids = new Set(filtered.map(d => d.student_id))
                        if (sf.type == 'numeric') {
                            let newFilter = results.filter(itm => {
                                // If no score exists for the exam/window being filtered on, it cannot be included
                                if (!itm[sf.field] && itm[sf.field] !== 0) {
                                    return false
                                }

                                if (sf.filters.greaterThan !== null && itm[sf.field] <= sf.filters.greaterThan) {
                                    return false
                                }
                                if (sf.filters.lessThan !== null && itm[sf.field] >= sf.filters.lessThan) {
                                    return false
                                }
                                if (sf.filters.equals !== null && itm[sf.field] != sf.filters.equals) {
                                    return false
                                }

                                return true
                            })
                            filtered = [...filtered, ...newFilter.filter(d => !ids.has(d.student_id))]
                        } else if (sf.type == 'text') {
                            let newFilter = results.filter(itm => {
                                // If no text exists for the field being filtered on, it cannot be included
                                if (!itm[sf.field]) {
                                    return false
                                }

                                // Cast anything as string to be safe.  Reject row if there is no text match
                                if (`${itm[sf.field]}`.toLowerCase().indexOf(sf.filters.contains.toLowerCase()) < 0) {
                                    return false
                                }

                                return true
                            })
                            filtered = [...filtered, ...newFilter.filter(d => !ids.has(d.student_id))]
                        } else if (sf.type == 'interventionLevel') {
                            // Multiple intervention levels can be selected.  Return only rows
                            // that have an intervention and is one of those selection(s)
                            if (sf.filters.interventionLevels?.length > 0) {
                                let newFilter = results.filter(itm => sf.filters.interventionLevels.find(l => `${itm[sf.field]}`.toLowerCase().includes(l.toLowerCase())))
                                filtered = [...filtered, ...newFilter.filter(d => !ids.has(d.student_id))]
                            }
                        }
                    })
                }

                return filtered.length ? filtered : results
            },
            sloEligibleItems() {
                let sel = this.slo.selections

                if (sel.assessment && sel.window) {
                    let key = `dp${sel.window.dataPointId}`
                    return this.items.filter(itm => itm[key] === 0 || !!itm[key])
                } else {
                    return this.items
                }
            },
            detailsTitle() {
                let f = this.extras.find(e=>e.show===this.activePage) || { name: 'Not Set'}
                return f.name +': '+this.studentData?.student_full_name
            },
            activePage() {
                let selectedField = this.field
                let event = 'show ' + selectedField

                this.$emit(event)
                return selectedField
            },
            extras() {
                let me = this
                let student = this.studentData

                return [{
                    name: 'Attachments',
                    show: 'student_attachments',
                    persist: true,
                    component: 'student-attachment',
                    title: 'Student Attachments',
                    listeners: {
                        refreshData: () => { me.loadData() }
                    }
                }, {
                    name: 'My Forms',
                    show: 'student_form_instances',
                    color: '#AE5DFF',
                    icon: 'fas fa-clipboard-list',
                    persist: true,
                    component: 'form-list',
                    title: 'Student smartFORMS',
                    handler() {
                        let d = me.details
                        d.title = "Student smartFORMS"
                        d.show = true
                    },
                    params: {
                        student_id: student?.student_id,
                        property: 'student_instance',
                    },
                    attrs: {
                        folderCreate: false,
                    },
                    listeners: {
                        closeDialog: () => { me.details.show = true },
                        toggleDialog: (visible) => { me.details.show = !visible },
                        openItem: () => { me.details.show = false },
                        loaded: (records) => {
                            let count = records.filter(r => r.school_year_id == me.params.school_year_id).length
                            let node  = this.$refs.grid.gridApi.getRowNode(student.student_id)
                            node.setDataValue('student_form_instances', count||0)
                        }
                    }
                }, {
                    name: 'Messages',
                    show: 'student_comments',
                    color: '#2B87FF',
                    icon: 'fas fa-comments',
                    persist: true,
                    component: 'message',
                    title: 'Comments for ' + student?.student_full_name,
                    params: {
                        student_id: student?.student_id,
                    },
                    handler() {
                        let d = me.details
                        d.title = "Comments for " + student?.student_full_name,
                        d.show = true
                    },
                    // Note: Messages/Comments total is the only extra comment that emits update count
                    updateCountHandler(items) {
                        // Message counts must drill into replies[] to count those as well
                        // to match backend logic on original datawall fetch
                        let count = 0
                        for (let itm of items) {
                            if (me.params?.school_year_id?.includes(itm.created_school_year_id)) {
                                count += 1
                            }

                            // Backend logic is to count replies completely separately,
                            // e.g. a 2020SY reply can be counted on a parent message from
                            // 2018SY that isn't counted on its own
                            count += itm.replies.filter(itm => me.params?.school_year_id?.includes(itm.created_school_year_id)).length
                        }

                        return count
                    },
                }, {
                    name: 'Tags',
                    show: 'student_tags',
                    color: '#FF675D',
                    icon: 'fas fa-tags',
                    component: 'student-tag-list',
                    title: 'Student Tags',
                    ref: 'studentTagListRef',
                    params: {
                        student_id: student?.student_id
                    },
                    attrs: {
                        style: {
                            height: '100%'
                        },
                        domLayout: "normal"
                    },
                    handler() {
                        let d = me.details
                        d.title = "Student Tags"
                        d.show = true
                    }
                }]
            },
            scoreFields () {
                return this.columnDefs.map(col =>  {
                    if (col.cellRenderer == 'scoreColumn' && col.field) {
                        return col.field
                    } else if (col.marryChildren && col.children?.length) {
                        return col.children.map(child => {
                            return (child.cellRenderer == 'scoreColumn' && child.field)
                                ? child.field
                                : null
                        })
                    } else {
                        return null
                    }
                }).flat().filter(x => !!x)
            },
            footerRows () {
                let avgs = {}
                this.scoreFields.forEach(sf => {
                    // if set, make sure we average score display values and not raw scores
                    let items = this.$_.cloneDeep(this.items)
                    let vals = items.map(itm => {
                        if (itm[sf+':Details']) {
                            let s = this.$getAlphaScore(itm[sf+':Details'].data_point_type_id, itm[sf], itm[sf+':Details'].sub_category_id)
                            itm[sf] = parseFloat(s)
                        }
                        return itm[sf]
                    }).filter(itm => `${itm}`.trim() !== '' && !isNaN(itm))

                    let avg = vals.reduce((p, c) => p + c, 0) / vals.length
                    avgs[sf] = isNaN(avg) ? avg : avg.toFixed(2)
                })
                return [avgs]
            }
        },

        watch: {
            selectedItems: {
                handler(v) {
                    this.$emit('selected', v?.length)
                }
            },
            params: {
                deep: true,
                handler(v) {
                    setTimeout(function() {
                        this.loadData()
                    }.bind(this), 500)
                }
            },
            'scatterDialog.show'(v) {
                this.$nextTick(() => this.$refs.grid.resize())
            },
            'scatterDialog.selected'(v, v1) {
                if (v.length > 1) {
                    v.forEach(val => {
                        v1.forEach(val2 => {
                            // Doesn't matter if there are multiple items with order 2 because
                            // create button becomes disabled when there are more than 2 selections
                            val.name === val2.name ? val.order = 1 : val.order = 2
                        })
                    })
                } else if (v.length === 1) {
                    v[0].order = 1
                }
            },
            panelObjectEvents() {
                // Apply any renames that have occurred
                let items = this.panelObjectEvents.filter(itm => itm.objectName == 'dataWallConfigEdit')
                for (let itm of items) {
                    let idx = this.dataWallConfigs.findIndex(dwc => dwc.id == itm.objectModel.id)
                    if (idx >= 0) {
                        this.dataWallConfigs[idx].name = itm.objectModel.name
                        this.dataWallConfigs[idx].public_flag = itm.objectModel.public_flag
                    }
                }

                // Any data wall config that has been created during this session
                // that we have not already pulled in should be taken
                items = this.panelObjectEvents
                    .filter(itm => itm.objectName == 'dataWallConfigCreation')
                    .filter(itm => !this.dataWallConfigs.find(dwc => dwc.id == itm.objectModel.id))

                this.dataWallConfigs = [...this.dataWallConfigs, ...items.map(itm => itm.objectModel)]
                this.dataWallConfigs = this.dataWallConfigs.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)

                // Any data wall config that has been deleted should be removed
                items = this.panelObjectEvents.filter(itm => itm.objectName == 'dataWallConfigDeletion')
                this.dataWallConfigs = this.dataWallConfigs.filter(dwc => !items.find(itm => itm.objectModel.id == dwc.id))
            },
        },

        mounted() {
            this.dataWallConfigId = this.userPreferences?.DATAWALL_CONFIG?.user_value || null
            this.loadData()
        },

        created() {
            window.addEventListener('beforeunload', this.editHeaders)
        },

        methods: {
            onCreateDataWallConfig() {
                let p = this.$showPanel({
                    component: ScoreDetailsConfigPanel,
                    width: 350,
                    openOn: 'right',
                    disableBgClick: true,
                    cssClass: 'main-left-slideout',
                    props: {
                        class: 'pa-5'
                    }
                })
            },

            onSelectDataWallConfig(dataWallConfig) {
                this.dataWallConfigId = dataWallConfig.id
                this.loadData(true)
            },

            onEditDataWallConfig(dataWallConfig) {
                this.$refs.menuBtn.hide()

                this.$showPanel({
                    component: ScoreDetailsConfigPanel,
                    width: 350,
                    openOn: 'right',
                    disableBgClick: true,
                    cssClass: 'main-left-slideout',
                    props: {
                        id: dataWallConfig.id,
                        class: 'pa-5'
                    }
                })
            },

            onChangeSloSelections(selections) {
                this.slo.selections = selections
            },

            filterModified(e) {
                this.$nextTick(() => {
                    if (!e.column && e.columns.length > 0) {
                        e.columns.forEach(col => {
                            let field = col.colDef.field
                            if (!col.visible) this.hiddenColumns.push(field)
                            else if (col.visible) this.hiddenColumns = this.hiddenColumns.filter(f => f != field)
                        })
                    } else {
                        let field = e.column.colDef.field
                        if (!e.visible) this.hiddenColumns.push(field)
                        else if (e.visible) this.hiddenColumns = this.hiddenColumns.filter(f => f != field)
                    }
                    this.$refs.grid.resize()
                })
            },

            doDownload() {
                let me = this
                this.$refs.grid.gridApi.exportDataAsCsv({
                    skipColumnGroupHeaders: false,
                    processCellCallback(cell) {
                        if (!cell.value && cell.value !== 0) return ''
                        let isScore = cell.column?.colDef?.cellRenderer == 'scoreColumn'
                        let v = cell.value
                        if (isScore && !isNaN(v)) {
                            v = me.$getAlphaScore(cell.column.colDef.cellRendererParams?.dataPointTypeId, v, cell.column.colDef.cellRendererParams?.subCategoryId)
                        }
                        let r = new DOMParser().parseFromString((v+'').replace('><', '>, <'), 'text/html')
                        return r.body.textContent||''
                    },
                    // removed processGroupHeaderCallback(cell) function because cell doesn't return
                    // cell.column.colDef anymore and instead just returns the group header text
                    processHeaderCallback(cell) {
                        if (cell.column.colDef.headerName.indexOf('paperclip')!=-1) return 'Attachments'
                        if (cell.column.colDef.headerName.indexOf('clipboard')!=-1) return 'smartFORMS'
                        if (cell.column.colDef.headerName.indexOf('fa-comments')!=-1) return 'Comments'
                        if (cell.column.colDef.headerName.indexOf('fa-tags')!=-1) return 'Tags'
                        if (cell.column.colDef.headerName.indexOf('fa-hands-heart')!=-1) return 'Interventions'

                        let v = cell.column.colDef.headerName || ''
                        let r = new DOMParser().parseFromString(v, 'text/html')
                        return r.body.textContent||''
                    },
                    columnGroups: true
                })
            },

            onGridReady(grid) {
                let me = this
                this.gridApi = grid.api
                this.columnApi = grid.columnApi
                this.$refs.grid.gridOptions.getRowNodeId = v => {
                    return v.student_id
                }
            },

            applySloSelections (studentIds) {
                this.slo.studentIds = studentIds || []

                // nextTick isn't good enough here, because the ag grid or some data
                // dependency is taking longer than one tick
                setTimeout(() => {
                    this.gridApi.forEachNode(node => {
                        node.setSelected(this.slo.studentIds.includes(node.data.student_id))
                    })
                }, 250)
            },

            doPin() {
                this.doCellOut()
                let uiConfig = {
                    baseColumns: [],
                    subCategoryColumns: [],
                    scoreFilters: this.scoreFilters
                }

               let all = this.columnApi.getAllColumns()
                // return
                this.columnApi.getColumnState().forEach(col => {
                    let lookup = this.columnLookup[col.colId]

                    if (lookup) {
                        if (lookup.col.subCategoryId) {
                            // assessments
                            let agcol = all.find(r=>r.colId===col.colId)
                            // Make sure it's an assessment that has a parent column.  All should
                            if (lookup.parent) {
                                // Did we already create this parent record?
                                let existing = uiConfig.subCategoryColumns.find(sc => sc.sub_category_id==lookup.parent.sub_category_id)
                                let child = {
                                    dataIndex: col.colId,
                                    hidden: col.hide,
                                    pinned: col.pinned,
                                }

                                if (existing) {
                                    existing.children.push(child)
                                } else {
                                    uiConfig.subCategoryColumns.push({
                                        sub_category_id: lookup.parent.sub_category_id,
                                        hidden: false,
                                        children: [child]
                                    })
                                }
                            }
                        } else {
                            // tags, demographics, etc
                            uiConfig.baseColumns.push({
                                data_index: col.colId,
                                dataIndex: col.colId,
                                hidden: this.hiddenColumns.find(h => h == col.colId) ? true : false,
                                pinned: col.pinned,
                            })
                        }
                    }
                })
                this.pinDialog.params.config_text = uiConfig
                this.pinDialog.show=true

            },

            createScatter() {
                this.doCellOut()
                this.scatterPlotOptions = []
                let base = {}
                Object.keys(this.params).forEach(k => {
                    base[k.replace('cohort_', '')] = this.params[k]
                })
                this.raw.columns.filter(r=> r.sub_category_id).forEach(o => {
                    o.columns.forEach(child => {
                        let childParams = {
                            data_point_id: child.dataPointId,
                            sub_category_id: child.subCategoryId,
                            data_point_name_id: child.dataPointNameId,
                            school_year_id: child.schoolYearId
                        }

                        if (child.scoreDetailTypeId) {
                            // This is not guaranteed to exist; only for score detail columns,
                            // and harmlessly undefined for top-level scores
                            childParams.score_detail_type_id = child.scoreDetailTypeId
                        }

                        this.scatterPlotOptions.push({
                            name: (o.text + ' ' + child.text).replace( /(<([^>]+)>)/ig, ''),
                            params: { ...base, ...childParams },
                        })

                    })
                })

                this.scatterDialog.show=true
                this.scatterDialog.selected = []
                this.$nextTick(() => this.$refs.grid.resize())
            },

            async doScatter() {
                this.$setLoading(true)

                let p1 = null
                let p2 = null

                this.scatterDialog.selected.forEach(sel => {
                    sel.order === 1 ? p1 = sel.params : p2 = sel.params
                })

                delete p1.sub_category_parent_id
                delete p2.sub_category_parent_id

                // Obtain appropriate datasets using analytics scatter mixing buildScatter helper
                // This has built-in support for differentiating between top-level scores
                // and score detail scores and handling all differences as needed
                let { dataset1, dataset2 } = await this.buildScatter(p1, p2)

                // Build scatter plot data
                let students = this
                    .$_.uniqBy(dataset1.concat(dataset2), 'student_id')
                    .map(s => ({
                        student_id: s.student_id,
                        student_full_name: s.student_full_name,
                        data: []
                    }))

                students.forEach(s => {
                    let first = dataset1.find(r => r.student_id === s.student_id)
                    let second = dataset2.find(r => r.student_id === s.student_id)

                    if (first && second) {
                        first.score = parseFloat(first.score)
                        second.score = parseFloat(second.score)
                        s.data.push(first, second)
                    }
                })

                this.$dockableWindow({
                    name: 'Scatter Plot',
                    component: 'scatter-plot',
                    visibilityChanged: (v) => {
                        if (v) this.$refs.grid.resize()
                    },
                    attrs: {
                        items: students,
                        // xAxisKey: me.xKey,
                        // yAxisKey: me.yKey,
                        class: 'pa-5'
                    }
                })

                this.$setLoading(false)
            },

            doSelect(item) {
                this.gridApi = item.api
                if (this.slo.create) {
                    if (item.node.selected) {
                        this.slo.studentIds.push(item.data.student_id)
                    } else {
                        this.slo.studentIds = this.slo.studentIds.filter(x => x !== item.data.student_id)
                    }
                    this.slo.studentIds = [...new Set(this.slo.studentIds)]
                } else {
                    if (item.node.selected) {
                        this.selectedItems.push(item)
                    } else {
                        this.removeFromArr(this.selectedItems, item)
                    }
                }
            },

            tagStudents() {
                this.doCellOut()
                let me = this
                let sel = this.$refs.grid.gridApi.getSelectedRows()
                this.$tagStudents(true, sel,() => {
                    me.$refs.grid.gridApi.deselectAll()
                    me.loadData()
                })
            },

            doAddTagClick() {
                this.$tagStudents(true, [{student_id: this.studentData.student_id, student_full_name: this.studentData.student_full_name }],() => {
                    this.loadData()
                    this.$refs.studentTagListRef[0].reload()
                })
            },

            removeFromArr(arr, item) {
                if (arr.length > 0) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].data.student_id === item.data.student_id) {
                            arr.splice(i, 1)
                        }
                    }
                }
            },

            closeSlo () {
                this.slo.create = false
                this.slo.selections = {}
                this.slo.studentIds = []
                this.gridApi.deselectAll()
            },

            doCellOver(meta) {
                if (meta.colDef.field === "student_full_name" && meta.data.student_id) {
                    if (this.lastStudentOver == meta.data.student_id && this.items.length > 1) return
                    if (this.lastStudentOver == meta.data.student_id && this.items.length == 1) {
                        return this.studentCard = {
                            ...this.studentCard,
                            show: true
                        }
                    }
                    let studentData = null

                    this.$axios.get(
                        'students.php?action=get&property=grade_history&id=' + meta.data.student_id + '&school_year_id=' + meta.data.school_year_id
                    ).then(response => {
                        if (response && response.data) {
                            studentData = response.data[0]
                            studentData.grade_desc = meta.data.grade // make sure we show current grade level in student card
                        }
                    }).finally(() => {
                        this.studentCard = {
                            ...this.studentCard,
                            show: true,
                            imageUrl: this.$axios.defaults.baseURL + 'students.php?action=get&property=student_image&id=' + meta.data.student_id,
                            studentRecord: studentData,
                            top: meta.event.clientY,
                            left: meta.event.clientX
                        }

                        this.lastStudentOver = meta.data.student_id
                    }).catch(error => {})
                }
            },

            doCellOut() {
                this.studentCard = {
                    ...this.studentCard,
                    show: false,
                    studentRecord: this.items.length == 1 ? this.studentCard.studentRecord : {}
                }
            },

            doCellClick(evt) {
                // do not want user to click cell if demographics
                if(evt.colDef.parent == 'Demographics') return

                this.$store.commit('global/showStudentCard', null)
                if (evt.colDef.field === 'student_full_name') {
                    // Show most recent school year of all data years selected
                    let schoolYearId = ''
                    if (this.params?.school_year_id?.length) {
                        schoolYearId = Math.max.apply(null, this.params.school_year_id)
                    }

                    this.$router.replace(`/StudentProfile/${evt.data.student_id}/${schoolYearId}`)
                    return
                } else if (evt.colDef.field || evt.colDef.isScore) {
                    this.studentData = evt.data
                    this.field = evt.colDef.isScore ? 'score_group' : evt.colDef.field

                    if(evt.colDef.isScore) {
                        let colField = evt.colDef.field
                        let scoreGroupData = _.get(evt.data, colField + ':Details')

                        if (scoreGroupData) {
                            this.doScoreClick(evt.data, scoreGroupData)
                        } else {
                            this.$snackbars.$emit('new', { text: 'No assessment data for this window', usage: 'info' })
                        }
                    } else if (evt.colDef.isScoreDetailScore) {
                        // Nothing should currently happen when clicking on score detail field, even if it's populated
                        // (Confirmed this is current behavior on classic ui)
                    } else if (evt.colDef.field == 'interventions') {
                        this.$dockableWindow({
                            name: 'Intervention Groups',
                            component: 'intervention-workspace',
                            attrs: {
                                interventionPlanId: evt.data.intervention_plan_id
                            }
                        })
                    } else if (evt.colDef.field == 'selectionBox') {
                        // ignore cell click on checkbox control.  we just want the
                        // checkbox itself to become checked
                    } else if (evt.colDef.field == 'affected_days_pct' || evt.colDef.field == 'days_equivalent_pct') {
                        // ignore cell click
                        // attendance cell helper opens window
                    } else {
                        this.details.show = true
                    }
                    this.showExtraWindow = !this.showExtraWindow
                }
            },

            loadData(isFromConfigChange) {
                this.loading = true

                this.$axios.get('dataWallConfig.php?action=get&property=config').then(res => {
                    this.dataWallConfigs = res.data.data_wall_configs
                })

                let url = this.$models.getUrl('datawall', 'read')+'&'+this.$objectToParams(this.$props.params, true)
                if (!this.params.data_wall_config_id && this.dataWallConfigId) {
                    // If user is viewing a datawall pinned to a collection, the default
                    // view shall be whichever datawall config they had selected at time of pinning,
                    // regardless of the user's site-wide default data wall
                    if (!this.params.saved_search_id || isFromConfigChange) {
                        url += `&data_wall_config_id=${this.dataWallConfigId}`
                    }
                }

                this.$axios.get(url)
                    .then(response => {
                        let success = response.data.hasOwnProperty('success') ? response.data.success : true
                        if (success) {
                            this.raw = response.data.datawall
                            this.pinDialog.params = {...this.raw.params}
                            this.pinDialog.params.dashboard_saved_search_type_id = 6

                            if (!isFromConfigChange) {
                                this.dataWallConfigId = this.raw.params.data_wall_config_id || this.dataWallConfigId
                            }

                            this.getSavedFilters(this.raw.fields) // get saved dw filters
                            this.formatColumns()
                        } else {
                            this.$snackbars.$emit('new', { text: response.data.msg, usage: 'error' })
                        }
                    })
                    .finally(() => {
                        this.loading=false
                        this.$nextTick(() => this.$refs.grid.resize())
                    })

                // attendance targets
                this.$modelGet('attendanceTargets').then(r => {
                    r.forEach(x => {
                        let target = {
                            start: parseFloat(x.start_value),
                            end: parseFloat(x.end_value),
                            color: x.attendance_target_descriptor_color
                        }
                        if (x.attendance_target_type_name === 'EQUIV') {
                            this.targets.EQUIV.push(target)
                        } else {
                            this.targets.AFFECTED.push(target)
                        }
                    })
                })
            },

            formatColumns() {
                let me = this
                let htmlDisplay = ["ell_flag", "student_disability", "student_tags", "student_full_name"]

                let c = this.raw.columns || []
                this.columnDefs = [{
                    field: 'selectionBox',
                    headerName: '',
                    pinned: 'left',
                    maxWidth: 70,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                }]

                c.forEach(col => {
                    this.columnLookup[col.dataIndex] = {
                        parent: false,
                        col: col
                    }

                    if (col.attendanceColumn) {
                        this.columnDefs.push(this.$grid.attendanceColumn(col, me, this.targets))
                        return
                    }

                    if (col.xtype=='incidentcolumn') {
                        this.columnDefs.push(this.$grid.incidentColumn(col, me))
                        return
                    }

                    let tmp = {
                        headerName: this.stripHtml(col.text),
                        field: col.dataIndex,
                        pinned: col.pinned ? col.pinned : (col.locked ? 'left' : false),
                        width: col.width ? col.width*1.5 : 90,
                        hide: col.hidden,
                        sortable: true,
                        headerTooltip: this.stripHtml(col.text),
                        tooltipValueGetter: (param) => me.stripHtml(col.text),
                        cellStyle: (params) => ({ cursor: 'pointer' }),
                    }

                    if (col.hidden) this.hiddenColumns.push(col.dataIndex)

                    this.columnLookup[col.dataIndex] = {
                        parent: false,
                        col: col
                    }

                    if (col.is_parent) {
                        const {sub_category_id, ...p} = this.raw.params
                        tmp.headerGroupComponent = 'eventheader'
                        tmp.headerGroupComponentParams = {
                            raw: col,
                            datawallParams: this.params,
                            drilldownParams: p,
                            suppressDefaultDrilldown: this.suppressDefaultDrilldown,
                            onHeaderClick: (event) => {
                                me.$emit('drilldown', event)
                            },
                        }
                        tmp.headerGroup
                    }

                    if (col.columns) {
                        tmp.marryChildren = true
                        tmp.children = []
                        tmp.raw = col

                        col.columns.forEach(child => {
                            this.columnLookup[child.dataIndex] = {
                                parent: col,
                                col: child
                            }

                            if (col.hidden || child.hidden) this.hiddenColumns.push(child.dataIndex)
                            if (child.dataIndex == "student_form_instances") {
                                child.text = '<i class="far fa-clipboard-list"></i>'
                            }
                            else if (child.dataIndex == "interventions") {
                                child.text = '<i class="far fa-hands-heart"></i>'
                            }

                            let displayNamePieces = child.text.split('<br>')
                            let displayHmtl = me.htmlDisplay(child.dataIndex)

                            // get filterType for filter display AND application if loading with user filter settings
                            let filterType =
                                child.dataIndex === 'student_tags'
                                    ? 'text'
                                    : child.dataIndex === 'interventions'
                                    ? 'interventionLevel'
                                    : 'numeric'

                            // check for saved filters and apply
                            let filtersCheck = this.savedFilters.find(f => f.name === child.dataIndex)
                            if (filtersCheck && filtersCheck.filters) {
                                me.onApplyFilters(child.dataIndex, filterType, filtersCheck.filters, false)
                            }

                            tmp.children.push({
                                hide: child.hidden ? child.hidden : col.hidden,
                                cellRenderer: me.getCellRenderer(child, displayHmtl),
                                cellRendererParams: {
                                    htmlDisplay: displayHmtl,
                                    targetDescriptors: this.raw.target_descriptors,
                                    dataPointTypeId: child.dataPointTypeId || null,
                                    subCategoryId: child.sub_category_id || null
                                },
                                filter: 'agSetColumnFilter',
                                filterParams: {
                                    cellRenderer: (params) => {
                                        if (params?.value) return me.formatFilters(params.value)
                                    }
                                },
                                headerName: this.stripHtml(child.text),
                                headerTooltip: this.stripHtml(child.text),
                                tooltipComponent: 'customTooltip',
                                tooltipComponentParams: (param) => {
                                    return {
                                        // param.value will be what tooltipValueGetter generated;
                                        // let's move it into the `tooltip` prop for the custom tooltip to use
                                        tooltip: param.value.replace('\n', '<br/>')
                                    }
                                },
                                tooltipValueGetter: (param) => {
                                    let colId = param.column?.colId
                                    let colDetailsId = `${colId}:Details`
                                    let targetDescriptorId = (colId && param.data[colDetailsId]?.target_descriptor_id)
                                    if (targetDescriptorId) {
                                        let dptid = param.data[colDetailsId].data_point_target_id
                                        let td = me.raw.target_descriptors[dptid]
                                        if (td) {
                                            let targetDescriptorName = param.data[colDetailsId]?.data_point_target_alias || td.target_descriptor_name
                                            return `${targetDescriptorName}\nTarget Range: ${td.target_beg_score} to ${td.target_end_score}`
                                        }
                                    }

                                    // Default/fallback
                                    return me.stripHtml(child.text)
                                },
                                field: child.dataIndex,
                                pinned: child.pinned ? child.pinned : (child.locked ? 'left' : false),
                                width: col.width ? col.width*1.5 : 90,
                                isScore: child.xtype == "scorecolumn",
                                isScoreDetailScore: !!child.detailColumn,
                                cellStyle: function(params) {
                                    let details = params.data[child.dataIndex + ":Details"]
                                    let color = '#FFFFFF'

                                    if (details) {
                                        let band = me.raw.target_descriptors[details.data_point_target_id]
                                        if (band) {
                                            color = band.target_descriptor_color
                                        }
                                    }

                                    return tmp.headerName != 'Demographics'
                                        ? { "cursor": "pointer", "background-color": color, textAlign: child.xtype==='scorecolumn' ? 'right' : '' }
                                        : { "background-color": color, textAlign: child.xtype==='scorecolumn' ? 'right' : '' }
                                },
                                sortable: true,
                                headerComponentParams: {
                                    field: child.dataIndex,
                                    displayName: displayNamePieces[0],
                                    displaySubtitle: displayNamePieces.length > 1 ? this.stripHtml(displayNamePieces[1]) : null,
                                    // Only show the filters for default student demographic columns, and also all data points (e.g. dp407)
                                    showScoreFilters: ['student_attachments', 'student_form_instances', 'student_comments', 'student_tags', 'interventions'].includes(child.dataIndex) || (!!child.dataIndex?.startsWith('dp')),
                                    filterType: filterType,
                                    filterValues: me.scoreFilters?.length && me.scoreFilters.find(x => x.field == child.dataIndex)
                                        ? me.scoreFilters.find(x => x.field == child.dataIndex).filters : {},
                                    filterValueName: ['student_attachments', 'student_form_instances', 'student_comments'].includes(child.dataIndex)
                                        ? 'Value'
                                        : 'Score',
                                    isFilterActive: me.scoreFilters?.length && me.scoreFilters.find(x => x.field == child.dataIndex) ? true : false,
                                    onApplyFilters: me.onApplyFilters,
                                    subCategoryId: child.sub_category_id,
                                    alphaMaps: me.$store.state.global.alphaMaps?.find(itm => itm.data_point_type_id === child.dataPointTypeId),
                                },
                                comparator: this.numberComparator,
                                parent: tmp.headerName
                            })
                        })
                    }

                    this.columnDefs.push(tmp)
                })

                // A known ag-grid bug can result in the `colId` value having a '_1' suffix appended to it when column defs are changed.
                // The recommended workaround is to explicitly specific a colId value within column defs.
                //
                // This must be done here because the columnLookup logic prebakes its data using the original colId, which means that when the '_1'
                // gets appended, the lookup fails and data may be hidden from the user (e.g. when pinning to a collection, columns will not
                // be found).
                // Reference:  https://github.com/ag-grid/ag-grid/issues/2889#issuecomment-514347233
                this.columnDefs = this.columnDefs.map(itm => ({
                    ...itm,
                    colId: itm.colId || itm.field || undefined,
                    children: itm.children
                        ? itm.children.map(itm => ({
                            ...itm,
                            colId: itm.colId || itm.field || undefined
                        }))
                        : undefined
                }))

                let hasMultilineheaders = this.raw.columns
                    .filter(col => !!col.columns) // only score header columns (grouped) may have score detail subheaders
                    .map(col => col.columns.map(child => child.text))
                    .filter(arr => arr.find(text => text?.indexOf('<br>') >= 0))
                    .length > 0

                let elem = this.gridApi.gridCore.eGui.querySelector('.ag-root')
                if (hasMultilineheaders) {
                    elem.classList.add('multiline-score-headers')
                } else {
                    elem.classList.remove('multiline-score-headers')
                }

                this.items = this.raw.students
                this.$nextTick(() => this.$refs.grid.resize() )

                if (!this.slo.create) this.setHeaders(this.columnDefs)
            },

            numberComparator(aVal, bVal, nodeA, nodeB, isInverted) {
                if (aVal === bVal) {
                    return 0
                } else if (aVal === 'null' || aVal === null || !aVal) {
                    return isInverted ? -1: 1
                } else if (bVal === 'null' || bVal === null || !bVal) {
                    return isInverted ? 1: -1
                } else { 
                    if(typeof(aVal) == 'number' && typeof(bVal) == 'number') {
                        return aVal < bVal ? -1 : 1
                    }
                    return aVal.localeCompare(bVal)
                }
            },

            getCellRenderer(child, htmlDisplay) {
                if (child.xtype == "scorecolumn" || htmlDisplay) {
                    return "scoreColumn"
                } else if (child.dataIndex == "interventions") {
                    return "interventionColumn"
                }
                return null
            },

            htmlDisplay(dataIndex) {
                return ['ell_flag', 'student_disability', 'student_tags', 'student_full_name'].filter(x => dataIndex.indexOf(x) != -1).length > 0
            },

            stripHtml(string) {
                if (string.indexOf('paperclip')!=-1) return 'Attachments'
                if (string.indexOf('clipboard')!=-1) return 'smartFORMS'
                if (string.indexOf('fa-comments')!=-1) return 'Comments'
                if (string.indexOf('fa-tags')!=-1) return 'Tags'
                if (string.indexOf('fa-hands-heart')!=-1) return 'Interventions'

                var tmp = document.createElement("DIV")
                tmp.innerHTML = string.replace(/\</g, " <")
                return tmp.textContent || tmp.innerText || ""
            },

            formatFilters(string) {
                let tmp = document.createElement("DIV")

                tmp.innerHTML = string.replace(/\</g, " <")
                if (tmp.textContent.includes("  ")) tmp.textContent = tmp.textContent.replace("  ", ", ")

                return tmp.textContent.trim() || tmp.innerText.trim() || ""
            },

            onApplyFilters(field, filterType, filters, applyNew=true) {
                let newFilter = []
                if (filterType == 'numeric') {
                    if (
                        (filters.greaterThan === '' || filters.greaterThan === null) &&
                        (filters.lessThan === '' || filters.lessThan === null) &&
                        (filters.equals === '' || filters.equals === null)
                    ) {
                        this.scoreFilters = []

                        // if saved filter is being removed, temporarily remove it
                        // it will return if/when the dw reloads
                        let index = this.savedFilters.indexOf(this.savedFilters.find(f => f.name === field))
                        if (index >= 0) this.savedFilters.splice(index, 1)
                    } else {
                        newFilter = [{
                            type: filterType,
                            field: field,
                            filters: filters,
                        }]
                    }
                } else if (filterType ==='text' || filterType == 'interventionLevel') {
                    if (filters.contains === '' || filters.contains === null) {
                        this.scoreFilters = []

                        // if saved filter is being removed, temporarily remove it
                        // it will return if/when the dw reloads
                        let index = this.savedFilters.indexOf(this.savedFilters.find(f => f.name === field))
                        if (index >= 0) this.savedFilters.splice(index, 1)
                    } else {
                        newFilter = [{
                            type: filterType,
                            field: field,
                            filters: filters,
                        }]
                    }
                }

                // add new filter to existing filter(s) instead of removing past filter(s)
                this.scoreFilters = this.scoreFilters.concat(newFilter)

                // Have to recalc columnDefs to update the isFilterActive flag on the respective field
                // If data wall has saved filters, applyNew=false prevents endless loop of formatColumns()
                if (applyNew) this.formatColumns()
            },

            doScoreClick(stdnt, rec) {
                let attrs = {
                    studentId: stdnt.student_id,
                    subCategoryId: rec.sub_category_id,
                    dataPointScoreId: rec.data_point_score_id
                }

                // Optional emit for any parent component that prefers handling clicks on its own
                this.$emit('scoreBreakdown', {
                    name: `${stdnt.student_full_name}: Score Breakdown`,
                    data: rec,
                    attrs: attrs,
                })

                if (!this.suppressDefaultDrilldown) {
                    this.$store.commit('global/addDockableWindow', {
                        name: stdnt.student_full_name + ': Score Breakdown',
                        data: rec,
                        component: 'assessment-history',
                        attrs: attrs,
                    })
                }
            },

            dismissDialog() {
                if (!this.dialogChangesUnsaved) {
                    this.details.show = false
                } else {
                    this.$confirmCancel(() => {
                        this.details.show = false
                        this.dialogChangesUnsaved = false
                    })
                }
            },

            closeDialog() {
                this.selectedStudent = []
                this.details.show=false
            },

            doCourseClick() {
                this.assignCourse = true
            },

            createIntervention() {
                this.doCellOut()

                let startDate = this.$dayjs().format('MM/DD/YYYY')
                let endDate   = this.$dayjs(this.currentYear.year_end, 'YYYY-MM-DD').format('MM/DD/YYYY')

                let value = {
                    students: [],
                    schedule: {
                        user_id: this.sessionUser.user.id,
                        start_date: startDate,
                        end_date: endDate,
                        frequency_cd: 'WEEK'
                    },
                    monitors: [{
                        monitor_user_id: this.sessionUser.user.id,
                        dateRange: [startDate, endDate],
                        start_date: startDate,
                        end_date: endDate,
                        primary_flag: true,
                        frequency_cnt: 1,
                        students: []
                    }],
                    intervention: {

                    },
                    review: {
                        dateRange: [startDate, endDate],
                        start_date: startDate,
                        end_date: endDate,
                        dashboard_meeting_id: 0,
                        frequency_cnt: 1,
                        frequency_cd: 'WEEK'
                    }
                }

                this.selectedItems.map(s => {
                    value.students.push(s.data)
                })

                let p = this.$showPanel({
                    component: InterventionCreation,
                    width: 600,
                    openOn: 'right',
                    disableBgClick: true,
                    props: {
                        class: 'pa-10',
                        value: value,
                        allowBehavior: false
                    }
                })

                p.promise.then(() => {})
            },

            doUpdateCount(id, add, type, items) {
                let countHandler = this.extras.find(itm => itm.show === type)?.updateCountHandler
                if (countHandler) {
                    let dataYearsCount = countHandler(items)
                    let stu = this.items.find(stu => stu.student_id === id)
                    if (stu) {
                        stu[type] = dataYearsCount
                    }
                }

                this.formatColumns()
            },

            getSavedFilters(filters) {
                filters.forEach(f => {
                    if (f && f.filters) {
                        this.savedFilters.push(f)
                    }
                })
            },

            editHeaders() {
                this.sloFilteredColumnDefs.forEach(head => {
                    if(head.field) {
                        head.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                    } else {
                        head.children.forEach(childHead => {
                            childHead.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == childHead.field).visible
                        })
                    }
                })
                window.localStorage.setItem('ec-datawall-headers', JSON.stringify(this.sloFilteredColumnDefs))
            },

            setHeaders(cols) {
                let me = this
                let previousHeaders = window.localStorage.getItem('ec-datawall-headers') || '[]'
                previousHeaders = JSON.parse(previousHeaders)
                if(previousHeaders.length) {
                    previousHeaders.forEach(head => {
                        cols.find(x => x.field == head.field).hide = head.hide
                    })
                    this.$refs.grid.gridApi.setColumnDefs(cols)
                }
                // add global listener to set local storage on visibility change 
                // so that going from datawall -> charts -> datawall it remains the same
                this.$refs.grid.gridApi.addGlobalListener(function(type, event) {
                    if(type.indexOf("column") >= 0) {
                        if(event.type == "columnVisible") {
                            cols.forEach(head => {
                                if(head.field) {
                                    head.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == head.field).visible
                                } else {
                                    head.children.forEach(childHead => {
                                        childHead.hide = !me.columnApi.getAllColumns().find(col => col.colDef.field == childHead.field).visible
                                    })
                                }
                            })
                            window.localStorage.setItem('ec-datawall-headers', JSON.stringify(cols))
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep.fe-grid.data-wall-container.standalone-borders {
        .layout.column:first-child {
            border: none;
        }
    }
    ::v-deep.fe-grid.data-wall-container {
        .layout.column:first-child {
            border: 1px solid #e0e1e6;
            border-top: none;
            border-left: none;
            border-radius: 0 0 5px;
        }
        .ag-root.multiline-score-headers {
            .ag-header {
                height: 96px !important;
                min-height: 96px !important;

                .ag-pinned-left-header {
                    .ag-header-row {
                        height: 42px !important;

                        ::v-deep .ag-header-group-cell {
                            height: 42px !important;
                        }
                    }

                    .ag-header-row:nth-child(2) {
                        height: 54px !important;

                        ::v-deep .ag-header-group-cell {
                            height: 54px !important;
                        }
                    }
                }

                .ag-header-viewport {
                    height: 96px !important;

                    .ag-header-container {
                        height: 54px !important;
                    }
                }

                .ag-header-row:nth-child(2) {
                    height: 54px !important;

                    ::v-deep .ag-header-group-cell {
                        height: 54px !important;
                        line-height: 24px !important;
                    }
                    ::v-deep .ag-header-cell {
                        line-height: 24px !important;
                    }

                    div {
                        max-height: 54px !important;
                    }
                }
            }
        }

        .ag-header {
            height: 84px !important;
            min-height: 84px !important;

            .ag-header-group-text, .ag-header-group-cell {
                font-size: 10px;
            }

            .ag-header-group-text, .ag-header-group-cell, .ag-header-group-cell-label, .ag-header-cell
            {
                // font-size: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;

                div, span {
                        text-overflow: ellipsis;
                        max-width: 100%;
                        overflow: hidden;
                }

                .mr-1 {
                    min-width: 12px;
                }

                .ag-header-select-all, .ag-header-select-all span {
                    overflow: visible;
                }
            }

            .ag-pinned-left-header {
                // height: 42px !important;

                .ag-header-row {
                    height: 42px !important;

                    ::v-deep .ag-header-group-cell {
                        height: 42px !important;
                    }
                }

            }

            .ag-header-viewport {
                height: 84px !important;

                .ag-header-container {
                    height: 42px !important;

                    .ag-header-row {
                        height: 42px !important;

                        ::v-deep .ag-header-group-cell {
                            height: 42px !important;
                        }
                    }
                }
            }

            .ag-header-row {
                height: 42px !important;

                ::v-deep .ag-header-group-cell {
                    height: 42px !important;
                }

                div {
                    max-height: 42px !important;
                }

                .ag-header-cell {
                    text-align: center;
                }

                .ag-header-group-cell:not(.ag-header-group-cell-no-group) {
                    border-right: 1px solid #ddd;
                }
            }

            .ag-header-row:first-child .ag-header-group-cell.ag-header-group-cell-with-group:last-child {
                border-right: none !important;
            }

            .ag-header-row:nth-child(2) {
                top: 42px !important;
            }

            .ag-header-group-cell-label {
                display: inline;
            }

            .ag-header-cell:last-of-type {
                border-right: 1px solid #ddd !important;
            }

            .ag-pinned-left-header {
                border-right: none !important;
            }

            .ag-header-cell:not(.ag-header-group-cell-no-group) {
                border-left-width: 1px;
                border-left-color: transparent;
            }
        }
    }
    ::v-deep .bordered-rows .fe-grid-grid .ag-header-cell:last-of-type {
        border-right: 1px solid #ddd !important;
    }
    ::v-deep .fe-grid {
        // .layout.column:first-child {
        //     border: 1px solid #e0e1e6;
        //     border-radius: 0 0 5px;
        // }

        ::v-deep .fe-grid-toolbar {
            ::v-deep .layout.row.nowrap {
                div.flex.text-right.shrink {
                    padding-top: 0 !important;
                }
            }
        }


        .fe-grid-container {
            border-radius: 0 0 5px 5px;
            border-left: none;
        }

        .ag-cell:last-of-type {
            border-right: 1px solid #ddd !important;
        }
    }
    ::v-deep .fe-grid.standalone-borders {
        .fe-grid-container {
            border-radius: 5px;
            border: solid 1px #e0e1e6;
        }
    }

    .launchpad-nav {
        //height: 44px;
        align-items: center;
        display: flex;
        height: 100%;

        .launchpad-nav-option {
            ::v-deep .fe-button {
                display: inline-block;
                color: var(--fe-hover) !important;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                text-align: center;
                margin: 4px 0px;
                cursor: pointer;
                border-color: #e2e2e2 !important;
            }

            ::v-deep .fe-button:hover.secondary {
                background: transparent !important;
            }

            &.btn-selected ::v-deep .fe-button.secondary {
                background-color: rgba(0, 108, 144, 0.2) !important;
            }

            &.first ::v-deep .fe-button {
                border-radius: 4px 0px 0px 4px;
            }

            &.middle ::v-deep .fe-button {
                border-radius: 0;
            }

            &.last ::v-deep .fe-button {
                border-radius: 0px 4px 4px 0px;
            }

            &.standalone {
                border-radius: 4px;
                width: 71px;
                margin-left: 6px;
            }
        }

        &-educlimber {
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            padding: 16px 16px 16px 10px;
        }
    }

    .dataWallConfigItem .edit-icon {
        visibility: hidden !important;
    }
    .dataWallConfigItem:hover .edit-icon {
        visibility: visible !important;
    }
    .dataWallConfigItem.active {
        background-color: #E5F0F4;
    }

    ::v-deep .subtitle {
        position: absolute;
        top: 16px;
        font-size: 10px;
        z-index: 0 !important;
    }
</style>
