<template>
    <v-list>
        <v-list-item-title v-if="showGroupBy">Combine Data By</v-list-item-title>

        <div class="d-flex justify-space-between flex-row" v-if="showGroupBy">
            <fe-btn :input-value="filter.group_by.school" usage="secondary" @click="filter.group_by.school = !filter.group_by.school">School</fe-btn>

            <fe-btn :input-value="filter.group_by.ethnicity" usage="secondary" @click="filter.group_by.ethnicity = !filter.group_by.ethnicity">Ethnicity</fe-btn>

            <fe-btn :input-value="filter.group_by.grade" usage="secondary" @click="filter.group_by.grade = !filter.group_by.grade">Grade</fe-btn>

            <fe-btn :input-value="filter.group_by.gender" usage="secondary" @click="filter.group_by.gender = !filter.group_by.gender">Gender</fe-btn>

            <fe-btn v-if="schoolGroups" :input-value="filter.group_by.schoolgroup" usage="secondary" @click="filter.group_by.schoolgroup = !filter.group_by.schoolgroup">As School Groups</fe-btn>
        </div>

        <v-list-group>
            <template v-slot:activator>
                <v-list-item-title>Assessment</v-list-item-title>
            </template>

            <v-list-item-content @click.stop>

                <div class="d-flex justify-space-between flex-row" :class="listView?'px-2':''">
                    <fe-label>Include Hidden Windows</fe-label>
                    <fe-switch v-model="filter.incl_hidden_data_point"></fe-switch>
                </div>
            </v-list-item-content>
        </v-list-group>

        <v-list-group>
            <template v-slot:activator>
                <v-list-item-title>Demographics</v-list-item-title>
            </template>

            <v-list-item-content @click.stop>
                <template v-for="(f, i) in fields">
                    <div :key="f.model + '-wrapper-' + i" class="d-flex justify-space-between" :class="listView ? 'flex-column': 'flex-row'">
                        <fe-label :key="f.model + '-label-' + i" class="filter-label" v-if="f.model !== 'ell_level_id' || ellLevelEnabled" :class="listView?'mx-2':''">
                            {{f.label}}
                        </fe-label>

                        <template v-if="f.model == 'ell_level_id'">
                            <fe-remote-combo
                                v-if="ellLevelEnabled"
                                :key="f.model + '-combo-' + i"
                                v-model="filter[f.model]"
                                :itemValue="f.itemValue"
                                :itemText="f.itemText"
                                :items="f.items"
                                :class="listView ? 'mx-2' : 'filter-component'"
                                hide-details
                                multiple
                                chips
                                :byId="byId"
                            />
                        </template>

                        <fe-remote-combo
                            v-else-if="f.model == 'ell_flag'"
                            :key="f.model + '-combo-' + i"
                            v-model="filter[f.model]"
                            :itemValue="f.itemValue"
                            :itemText="f.itemText"
                            :items="f.items"
                            :class="listView ? 'mx-2' : 'filter-component'"
                            hide-details
                            single
                            chips
                            byId
                        />

                        <fe-remote-exclude-combo
                            v-else
                            :key="f.model + '-combo-' + i"
                            v-model="filter[f.model]"
                            :itemValue="f.itemValue"
                            :itemText="f.itemText"
                            :url="f.url ? f.url : null"
                            :items="f.items ? f.items : null"
                            :rootProperty="f.rootProperty"
                            :class="listView ? 'mx-2' : 'filter-component'"
                            @input="f.onChange ? f.onChange(f) : () => {}"
                            hide-details
                            :byId="byId"
                        ></fe-remote-exclude-combo>
                    </div>
                </template>
                <advanced-demographic-filters
                    v-model="advancedDemographicParams"
                    vertical
                    :labelsOnTop="listView"
                    :byId="byId"
                    @result="updateAdvanced"
                    :resetEntry="resetEntry"
                />
            </v-list-item-content>
        </v-list-group>

        <v-list-group>
            <template v-slot:activator>
                <v-list-item-title>Intervention Status</v-list-item-title>
            </template>

            <v-list-item-content @click.stop>
                <template v-for="(f, i) in interventionFields">
                    <div :key="f.model + '-wrapper-' + i" class="d-flex justify-space-between" :class="listView ? 'flex-column': 'flex-row'">
                        <fe-label :key="f.model + '-label-' + i" class="filter-label" :class="listView?'mx-2':''">
                            {{f.label}}
                        </fe-label>

                        <fe-remote-exclude-combo
                            :key="f.model + '-combo-' + i"
                            v-model="filter[f.model]"
                            :itemValue="f.itemValue"
                            :itemText="f.itemText"
                            :url="f.url ? f.url : null"
                            :items="f.items ? f.items : null"
                            :rootProperty="f.rootProperty"
                            :class="listView ? 'mx-2' : 'filter-component'"
                            hide-details
                            :byId="byId"
                            @input="f.onChange ? f.onChange(f) : () => {}"
                        ></fe-remote-exclude-combo>
                    </div>
                </template>
            </v-list-item-content>
        </v-list-group>

        <v-list-group>
            <template v-slot:activator>
                <v-list-item-title>Active Student Filters</v-list-item-title>
            </template>

            <v-list-item-content @click.stop>
                <div class="d-flex justify-space-between" :class="listView ? 'flex-column': 'flex-row'">
                    <fe-label class="filter-label" :class="listView?'mx-2':''">Student Active Status</fe-label>
                    <fe-remote-combo
                        :items="studentActiveStatus"
                        byId
                        :returnObject="!byId"
                        itemValue="id"
                        itemText="name"
                        :value="filter.student_active_flag"
                        @input="onChangeStudentActiveFlag"
                        :class="listView ? 'mx-2' : 'filter-component'"
                        hide-details
                    ></fe-remote-combo>
                </div>

                <div class="d-flex justify-space-between" :class="listView ? 'flex-column': 'flex-row'">
                    <fe-label class="filter-label" :class="listView?'mx-2':''">As of Date</fe-label>
                    <fe-date-picker
                        v-model="filter.student_active_date"
                        class="filter-datepicker"
                        :class="listView ? 'mx-2' : 'filter-component'"
                    ></fe-date-picker>
                </div>
            </v-list-item-content>
        </v-list-group>

        <fe-crud v-if="$hasSecurity('SEARCH_MEAL_STATUS') && $hasSecurity('DRILLDOWN_MEAL_STATUS')" ref="mealStatusCrud" :config="$models.mealStatusType" autoload @read="postReadDemo($event, 'meal_status_id')"/>
        <fe-crud ref="elpLevelCrud" :config="$models.ellLevel" autoload @read="postReadDemo($event, 'ell_level_id')"/>
        <fe-crud ref="disabilityCodeCrud" :config="$models.studentDisabilityType" autoload @read="postReadDemo($event, 'disability_type_id')"/>
        <fe-crud ref="genderCrud" :config="$models.gender" autoload @read="postReadDemo($event, 'gender')"/>
        <fe-crud ref="ethnicityCrud" :config="$models.ethnicity" autoload @read="postReadDemo($event, 'ethnicity')"/>
        <fe-crud ref="interventionLevelCrud" :config="interventionLevelCrudConfig" autoload @read="postReadInterventionLevel"/>
    </v-list>
</template>

<script>
    import AdvancedDemographicFilters from "@/components/common/AdvancedDemographicFilters"

    export default {
        name: 'AdvancedFilters',

        components: { AdvancedDemographicFilters },

        props: {
            // Historically, some widgets did use byId (hard-coded) and some
            // components did not (inconsistent).  Because components that already
            // implemented this (AdvancedFilters) component may be using
            // full (non-byId) objects according to previous default behavior,
            // the byId default should be false
            byId: {
                type: Boolean,
                default: false,
            },
            queryParams: {

            },
            value: {

            },
            dptURL: {

            },
            selected: {

            },
            schoolGroups: {
                type: Boolean,
                default: false
            },
            showGroupBy: {
                type: Boolean,
                default: true
            },
            isProgEval: {
                type: Boolean,
                default: false
            },
            listView: {
                type: Boolean,
                default: false
            },
            resetEntry: {
                type: Boolean,
                default: true
            },
        },

        data() {
            let me = this

            return {
                localValue: {
                    student_active_date: null,
                    student_active_flag: 1
                },
                fields: [{
                    label: 'Tag',
                    model: 'tag_id',
                    url: me.tagURL(),
                    rootProperty: 'tags'
                }, {
                    label: 'Ethnicity',
                    model: 'ethnicity',
                    rootProperty: 'ethnicity',
                    itemText: "display_name",
                    itemValue: "value",
                    items: [],
                }, {
                    label: 'Disability Code',
                    model: 'disability_type_id',
                    rootProperty: 'student_disability_type',
                    items: [
                        {
                            id: -1,
                            code: 'ALLDIS',
                            description: 'All Disabilities',
                            display_name: 'All Disabilities',
                        }, {
                            id: 0,
                            code: 'NODIS',
                            description: 'No Disabilities',
                            display_name: 'No Disabilities',
                        }
                    ],
                    itemText: "display_name",
                    itemValue: "id",
                    onChange: v => this.chipHandler(v),
                }, {
                    label: 'Gender',
                    model: 'gender',
                    rootProperty: 'gender',
                    itemText: 'display_name',
                    itemValue: 'value',
                    items: [],
                }, {
                    label: 'Meal Status',
                    model: 'meal_status_id',
                    rootProperty: 'meal_status',
                    itemText: 'display_name',
                    itemValue: 'id',
                    items: [
                        {
                            id: -1,
                            code: 'ALLSTATUS',
                            name: 'Any Status',
                            display_name: 'Any Status',
                        }, {
                            id: 0,
                            code: 'NOSTATUS',
                            name: 'No Status',
                            display_name: 'No Status',
                        }
                    ],
                    onChange: v => this.chipHandler(v),
                }, {
                    label: 'EL Status',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'ell_flag',
                    items: [{
                        id: '0',
                        name: 'No'
                    }, {
                        id: '1',
                        name: 'Yes'
                    }]
                }, {
                    label: 'ELP Level',
                    model: 'ell_level_id',
                    rootProperty: 'ell_level',
                    itemText: 'display_name',
                    itemValue: 'id',
                    items: []
                }],
                interventionFields: [{
                    label: 'Intervention Level',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'intervention_level',
                    items: [
                        {
                            id: -1,
                            name: 'All Interventions',
                            code: 'ALLINT',
                            rank: 0,
                            icon: null,
                        }, {
                            id: 0,
                            name: 'No Interventions Assigned',
                            code: 'NOINT',
                            rank: 0,
                            icon: null,
                        }
                    ],
                    onChange: v => this.chipHandler(v),
                }, {
                    label: 'Intervention Exit Status',
                    itemText: 'name',
                    itemValue: 'id',
                    model: 'intervention_exit_status',
                    url: 'crud.php?property=InterventionExitStatus&action=get',
                    rootProperty: 'intervention_exit_status'
                }],
                studentActiveStatus: [{
                    id: 0,
                    name: 'Inactive'
                }, {
                    id: 1,
                    name: 'Active'
                }, {
                    id: null,
                    name: 'Both'
                }],
                filter: {
                    tag_id: null,
                    ethnicity: null,
                    disability_type_id: null,
                    gender: null,
                    intervention_level: null,
                    intervention_exit_status: null,
                    meal_status_id: null,
                    ell_flag: null,
                    ell_level_id: null,
                    sub_category_id: null,
                    incl_hidden_data_point: false,
                    group_by: {
                        school: true,
                        grade: false,
                        ethnicity: true,
                        gender: true,
                        schoolgroup: false
                    },
                    selected: {},
                    demographics: {},
                    student_active_flag: null,
                    student_active_date: null,
                }
            }
        },

        computed: {
            ellLevelEnabled () {
                return this.$store.state.global.shareableStores.ell_level_enabled
            },

            getTitle() {
                return 'More Filters'
            },

            scURL() {
                let p = this.selected

                if (p.data_point_type_id?.included) {
                    return 'subcategories.php?action=get&data_point_type_id=' + p.data_point_type_id.included[0].id
                }
                return null
            },

            advancedDemographicParams: {
                get () { return this.filter.demographics || {} },
                set (obj) { this.filter.demographics = obj }
            },

            interventionLevelCrudConfig() {
                let cfg = this.$_.cloneDeep(this.$models.interventionLevel)
                cfg.read.params.active_only = 1
                return cfg
            },
        },

        watch: {
            value(v) {
                this.filter = v || { group_by: { } }

            },
            filter: {
                handler(v) {
                    this.$emit('input', v)
                },
                deep: true
            },
            'localValue.student_active_flag'(v) {
                if (this.byId) {
                    this.filter.student_active_flag = v
                } else {
                    this.filter.student_active_flag = this.studentActiveStatus.find(itm => itm.id == v)
                }
            },
        },

        mounted() {
            let me = this

            let filter = this.$_.merge(this.filter, this.value)
            if (!this.schoolGroups) {
                // Never return schoolgroup prop to a component that doesn't use
                // it; otherwise, an unwanted schoolgroup: false setting will take
                // hold and can cause results (e.g. charting results) to group by schoolgroup
                delete filter.group_by.schoolgroup
            }
            this.filter = filter
            this.filter.student_active_flag = this.localValue.student_active_flag
        },

        methods: {
            tagURL() {
                return 'tags.php?action=get&' + this.$objectToParams(this.$props.queryParams)
            },

            // fe-remote-combo returns value only, but :value is expected
            // to be a complete { id, name } style object
            onChangeStudentActiveFlag(v) {
                if (this.byId) {
                    this.filter.student_active_flag = v
                } else {
                    this.filter.student_active_flag = this.studentActiveStatus.find(itm => itm.id == v)
                }
            },

            formatParams() {
                let p = {}
                Object.keys(this.filter.selected).forEach(sel => {
                    let val = this.filter.selected[sel].included

                    val.forEach(rec => {
                        if (!p[sel]) p[sel] = []
                        if (rec.id) {
                            p[sel].push(rec.id)
                        } else {
                            p[sel].push(rec[sel])
                        }
                    })
                })
                if (this.filter.incl_hidden_data_point) p.incl_hidden_data_point = 1
                return p
            },

            postReadDemo(data, demo) {
                if (data) {
                    let allOrNone = false

                    this.fields.forEach(x => {
                        if (x.model === demo) {
                            x.items = x.items.concat(data) // attach any All or None options

                            // check for display_name and grouping
                            let filteredOptions = []
                            let filterStr = '' // filter string needed for stepper panel to return selections on edit
                            let filterArr = [] // array for ELP Level selections on edit
                            let filterChk = [] // hold previously selected values (needed for stepper panels, not search bar)

                            // if user selected All/No Disabilities or Any/No Meal Status
                            // make sure that shows up when editing the search
                            // don't edit the filter string/array
                            if (x.model === 'disability_type_id' && this.filter[x.model] && (this.filter[x.model][0].id === -1 || this.filter[x.model][0].id === 0)) {
                                allOrNone = true
                            } else if (x.model === 'meal_status_id' && this.filter[x.model] && (this.filter[x.model][0].id === -1 || this.filter[x.model][0].id === 0)) {
                                allOrNone = true
                            } else if (!this.showGroupBy && !this.isProgEval) {
                                filterChk = this.filter[x.model]?.split(',') // for checking selected ids/values
                            }

                            x.items.forEach(itm => {

                                if (!itm.display_name_group) {
                                    itm.display_name = itm.display_name || itm.value || itm.name || itm.description
                                    filteredOptions.push(itm)

                                    // manage filter display
                                    let id = itm.id ? itm.id.toString() : itm.value
                                    if (!this.showGroupBy && !this.isProgEval && !allOrNone && x.model !== 'ell_level_id' && filterChk?.includes(id)) filterStr += id + ',' // use id/value to select correct option in menu on edit
                                    else if (!this.showGroupBy && !this.isProgEval && x.model === 'ell_level_id' && this.filter[x.model]?.indexOf(id) > -1) filterArr.push(id) // use id to select correct option in menu on edit
                                } else {
                                    let o = x.items.find(x => (x.id || x.value) == itm.display_name_group[0]) // find and return first option within display_name_group; id can be string
                                    if (!filteredOptions.find(x => x.display_name === o.display_name)) {
                                        o = {
                                            ...o,
                                            display_name: o.display_name || o.value || o.name || o.description // use display_name instead of value
                                        }
                                        filteredOptions.push(o)

                                        // manage filter display
                                        let id = o.id ? o.id.toString() : o.value
                                        if (!this.showGroupBy && !this.isProgEval && !allOrNone && x.model !== 'ell_level_id' && filterChk?.includes(id)) filterStr += id + ',' // use id/value to select correct option in menu on edit
                                        else if (!this.showGroupBy && !this.isProgEval && x.model === 'ell_level_id' && this.filter[x.model]?.indexOf(id) > -1) filterArr.push(id) // use id to select correct option in menu on edit
                                    }
                                }

                            })

                            if (!this.showGroupBy && !this.isProgEval && !allOrNone && x.model !== 'ell_level_id' && this.filter[x.model]) this.filter[x.model] = filterStr.slice(0, -1) // prevents extra values on editing
                            else if (!this.showGroupBy && !this.isProgEval && x.model === 'ell_level_id' && this.filter.ell_level_id) this.filter[x.model] = filterArr

                            x.items = filteredOptions
                        }
                    })
                }
            },

            postReadInterventionLevel(data) {
                if (data) {
                    this.interventionFields.forEach(x => {
                        if (x.model === 'intervention_level') {
                            x.items = x.items.concat(data)
                        }
                    })
                }
            },

            accept() {
                this.$emit('input', this.filter)
                this.$emit('close')
            },

            updateAdvanced(demographic, val) {
                this.$emit('result', demographic, val)
            },

            chipHandler(v) {
                let data = this.filter[v.model]

                if (typeof data === 'string') {
                    let dataArr = data.split(',').map(s => parseInt(s.trim()))
                    let currentSelection = dataArr[dataArr.length - 1]
                    if (currentSelection <= 0) {
                        // clear any selected chips if All or None is selected
                        this.filter[v.model] = v.items.filter(i => i.id === currentSelection)
                    } else {
                        // remove All or None when selecting individual options
                        this.filter[v.model] = data.replace(/-1,/, '')
                        if (data.substring(0, 2) === '0,') {
                            this.filter[v.model] = data.replace(/0,/, '')
                        }
                    }
                } else {
                    let currentSelection = data[data.length - 1]
                    if (currentSelection?.id <= 0 || this.filter[v.model][0]?.id <= 0) {
                        // clear any selected chips if All or None is selected
                        // OR clear All or None if individual option selected
                        this.filter[v.model] = v.items.filter(i => i.id === currentSelection.id)
                    }

                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .filter-component {
        margin-left: auto !important;
        max-width: 315px;
        width: 315px;
    }
</style>
