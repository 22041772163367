<template>
    <div style="background: white;">
        <div v-if="!editing" class="pa-5" style="background: white;">
            <div class="d-flex mb-5">
                <div class="title">Scatter Plot</div>
                <v-spacer/>
                <fe-btn small icon useIcon="fas fa-times" usage="ghost" @click="$emit('close')"/>
            </div>

            <div style="max-height: 400px; overflow: scroll;">
                <div class="d-flex data-set-container mb-5" @click="editing = population; selectedAssessmentIndex = null">
                    <div>
                        <div class="fs18">Student Population</div>
                        <div class="ds-description">{{ calcSummary(population.summary) || 'No search parameters defined.' }}</div>
                    </div>

                    <v-spacer/>
                    <fe-btn usage="tertiary" @click="editing = population; selectedAssessmentIndex = null">Edit</fe-btn>

                    <!-- Invisible button to keep things aligned with assessment options that have this button -->
                    <menu-btn class="mt-3" style="visibility: hidden;"/>
                </div>

                <template v-for="(assessment, i) in [assessment1, assessment2]">
                    <div class="d-flex data-set-container mb-5" :key="`ds-`+i" @click="onEditAssessment(i)">
                        <div>
                            <div class="fs18">{{assessment.name}}</div>
                            <div class="ds-description">{{ assessment.summary || 'No search parameters defined.' }}</div>
                        </div>

                        <v-spacer/>
                        <fe-btn usage="tertiary" :disabled="i === 1 && !assessment1.summary" @click="onEditAssessment(i)">Edit</fe-btn>
                        <menu-btn class="mt-3">
                            <v-list-item @click="rename(i)">Rename</v-list-item>
                        </menu-btn>
                    </div>
                </template>
            </div>

            <div class="d-flex">
                <v-spacer/>
                <fe-btn usage="primary" @click="emit" :disabled="searchDisabled">Search</fe-btn>
            </div>
        </div>

        <div v-else>
            <cohort-stepper
                v-if="selectedAssessmentIndex === null"
                byId
                title="Scatter Plot:  Student Population"
                applyBtnText="Save"
                :populationSummary.sync="population.summary"
                v-model="population.params"
                :surfaceFirstSelections="surfaceFirstSelections"
                @close="cancel"
            />
            <scatter-stepper
                v-else
                byId
                :key="editing.rowId"
                :name="editing.name"
                :value="editing.params"
                :surfaceFirstSelections="surfaceFirstSelections"
                :opposingValue="notEditing.params"
                @update:assessmentSummary="onAssessmentSummaryUpdate"
                @input="onAssessmentInput"
                @close="cancel"
                @cancel="cancel"
                @done="finishStepper"
            />
        </div>

        <fe-dialog
            width="500"
            title="Rename Data Set"
            v-if="renameDialog.show"
            dismissButtonText="Cancel"
            @dismiss="renameDialog.show=false"
            @close="renameDialog.show=false"
            @accept="finishRename"
            :acceptButtonDisabled="!formValid"
            acceptButtonText="Save"
            persistent
        >
            <v-form @submit.prevent v-model="formValid" ref="form">
                <v-text-field
                    v-model="renameDialog.rec.name"
                    flat solo dense
                    :counter="50"
                    placeholder="Enter a name..."
                    :rules="$fieldValidators('text', null, { required: true, limit: 50 })"
                />
            </v-form>
        </fe-dialog>

        <embargoed-data-agreement
            v-if="showEmbargoAcceptDialog"
            :embargoes="embargoes"
            @accept="onAcceptEmbargo"
        />
    </div>
</template>

<script>
    import * as Util from './Util'
    import EmbargoedDataAgreement from '@/components/common/EmbargoedDataAgreement'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import scatterMixin from './mixins/scatter'
    import StudentStepperPanel from '@/components/common/stepper/StudentStepperPanel'
    import ScatterStepper from './ScatterStepper'
    import CohortStepper from './CohortStepper'
    import hybrids from "./mixins/hybrids";

    export default {
        name: 'ScatterLanding',
        mixins: [scatterMixin, hybrids],
        components: {
            EmbargoedDataAgreement,
            StudentStepperPanel,
            ScatterStepper,
            CohortStepper,
            MenuBtn,
        },
        props: {
            state: {
                type: Object,
                required: false
            },
            value: {
                type: Object,
                required: false
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            configJSON: {
                type: Object,
                required: false
            },
        },
        computed: {
            searchDisabled() {
                return !this.assessment1.summary || !this.assessment2.summary
            },
        },
        watch: {
            editing(v) {},
            selectedAssessment(v) {},
        },
        data() {
            let me = this
            return {
                renameDialog: {
                    show: false,
                    index: null,
                    rec: {}
                },
                formValid: false,
                editing: null,    // User is always going to either "editing" data set 1 and "not editing"
                notEditing: null, // data set 2, or vice versa.  "not editing" is sent as opposite params to prevent identical data sets
                selectedAssessment: null,
                selectedAssessmentIndex: null,
                chartData: [],
                categories: [],
                population: {
                    params: {},
                    summary: null,
                },
                assessment1: {
                    rowId: 1,
                    name: 'Data Set 1',
                    params: {},
                    summary: null,
                },
                assessment2: {
                    rowId: 2,
                    name: 'Data Set 2',
                    params: {},
                    summary: null,
                },
                showEmbargoAcceptDialog: false,
                embargoes: [],
            }
        },
        mounted() {
            if (this.value) {
                let { populationParams, advancedFilterParams, assessmentParams1, assessmentParams2 } = this.value

                this.population.params = {
                    ...populationParams,
                    advancedFilters: { ...advancedFilterParams },
                }

                this.assessment1.params = assessmentParams1
                this.assessment2.params = assessmentParams2

                this.population.summary = this.configJSON?.summaries?.find(itm => itm.id == 'population')?.summary || this.population.summary
                this.assessment1.name = this.configJSON?.summaries?.find(itm => itm.id == 'assessment1')?.name || this.assessment1.name
                this.assessment1.summary = this.configJSON?.summaries?.find(itm => itm.id == 'assessment1')?.summary || this.assessment1.summary
                this.assessment2.name = this.configJSON?.summaries?.find(itm => itm.id == 'assessment2')?.name || this.assessment2.name
                this.assessment2.summary = this.configJSON?.summaries?.find(itm => itm.id == 'assessment2')?.summary || this.assessment2.summary

                // hybrid demographics
                this.loadEll()
                this.loadEthnicities()
                this.loadGenders()
                this.loadDisabilities()
                this.loadMealStatus()
            }
        },
        methods: {
            finishStepper(searchParams, populationParams, assessmentParams, summaries) {
                let rec = this.sets.find(r => r.name === this.editing.name)
                rec.searchParams = searchParams
                rec.populationParams = populationParams
                rec.assessmentParams = assessmentParams
                rec.summaries = summaries
                this.editing = null
            },

            onEditAssessment(index) {
                if (index === 1) {
                    if (!this.assessment1.summary) {
                        // Not allowed to edit data set 2 until data set 1 has had its selections made
                        return
                    } else if (!this.assessment2.summary) {
                        // If user is clicking into the data set 2 for the first time, copy the params from data set 1
                        this.assessment2.params = this.$_.cloneDeep(this.assessment1.params)
                    }
                }

                this.selectedAssessmentIndex = index
                this.editing = (index === 0 ? this.assessment1 : this.assessment2)
                this.notEditing = (index === 0 ? this.assessment2 : this.assessment1)
            },

            onAssessmentInput(value) {
                if (this.selectedAssessmentIndex === 0) {
                    this.assessment1.params = value
                } else {
                    this.assessment2.params = value
                }
            },

            onAssessmentSummaryUpdate(value) {
                if (this.selectedAssessmentIndex === 0) {
                    this.assessment1.summary = value
                } else {
                    this.assessment2.summary = value
                }
            },

            calcSummary(summary) {
                if (typeof(summary) == 'string') {
                    return summary || ''
                }

                let pieces = []

                if (summary?.isImportedFromClassic) {
                    pieces.push('Data set imported from Classic eduCLIMBER')
                }

                if (summary?.schoolYear) {
                    pieces.push(summary?.schoolYear)
                    if (summary.school) {
                        pieces.push(summary?.school)
                        if (summary?.grade) {
                            pieces.push(summary?.grade)
                        }
                    }
                }

                return (pieces.length > 0 ? pieces.join(' - ') : 'No search parameters defined.')
            },

            rename(index) {
                let rec = index === 0 ? this.assessment1 : this.assessment2
                this.renameDialog.show = true
                this.renameDialog.index = index
                this.renameDialog.rec = {...rec}
            },

            finishRename() {
                let rec = this.renameDialog.index === 0 ? this.assessment1 : this.assessment2
                rec.name = this.renameDialog.rec.name
            },

            remove(index) {
                this.sets.splice(index, 1)
            },

            cancel() {
                this.editing = null
            },

            async emit() {
                if (this.$hasSecurity('VIEW_EMBARGOED_DATA')) {
                    // Scatter plots are a little more work because they have
                    // 2 potentially distinct (re: assessment data) data sets and we have to
                    // scour for all unique data point vars
                    let setsParams = [this.assessment1.params, this.assessment2.params].map(set => ({
                        school_year_id: set.school_year_id,
                        data_point_type_id: set.data_point_type_id,
                        sub_category_id: set.sub_category_id,
                        data_point_name_id: set.data_point_name_id,
                    }))

                    let distinctSetsParams = this.$_.uniqBy(setsParams, itm => JSON.stringify(itm))
                    let promises = distinctSetsParams.map(params => {
                        return this.$axios.get(`targetScores.php?action=get&property=data_point_embargoes&` + this.$objectToParams(params)).then(res => res.data.data_point_embargo)
                    })

                    let promiseResults = await Promise.all(promises)

                    // Separate data sets may have overlaps (e.g. both include FAST 2020SY and
                    // that has embargoes) so make sure to take distinct by embargo id
                    let distinctEmbargoes = this.$_.uniqBy(this.$_.flatten(promiseResults), 'id')

                    if (distinctEmbargoes.length > 0) {
                        this.showEmbargoAcceptDialog = true
                        this.embargoes = distinctEmbargoes
                    } else {
                        this.doSearch()
                    }
                } else {
                    this.doSearch()
                }
            },

            onAcceptEmbargo() {
                this.showEmbargoAcceptDialog = false
                this.doSearch()
            },

            format(p) {
                if (p.advancedFilters?.disability_type_id && p.advancedFilters?.disability_type_id[0].allIds) {
                    p.advancedFilters.disability_type_id = p.advancedFilters.disability_type_id[0].allIds
                    p.advancedFilters.disability_code = 'ALLDIS' // use for stepper display if All Disabilities was chosen
                }
                if (p.advancedFilters?.meal_status_id && p.advancedFilters?.meal_status_id[0].allIds) {
                    p.advancedFilters.meal_status_id = p.advancedFilters.meal_status_id[0].allIds
                    p.advancedFilters.status_code = 'ALLSTATUS' // use for stepper display if Any Status was chosen
                }
                if (p.advancedFilters?.intervention_level && p.advancedFilters?.intervention_level[0].allIds) {
                    p.advancedFilters.intervention_level = p.advancedFilters.intervention_level[0].allIds
                    p.advancedFilters.intervention_code = 'ALLINT' // use for stepper display if All Interventions was chosen
                }

                // HYBRID DEMOS BACKEND PAYLOAD
                // make sure we're sending values to BE for queries (not groups or display_names)
                // ETHNICITY
                if (p.advancedFilters?.ethnicity) {
                    let ethnicities = ''
                    let selected = p.advancedFilters.ethnicity.split(',')
                    this.ethnicity.forEach(x => {
                        if (selected.indexOf(x.display_name) > -1 || selected.indexOf(x.value) > -1) {
                            if (x.display_name_group) {
                                x.display_name_group.forEach(n => {
                                    if (ethnicities.indexOf(n) === -1) {
                                        ethnicities += n + ',' // add name if it doesn't exist in list
                                    }
                                })
                            } else {
                                ethnicities += x.value + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = ethnicities.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.ethnicity = tmp.slice(0, -1).join(',')
                }
                // GENDER
                if (p.advancedFilters?.gender) {
                    let genders = ''
                    let selected = p.advancedFilters.gender.split(',')
                    this.gender.forEach(x => {
                        if (selected.indexOf(x.display_name) > -1 || selected.indexOf(x.value) > -1) {
                            if (x.display_name_group) {
                                x.display_name_group.forEach(n => {
                                    if (genders.indexOf(n) === -1) {
                                        genders += n + ',' // add name if it doesn't exist in list
                                    }
                                })
                            } else {
                                genders += x.value + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = genders.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.gender = tmp.slice(0, -1).join(',')
                }
                // DISABILITY
                if (
                    p.advancedFilters?.disability_type_id &&
                    p.advancedFilters?.disability_code !== 'ALLDIS' &&
                    p.advancedFilters?.disability_code !== 'NODIS' &&
                    p.advancedFilters?.disability_type_id[0].id !== -1 &&
                    p.advancedFilters?.disability_type_id[0].id !== 0
                ) {
                    let disabilities = ''
                    let selected = p.advancedFilters.disability_type_id.split(',')
                    this.disability.forEach(x => {
                        if (selected.indexOf(x.id.toString()) > -1) {
                            if (x.display_name_group) {
                                disabilities += x.display_name_group.toString() + ','
                            } else {
                                disabilities += x.id + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = disabilities.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.disability_type_id = tmp.slice(0, -1).join(',')
                } else if (p.advancedFilters?.disability_code === 'ALLDIS' || (p.advancedFilters?.disability_type_id && p.advancedFilters?.disability_type_id[0].code === 'ALLDIS')) {
                    p.advancedFilters.disability_code = 'ALLDIS'
                }
                // ELP LEVEL
                if (p.advancedFilters?.ell_level_id) {
                    let levels = []
                    let selected = p.advancedFilters.ell_level_id
                    this.elpLevel.forEach(x => {
                        if (selected.indexOf(x.id) > -1) {
                            if (x.display_name_group) {
                                levels = levels.concat(x.display_name_group)
                            } else {
                                levels.push(x.id)
                            }
                        }
                    })
                    levels = [...new Set(levels)] // remove duplicates
                    p.advancedFilters.ell_level_id = levels.slice().join(',') // .slice.join prevents infinite loop
                }
                // MEAL STATUS
                if (
                    p.advancedFilters?.meal_status_id &&
                    p.advancedFilters?.status_code !== 'ALLSTATUS' &&
                    p.advancedFilters?.status_code !== 'NOSTATUS' &&
                    p.advancedFilters?.meal_status_id[0].id !== -1 &&
                    p.advancedFilters?.meal_status_id[0].id !== 0
                ) {
                    let status = ''
                    let selected = p.advancedFilters.meal_status_id.split(',')
                    this.mealStatus.forEach(x => {
                        if (selected.indexOf(x.id.toString()) > -1) {
                            if (x.display_name_group) {
                                status += x.display_name_group.toString() + ','
                            } else {
                                status += x.id + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = status.split(',')
                    tmp = [...new Set(tmp)]
                    p.advancedFilters.meal_status_id = tmp.slice(0, -1).join(',')
                }
            },

            async doSearch() {
                this.format(this.population.params)

                Util.doScatterPlot(
                    this,
                    {
                        configJSON: {
                            scope: 'district',
                            component: 'scatter-plot',
                            summaries: [
                                { id: 'population', name: null, summary: this.calcSummary(this.population.summary) },
                                { id: 'assessment1', name: this.assessment1.name, summary: this.assessment1.summary },
                                { id: 'assessment2', name: this.assessment2.name, summary: this.assessment2.summary },
                            ],
                        },
                        population: { ...this.population.params },
                        assessment1: this.assessment1.params,
                        assessment2: this.assessment2.params,
                    },
                    'district',
                    null,
                    { ...this.state, isDirty: true }
                )

                if (this.state?.windowUUID) {
                    this.$store.commit('global/removeDockedWindow', this.$store.getters['global/dockableWindows'].find(w => w.uuid === this.state?.windowUUID))
                }

                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
.data-set-container {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px 16px 6px 16px;
    transition: all .4s;

    &:hover {
        cursor: pointer;
        box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.ds-description {
    color: #7E8494;
    font-size: 14px;
}
</style>
