<template>
  <div>
    <h3 style="font-size: 18px; margin-bottom: 4px;">Review Students, Guardians, & Forms</h3>
    <div>
      Review the students, guardian contacts, and forms to send below. Email addresses for guardians are from the 
      <a href="/uploads">Student Guardian Upload.</a>
    </div>
    <div style="margin-top: 32px;">
      Students: <span class="count" v-text="studentsCount"></span>
      Guardian Contacts: <span class="count" v-text="guardianContactsCount"></span>
      smartFORMS: <span class="count" v-text="smartformsCount"></span>
    </div>
    <ag-grid-vue
      style="height: 334px; margin-top: 8px; border: 1.5px solid #ECEDF1; border-radius: 4px; width: 90vw"
      class="ag-theme-material"
      :columnDefs="columnDefs"
      :rowData="rowData"
      >
    </ag-grid-vue>
      <fe-btn
        style="margin-top: 20px; margin-left: -4px;" 
        usage="primary"
        @click="$emit('goToCommunicationSettings')"
      >
          Continue
      </fe-btn>
  </div>
</template>
  
<script>
  import {AgGridVue} from "@ag-grid-community/vue"
  
  export default {
    name: 'ReviewEmail',
    components: {
        AgGridVue
    },
    props: ['studentsCount', 'guardianContactsCount', 'smartformsCount', 'columnDefs', 'rowData']
  };
</script>
  
<style scoped>
  .count {
      font-size: 20px;
      font-weight: bold;
  }
</style>
  