<template>
    <div class="card mx-2" @click="$emit('click')">
        <div class="d-flex upper">
            <div class="flex-grow-1 align-self-center">
                Current Enrollment & Trends
                <div class="title-tip-adj">
                    <fe-info-tip
                        class="ml-2"
                        color="#7e8494 !important"
                        icon="fas fa-info-circle"
                        tooltip="Enrollment data is provided by your Student Information System (SIS) and syncs with eduCLIMBER each night."
                    />
                </div>
            </div>

            <v-menu class="flex-shrink-1" offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn text icon>
                        <v-icon v-on="on">more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn style="padding: 0" text @click="printChart">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br/>
                        <v-btn style="padding: 0" text @click="exportChart">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </div>

        <div v-if="params" id="enrollment-data-viz" class="lower pt-3">
            <div class="d-flex details flex-grow-1">
                <div class="total">
                    <div>{{ totalEnrDisplay }}</div>
                    <div class="total-text">yesterday's enrollment</div>
                </div>

                <v-spacer/>

                <div class="delta" style="width: 210px">
                    <div v-if="fromLastYear.num != 0" class="d-flex row">
                        <div class="align-self-start value" :class="[fromLastYear.delta, fromLastYear.numLength]">
                            <v-icon class="caret">{{ `fa-caret-${fromLastYear.delta}` }}</v-icon>
                            {{ fromLastYear.num }}
                        </div>
                        <div class="align-self-start flex-grow-1 category" style="text-align: right; color: #828795;">
                            from this time last year
                        </div>
                    </div>
                    <div v-else class="d-flex row">
                        <div class="align-self-start value" :class="fromLastYear.numLength">
                            <div style="width: 23px; float: right; right: 20%">{{ fromLastYear.num }}</div>
                        </div>
                        <div class="flex-grow-1 category" style="text-align: right; color: #828795;">
                            no change from last year
                        </div>
                    </div>

                    <div v-if="fromLastMonth.num != 0" class="d-flex row">
                        <div class="align-self-start value" :class="[fromLastMonth.delta, fromLastMonth.numLength]">
                            <v-icon class="caret">{{ `fa-caret-${fromLastMonth.delta}` }}</v-icon>
                            {{ fromLastMonth.num }}
                        </div>
                        <div class="align-self-start flex-grow-1 category" style="width: 130px; text-align: right; color: #828795;">
                            from this time last month
                        </div>
                    </div>
                    <div v-else class="d-flex row">
                        <div class="align-self-start value" :class="fromLastMonth.numLength">
                            <div style="width: 23px; float: right; right: 20%">{{ fromLastMonth.num }}</div>
                        </div>
                        <div class="flex-grow-1 category" style="text-align: right; color: #828795;">
                           no change from last month
                        </div>
                    </div>
                </div>
            </div>

            <highcharts
                ref="enrollmentChart"
                class="chart"
                :options="chartOptions"
            />
        </div>
    </div>
</template>

<script>
import * as ChartExportUtil from '../common/util/ChartExports'
import html2canvas from 'html2canvas';
import Printd from 'printd';

export default {
    name: 'EnrollmentCard',

    props: {
        params: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            totalEnrollment: 16788
        }
    },

    computed: {
        totalEnrDisplay() {
            return new Intl.NumberFormat().format(this.params?.total_enrolled)
        },

        fromLastYear() {
            return this.display(this.params.from_this_time_last_year_enrolled)
        },

        fromLastMonth() {
            return this.display(this.params.from_last_month_enrolled)
        },

        chartOptions() {
            let categories = this.params?.charts.map(x => {
                let y = x.school_year
                return y.slice(0,5) + y.slice(7)
            })

            let series = [{
                name: 'Enrolled Students',
                data: this.params?.charts.map(x => x.student_count),
                color: '#006c90'
            }]

            return {
                chart: {
                    height: '170px'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories,
                    title: {
                        text: undefined
                    },
                },
                yAxis: {
                    title: {
                        text: undefined
                    },
                    labels: {
                        formatter: x => new Intl.NumberFormat().format(x.value)
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                series
            }
        }
    },

    methods: {
        display(value) {
            let delta = value < 0 ? 'down' : 'up'
            let abs = Math.abs(value)
            let numSize
            switch(abs.toString().length) {
                case 1:
                case 2:
                    numSize = 'small'
                    break
                case 3:
                    numSize = 'medium'
                    break
                default:
                    numSize = 'large'
                    break
            }

            return {
                num: new Intl.NumberFormat().format(abs),
                delta,
                numLength: numSize
            }
        },

        printChart() {
            html2canvas(document.querySelector('#enrollment-data-viz')).then(canvas => {
                const imgURL = canvas.toDataURL('image/jpeg')
                const image = document.createElement('img')
                image.src = imgURL
                image.setAttribute("id", 'print-content')
                document.body.appendChild(image)
                const printD = new Printd();
                printD.print( document.getElementById('print-content'))
                document.body.removeChild(image)
            })
        },

        exportChart() {
            ChartExportUtil.exportChart(this.$refs.enrollmentChart.chart, this.title ? this.title : 'Chart')
        },
    }
}
</script>

<style scoped lang="scss">
.card {
    cursor: pointer;

    .upper {
        height: 48px;
        border-bottom: 1px solid #F3F3F3;
    }

    .lower {
        height: 252px;

        .details {
            height: 70px;

            .total {
                min-width: 90px;
                color: var(--v-primary-base);
                font-size: 24px;
                font-weight: 600;
                line-height: 31px;
                text-align: left;
            }

            .total-text {
                text-align: left;
                font-size: 12px;
                color: #828795;
            }

            .delta {
                width: 200px;
                color: var(--v-primary-base);

                .row {
                    height: 25px;

                    .value {
                        text-align: left;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;

                        &.small {
                            width: 40px
                        }

                        &.medium {
                            width: 60px
                        }

                        &.large {
                            width: 70px
                        }

                        .caret {
                            font-size: 20px;
                            color: inherit !important;
                        }
                    }

                    .category {
                        text-align: left;
                        font-size: 12px;
                    }
                }
            }
        }

        .chart {
            height: 170px;
        }
    }
}

.title-tip-adj {
    display: inline-block;
    position: relative;
    top: -3px;
}
</style>
