<template>
    <div class="flex-fill flex-column no-scroll">
        <div class="headline">Users</div>

        <fe-grid
            style="height:100%"
            ref="grid"
            :columnDefs="columnDefs"
            :rowData="filteredData"
            displayDensity="medium"
            disableInlineCreate
            addRowBtnText="Add User"
            @beginCreate="editRec(null)"
            @cellValueChanged="updateRec($event.data,$event)"
            :frameworkComponents="frameworkComponents"
            :defaultColDefOverrides="defaultColDef"
        >
            <template #after-search-toolbar-items>
                <v-checkbox
                    v-model="showInactive"
                    label="Show Inactive Users"
                    hide-details
                    :style="{ marginTop: '6px' }"
                />
            </template>

            <template #toolbar-items>
                <fe-btn
                    v-if="mergeData && mergeData.length"
                    usage="secondary"
                    @click="mergeUsers"
                >
                    Merge Users {{ mergeData.length * 2 }}
                </fe-btn>
            </template>

            <template #cellDialog="{ cell }">
                <simple-grid-dialog-column
                    v-if="cell.colDef.cellRendererFramework=='FeDialogColumn'"
                    :cell="cell"
                    :title="cell.data.user_full_name"
                    :deleteTool="1"
                    :field="dialogColumnDefs[cell.colDef.fieldCnt].column1.field"
                    :subField="dialogColumnDefs[cell.colDef.fieldCnt].column1.subField"
                    :fieldHeader="dialogColumnDefs[cell.colDef.fieldCnt].column1.headerName"
                    :columnDefs="dialogColumnDefs[cell.colDef.fieldCnt].columnDefs && dialogColumnDefs[cell.colDef.fieldCnt].columnDefs"
                    :crudConfig="dialogColumnDefs[cell.colDef.fieldCnt].model"
                    :onRead="dialogColumnDefs[cell.colDef.fieldCnt].onRead"
                    :beforeDestroy="dialogColumnDefs[cell.colDef.fieldCnt].beforeDestroy"
                    :fieldConfig="dialogColumnDefs[cell.colDef.fieldCnt].fieldConfig"
                    :buildInsertRec="dialogColumnDefs[cell.colDef.fieldCnt].buildRec"
                    :width="dialogColumnDefs[cell.colDef.fieldCnt].width"
                    :height="dialogColumnDefs[cell.colDef.fieldCnt].height"
                    :insertDefaults="cell.data"
                    :readParams="{user_id:cell.data.id}"
                    @close="$refs.grid.openMenu = false"
                    @count="updateCellCount(cell,$event)"
                    @cellValueChanged="dialogColumnDefs[cell.colDef.fieldCnt].changed($event)"
                />

                <v-list v-else>
                    <v-list-item @click="editRec(cell.data)">Edit User Info</v-list-item>

                    <v-list-item @click="sendLogin(cell.data)" :disabled="!cell.data.active">
                        Send Login Info
                    </v-list-item>

                    <v-list-item @click="toggleActive(cell.data,cell.node)">
                        {{ cell.data.active ? 'Mark Inactive' : 'Mark Active' }}
                    </v-list-item>
                </v-list>
            </template>
        </fe-grid>

        <add-user
            v-if="showAddDialog"
            :value="userRec"
            @create="createRec" @update="updateRec"
            @close="showAddDialog=false"
        />

        <user-card
            v-if="showUserCard"
            :value="userRec"
            :fields="[
                {name:'Id', field:'id'},
                {name:'Title', field:'title'},
                {name:'Email', field:'email_addr'},
                {name:'Login', field:'login'},
                {name:'Active', field:'active', type:'boolean'},
                {name:'Schools', field:'school_cnt'},
                {name:'Grades', field:'grade_cnt'},
                {name:'IDs', field:'indentification_cnt'},
            ]"
            @close="showUserCard=false"
        />

        <fe-crud
            ref="crud"
            autoload
            :config="$models.user"
            @read="rowData = $event"
        />

        <!-- get all school years. even inactive -->
        <fe-crud
            autoload
            :config="{ defaults: {
                        endpoint: 'crud.php?property=SchoolYear&action=get',
                        rootProperty: 'school_year'
                    }}"
            @read="schoolYears = $event.reverse(); schoolYearNames = $event.reduce((acc,x)=>{acc[x.id]=x.name; return acc},{})"
        />

        <fe-crud
            ref="doNotMergeCrud"
            :autoload="false"
            :config="$models.userDoNotMerge"
            disableSnackbars
        />
    </div>
</template>

<script>
import SimpleGridDialogColumn from '@/components/common/SimpleGridDialogColumn'
import AddUser from './dialogs/AddUser'
import UserCard from './dialogs/UserCard'
import CustomTooltip from './renderers/CustomTooltip'

export default {
    name: 'Users',

    components: {
        SimpleGridDialogColumn,
        AddUser,
        UserCard,
        CustomTooltip,
    },

    data() {
        let me = this
        return {
            userRec: null,
            showUserCard: false,
            showAddDialog: false,
            showInactive: false,
            showMergeDialog: false,
            schoolYears: [],
            schoolYearNames: {},
            mergeData: [],
            mergeDataIdIndex: {},
            isBusyMerging: false,
            rowData: null,
            includeDoNotMerge: false,
            mergeByName: [],
            columnDefs: [{
                headerName: 'Name and Information',
                field: 'user_full_name', width: 260,
                cellRenderer: v => {
                    let before = this.mergeDataIdIndex[v.data.id]
                        ? '<i style="font-size:12px;" class="fe-grid-icon fas fa-exchange red--text mr-2"></i>'
                        : ''

                    let after = '<i style="font-size:12px;" class="fe-grid-icon fas fa-id-card-alt ml-2 grey--text text--lighten-1"></i>'
                    return before + v.value + after
                },
                onCellClicked: this.openUserCard,
            }, {
                headerName: 'Title',
                field: 'title',
                width: 240
            }, {
                headerName: 'Email',
                field: 'email_addr',
                hide: true
            }, {
                headerName: 'Login',
                field: 'login',
                hide: true
            }, {
                headerName: 'Groups',
                field: 'groups',
                maxWidth: 100,
                cellRendererFramework: 'FeDialogColumn',
                cellRendererParams: v => {
                    return {value: v.data.group_cnt}
                },
                onCellClicked: this.onCellClicked,
                fieldCnt: 'group_cnt',
                cellClass: 'users-grid-popout-column',
                tooltipComponent: 'customTooltip',
                tooltipComponentParams: (param) => {
                    return {
                        tooltip: param.value
                    }
                },
                tooltipValueGetter: (v) => {
                    let value = ''
                    if (v.data.group_cnt > 1) {
                        let arr = v.data.groups.split('|')
                        arr.sort()
                        arr.forEach((a, index) => {
                            value += a
                            if (index != arr.length - 1) value += '; '
                        })
                    } else if (v.data.group_cnt == 1) {
                        value = v.data.groups
                    }
                    return value
                },
            }, {
                headerName: 'Extra Permissions',
                field: 'permissions',
                maxWidth: 100,
                cellRendererFramework: 'FeDialogColumn',
                cellRendererParams: v => {
                    return {value: v.data.security_cnt}
                },
                onCellClicked: this.onCellClicked,
                fieldCnt: 'security_cnt',
                cellClass: 'users-grid-popout-column',
                tooltipComponent: 'customTooltip',
                tooltipComponentParams: (param) => {
                    return {
                        tooltip: param.value
                    }
                },
                tooltipValueGetter: (v) => {
                    let value = ''
                    if (v.data.security_cnt > 1) {
                        let arr = v.data.permissions.split('|')
                        arr.sort()
                        arr.forEach((a, index) => {
                            value += a
                            if (index != arr.length - 1) value += '; '
                        })
                    } else if (v.data.security_cnt == 1) {
                        value = v.data.permissions
                    }
                    return value
                },
            }, {
                headerName: 'Schools',
                field: 'schools',
                maxWidth: 100,
                cellRendererFramework: 'FeDialogColumn',
                cellRendererParams: v => {
                    return {value: v.data.school_cnt}
                },
                onCellClicked: this.onCellClicked,
                fieldCnt: 'school_cnt',
                cellClass: 'users-grid-popout-column',
                tooltipComponent: 'customTooltip',
                tooltipComponentParams: (param) => {
                    return {
                        tooltip: param.value
                    }
                },
                tooltipValueGetter: (v) => {
                    let value = ''
                    if (v.data.school_cnt > 1) {
                        let arr = v.data.schools.split('|')
                        arr.sort()
                        arr.forEach((a, index) => {
                            value += a
                            if (index != arr.length - 1) value += '; '
                        })
                    } else if (v.data.school_cnt == 1) {
                        value = v.data.schools
                    }
                    return value
                },
            }, {
                headerName: 'Grades',
                field: 'grades',
                maxWidth: 100,
                cellRendererFramework: 'FeDialogColumn',
                cellRendererParams: v => {
                    return {value: v.data.grade_cnt}
                },
                onCellClicked: this.onCellClicked,
                fieldCnt: 'grade_cnt',
                cellClass: 'users-grid-popout-column',
                tooltipComponent: 'customTooltip',
                tooltipComponentParams: (param) => {
                    return {
                        tooltip: param.value
                    }
                },
                tooltipValueGetter: (v) => {
                    let value = ''
                    if (v.data.grade_cnt > 1) {
                        let arr = v.data.grades.split('|')
                        arr.sort()
                        arr.forEach((a, index) => {
                            value += a
                            if (index != arr.length - 1) value += ', '
                        })
                    } else if (v.data.grade_cnt == 1) {
                        value = v.data.grades
                    }
                    return value
                },
            }, {
                headerName: 'IDs',
                field: 'indentification_cnt',
                hide: true,
                maxWidth: 100,
                cellRendererFramework: 'FeDialogColumn',
                onCellClicked: this.onCellClicked,
                fieldCnt: 'indentification_cnt',
            }, {
                headerName: 'Approve SLO',
                field: 'approve_slo',
                cellRendererFramework: 'FeGridToggle',
                maxWidth: 100,
            }, {
                headerName: 'Force Top Secret',
                field: 'force_top_secret',
                cellRendererFramework: 'FeGridToggle',
                maxWidth: 100,
            }, {
                headerName: 'Last Login',
                field: 'last_login',
            }, {
                headerName: 'Created',
                field: 'created',
                hide: true
            }, {
                colId: 'toolmenu',
                maxWidth: 50,
                cellRenderer: v => {
                    return '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>'
                },
                onCellClicked: this.onCellClicked,
            }
            ],
            dialogColumnDefs: {
                group_cnt: {
                    model: this.$models.userGroupUser,
                    column1: {headerName: 'Groups', field: 'user_group_name'},
                    fieldConfig: {
                        type: 'combo',
                        url: 'userGroup.php?action=get',
                        rootProperty: 'user_group',
                        itemSubtext: 'description',
                        filter: function (x) {
                            return !this.find(y => y.user_group_id == x.id)
                        }
                    },
                    buildRec: (v, d) => ({user_group_id: v.id, user_id: d.id,}),
                },

                security_cnt: {
                    model: this.$models.userSecurity,
                    column1: {headerName: 'Permissions', field: 'security_name',},
                    fieldConfig: {
                        type: 'combo',
                        url: 'security.php?action=get&property=codes&all=true',
                        rootProperty: 'security',
                        itemSubtext: 'description',
                        filter: function (x) {
                            return !this.find(y => y.security_id == x.id)
                        }
                    },
                    buildRec: (v, d) => ({security_id: v.id, user_id: d.id,}),
                    width: '600px',
                },

                school_cnt: {
                    model: {
                        defaults: {
                            endpoint: 'userSchool.php',
                            rootProperty: 'user_school'
                        },
                        create: {
                            params: {action: 'create'}
                        },
                        read: {
                            params: {action: 'get_composite'}
                        },
                        update: {
                            params: {action: 'update_composite'}
                        },
                        destroy: {
                            params: {action: 'update_composite'}
                        }
                    },
                    column1: {headerName: 'Schools', field: 'school_name', editable: false},
                    columnDefs: [{
                        headerName: 'All Years',
                        valueSetter: v => {
                            if (!this.backupSchoolYearId) this.backupSchoolYearId = {}
                            if (!v.oldValue) {
                                this.backupSchoolYearId[v.data.user_id + ',' + v.data.school_id] = v.data.school_year_id
                                v.data.school_year_id = [-1]
                            } else {
                                v.data.school_year_id = this.backupSchoolYearId[v.data.user_id + ',' + v.data.school_id] || [this.currentYear.id]
                            }
                            return true
                        },
                        valueGetter: v => {
                            return v.data.school_year_id
                                ? v.data.school_year_id.length == 1 && v.data.school_year_id[0] == -1
                                : false
                        },
                        cellRendererFramework: 'FeGridToggle',
                        maxWidth: 100,
                    }, {
                        headerName: 'Specific Years', field: 'school_year_id',
                        maxWidth: 120,
                        cellRenderer: v => {
                            if (!v.data.school_year_id) return ''
                            else if (v.data.school_year_id.length == 1) return this.schoolYearNames[v.data.school_year_id[0]]
                            return v.data.school_year_id.length + ' Selected'
                        },
                        editable: true,
                        cellEditorFramework: 'FeGridChipSelect',
                        cellEditorParams: {
                            rowDataKey: 'school_year_id',
                            items: () => this.schoolYears,
                            keyProp: 'id',
                            labelProp: 'name',
                            multiple: true,
                            mode: 'edit',
                            disableChips: true,
                        }
                    }],
                    fieldConfig: {
                        type: 'combo', url: 'schools.php?action=get', rootProperty: 'schools',
                        filter: function (x) {
                            return !this.find(y => y.school_id == x.id)
                        }
                    },
                    buildRec: (v, d) => ({
                        school_id: v.id,
                        user_id: d.id,
                        school_year_id: this.currentYear.id,
                    }),
                    beforeDestroy: v => {
                        v.school_year_id = []
                        return v
                    },
                    changed: (v) => {
                        if (v.oldValue != v.newValue) {
                            v.data.school_year_id = v.data.school_year_id.length > 1
                                ? v.data.school_year_id.filter(x => x > 0)
                                : v.data.school_year_id

                            this.$axios.post('userSchool.php?action=update_composite', JSON.stringify({
                                user_school: [v.data]
                            })).then(r => {
                                v.crud.read()
                                this.$ecResponse(r)
                            })
                        }
                    },
                    width: '600px',
                },

                indentification_cnt: {
                    model: {
                        defaults: {
                            endpoint: 'users.php',
                            rootProperty: 'user_identification'
                        },
                        read: {
                            params: {
                                action: 'get',
                                property: 'user_identification'
                            }
                        },
                        destroy: {
                            params: {
                                action: 'delete',
                                property: 'user_identification'
                            }
                        }
                    },
                    column1: {headerName: 'IDs', field: 'unique_id', editable: false},
                    columnDefs: [{
                        headerName: 'Assessment Type',
                        field: 'data_point_type_name',
                        width: 150
                    }, {
                        headerName: 'Upload ID',
                        field: 'upload_id',
                        width: 100
                    }],
                    fieldConfig: {
                        insertDisabled: true
                    },
                    width: '600px'
                },

                grade_cnt: {
                    model: {
                        defaults: {
                            endpoint: 'userGrade.php',
                            rootProperty: 'user_grade'
                        },
                        create: {
                            params: {action: 'create'}
                        },
                        read: {
                            params: {action: 'get_composite'}
                        },
                        update: {
                            params: {action: 'update_composite'}
                        },
                        destroy: {
                            params: {action: 'update_composite'}
                        }
                    },
                    column1: {headerName: 'Grades', field: 'grade_name', editable: false},
                    columnDefs: [{
                        headerName: 'All Years',
                        valueSetter: v => {
                            if (!this.backupGradeYearId) this.backupGradeYearId = {}
                            if (!v.oldValue) {
                                this.backupGradeYearId[v.data.user_id + ',' + v.data.grade_id] = v.data.school_year_id
                                v.data.school_year_id = [-1]
                            } else {
                                v.data.school_year_id = this.backupGradeYearId[v.data.user_id + ',' + v.data.grade_id] || [this.currentYear.id]
                            }
                            return true
                        },
                        valueGetter: v => {
                            return v.data.school_year_id
                                ? v.data.school_year_id.length == 1 && v.data.school_year_id[0] == -1
                                : false
                        },
                        cellRendererFramework: 'FeGridToggle',
                        maxWidth: 100,
                    }, {
                        headerName: 'Specific Years', field: 'school_year_id',
                        maxWidth: 120,
                        cellRenderer: v => {
                            if (!v.data.school_year_id) return ''
                            else if (v.data.school_year_id.length == 1) return this.schoolYearNames[v.data.school_year_id[0]]
                            return v.data.school_year_id.length + ' Selected'
                        },
                        editable: true,
                        cellEditorFramework: 'FeGridChipSelect',
                        cellEditorParams: {
                            rowDataKey: 'school_year_id',
                            items: () => this.schoolYears,
                            keyProp: 'id',
                            labelProp: 'name',
                            multiple: true,
                            mode: 'edit',
                            disableChips: true,
                        }
                    }],
                    fieldConfig: {
                        type: 'combo', url: 'grades.php?action=get', rootProperty: 'grades',
                        filter: function (x) {
                            return !this.find(y => y.grade_id == x.id)
                        }
                    },
                    buildRec: (v, d) => ({
                        grade_id: v.id,
                        user_id: d.id,
                        school_year_id: this.currentYear.id,
                    }),
                    beforeDestroy: v => {
                        v.school_year_id = []
                        return v
                    },
                    changed: (v) => {
                        if (v.oldValue != v.newValue) {
                            v.data.school_year_id = v.data.school_year_id.length > 1
                                ? v.data.school_year_id.filter(x => x > 0)
                                : v.data.school_year_id

                            this.$axios.post('userGrade.php?action=update_composite', JSON.stringify({
                                user_grade: [v.data]
                            })).then(r => {
                                v.crud.read()
                                this.$ecResponse(r)
                            })
                        }
                    },
                    width: '600px',
                },
            },
            frameworkComponents: {
                customTooltip: CustomTooltip,
            },
            defaultColDef: {
                customTooltip: 'customTooltip',
            },
        }
    },

    computed: {
        currentYear() {
            return this.$store.state.global.sessionUser.currentYear
        },

        filteredData() {
            if (!this.rowData) return []
            return this.showInactive ? this.rowData : this.rowData.filter(x => x.active)
        },

        recsToMerge() {
            return this.mergeData.filter(x => x.keep_user_id && x.merge_user_id)
        },
    },

    watch: {
        includeDoNotMerge() {
            this.loadMergeData()
        },
    },

    mounted() {
        this.loadMergeData()

        this.$refs.grid.gridOptions.deltaRowDataMode = true
        this.$refs.grid.gridOptions.getRowNodeId = v => {
            return v.id
        }
        this.$refs.grid.gridOptions.getRowStyle = v => {
            if (!v.data.active) return {color: 'rgba(0,0,0,.45)'}
        }
    },

    methods: {
        updateCellCount(cell, count) {
            // don't want to update cell value as that's now text values
            // only want to update count
            cell.data[cell.colDef.fieldCnt] = count
            this.$refs.grid.gridOptions.api.refreshCells({force: true, suppressFlash: true})
        },

        onCellClicked(v) {
            let cfg = v.column.colId == 'toolmenu' ? 'menu' : null
            this.$refs.grid.setDialogCell(v, cfg)
        },

        openUserCard(v) {
            this.userRec = v.data
            if (v.colDef.field === 'u1_user_full_name') this.userRec = this.rowData.find(x => x.id == v.data.u1_id)
            if (v.colDef.field === 'u2_user_full_name') this.userRec = this.rowData.find(x => x.id == v.data.u2_id)
            this.showUserCard = true
        },

        editRec(v) {
            this.userRec = v
            this.showAddDialog = true
        },

        loadMergeData() {
            let incl = this.includeDoNotMerge ? 1 : !this.includeDoNotMerge ? 0 : this.includeDoNotMerge
            this.$modelGet('userMerge', {property: 'users', include_distinct: incl}).then(r => {
                this.mergeData = r
                this.mergeDataIdIndex = {}
                this.mergeData?.forEach(x => {
                    x.u1_user_full_name = `${x.u1_lname}, ${x.u1_fname}`
                    x.u2_user_full_name = `${x.u2_lname}, ${x.u2_fname}`
                    this.mergeDataIdIndex[x.u1_id] = true
                    this.mergeDataIdIndex[x.u2_id] = true
                })
                this.$refs.grid.gridApi.redrawRows()
            })
        },

        createRec(v) {
            this.$refs.crud.create(v).then(r => {
                let newRec = r.data.users
                let gridApi = this.$refs.grid.gridApi
                this.showAddDialog = false
                this.rowData = newRec.concat(this.rowData)
                this.$emit('countChanged')

                this.$nextTick(() => {
                    gridApi.ensureIndexVisible(0)
                    this.$flashGridRow(this.$refs.grid, 0, 3000)
                    this.loadMergeData()
                })
            })
        },

        updateRec(v, e) {
            if (e && (e.colDef.field.endsWith('_cnt') || e.colDef.fieldCnt)) return
            this.showAddDialog = false
            this.$refs.crud.update(v).finally(() => {
                this.$refs.crud.read()
                this.loadMergeData()
            })
        },

        sendLogin(v) {
            this.$messageBox({
                title: 'Email Account Info',
                persistent: true,
                maxWidth: 400,
                message: 'Would you like to email this user their account and login information?',
                actions: [{
                    text: 'Cancel',
                }, {
                    text: 'Ok',
                    onClick: () => {
                        this.$axios.get('users.php?action=resetPswd', {
                            params: {
                                id: v.id
                            }
                        }).then(r => this.$ecResponse(r))
                    }
                }]
            })
        },

        toggleActive(v, node) {
            v.active = !v.active
            this.$refs.grid.gridApi.redrawRows(node)
            this.updateRec(v)
        },

        mergeUsers() {
            let me = this
            me.$store.commit('global/addDockableWindow', {
                name: 'Merge Users',
                component: 'users-merge',
                events: {
                    close() {
                        me.loadMergeData()
                    },
                }
            })
        }
    },
}
</script>

<style lang="scss">
.user-merge-grid .ag-header-cell {
    background-color: #f5f6f8;
}

.user-merge-header {
    background-color: #fff !important;

    .ag-header-cell-label {
        font-weight: normal;
        text-align: center !important;
    }
}

.fe-grid-container {
    position: relative;
    height: 100%;
    border: 1px solid #e0e1e6;
    border-radius: 5px;
}

.users-grid-popout-column {
    .fe-dialog-column {
        padding: 0 !important;
    }
}

.ec-users-grid-popout-column-tooltip {
    max-width: 300px;
}

.ec-users-grid-popout-column-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 65%;
}

.ec-users-grid-popout-column-truncate-less {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 90%;
}

.ec-users-grid-popout-column-count {
    position: absolute;
    right: 20px;
}
</style>
