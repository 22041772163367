<template>
    <div style="background: white;">
        <div v-if="!editing" class="pa-5" style="background: white;">
            <div class="d-flex mb-5">
                <div class="title">Longitudinal Chart</div>
                <v-spacer/>
                <fe-btn small icon useIcon="fas fa-times" usage="ghost" @click="$emit('close')"/>
            </div>

            <div style="max-height: 400px; overflow: scroll;">
                <template v-for="(s, i) in sets">
                    <div class="d-flex data-set-container mb-5" :key="`ds-`+i" @click="editing=s">
                        <div>
                            <div class="fs18">{{s.name}}</div>
                            <div class="ds-description">{{ calcSummary(s.summaries) || 'No search parameters defined.' }}</div>
                        </div>

                        <v-spacer/>
                        <fe-btn usage="tertiary" @click="editing=s">Edit</fe-btn>
                        <menu-btn class="mt-3">
                            <v-list-item @click="rename(i)">Rename</v-list-item>
                            <v-list-item v-if="i > 0" @click="remove(i)">Remove</v-list-item>
                            <v-list-item @click="replicate(i)">Replicate</v-list-item>
                        </menu-btn>
                    </div>
                </template>
            </div>

            <div class="mt-5">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <fe-btn usage="ghost" useIcon="fal fa-plus" v-on="on" :disabled="!hasConfiguredDataSet">Additional Data Set</fe-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(r,i) in reportByItems" :key="`rb-`+i" @click="r.handler()">
                            {{ r.name }}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <div class="d-flex">
                <v-spacer/>
                <fe-btn usage="primary" @click="emit" :disabled="searchDisabled">Search</fe-btn>
            </div>
        </div>

        <div v-else>
            <longitudinal-stepper v-model="editing.params" :surfaceFirstSelections="surfaceFirstSelections" byId :dataSetName="editing.name" @close="cancel" @cancel="cancel" @done="finishStepper"/>
        </div>

        <fe-dialog
            width="500"
            title="Rename Data Set"
            v-if="renameDialog.show"
            dismissButtonText="Cancel"
            @dismiss="renameDialog.show=false"
            @close="renameDialog.show=false"
            @accept="finishRename"
            :acceptButtonDisabled="!formValid"
            acceptButtonText="Save"
            persistent
        >
            <v-form @submit.prevent v-model="formValid" ref="form">
                <v-text-field
                    v-model="renameDialog.rec.name"
                    flat solo dense
                    :counter="50"
                    placeholder="Enter a name..."
                    :rules="$fieldValidators('text', null, { required: true, limit: 50 })"
                />
            </v-form>
        </fe-dialog>

        <embargoed-data-agreement
            v-if="showEmbargoAcceptDialog"
            :embargoes="embargoes"
            @accept="onAcceptEmbargo"
        />
    </div>
</template>

<script>
    import * as Util from './Util'
    import EmbargoedDataAgreement from '@/components/common/EmbargoedDataAgreement'
    import LongitudinalStepper from './LongitudinalStepper'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import longitudinalMixin from './mixins/longitudinal'
    import hybrids from "./mixins/hybrids";

    export default {
        name: 'LongitudinalLanding',
        mixins: [longitudinalMixin, hybrids],
        components: {
            EmbargoedDataAgreement,
            LongitudinalStepper,
            MenuBtn
        },
        props: {
            state: {
                type: Object,
                required: false
            },
            value: {
                type: Array,
                required: false
            },
            surfaceFirstSelections: {
                type: Boolean,
                default: false
            },
            configJSON: {
                type: Object,
                required: false
            },
        },
        computed: {
            searchDisabled() {
                let disabled = false
                this.sets.forEach(s => {
                    if (!s?.params?.assessment?.sub_category_id) disabled = true
                })
                return disabled
            },
            configuredDataSets() {
                return this.sets.filter(set => (set.summaries?.isImportedFromClassic || set.summaries?.populationSummary?.schoolYear))
            },
            hasConfiguredDataSet() {
                return (this.configuredDataSets.length > 0)
            },
        },
        data() {
            let me = this
            return {
                renameDialog: {
                    show: false,
                    index: null,
                    rec: {}
                },
                formValid: false,
                editing: null,
                chartData: [],
                categories: [],
                sets: [{
                    name: 'Data Set 1',
                    params: {
                        population: {},
                        assessment: {}
                    }
                }],
                reportByItems: [{
                    id: 1,
                    name: 'Custom',
                    handler() {
                        me.addCustom()
                    }
                }, {
                    id: 2,
                    name: 'By Grade',
                    handler() {
                        me.byGrade()
                    }
                }, {
                    id: 3,
                    name: 'By School',
                    handler() {
                        me.bySchool()
                    }
                }, {
                    id: 4,
                    name: 'By Gender',
                    handler() {
                        me.byGender()
                    }
                }, {
                    id: 5,
                    name: 'By Ethnicity',
                    handler() {
                        me.byEthnicity()
                    }
                }],
                reportBy: null,
                showEmbargoAcceptDialog: false,
                embargoes: [],
            }
        },
        mounted() {
            if (this.value) {
                this.sets = this.value.map((set, i) => {
                    let { searchParams, populationParams, advancedFilterParams, assessmentParams } = Util.unpackSearchParams(set)
                    //let population = set.find(itm => itm.saved_search_group_name == 'population')
                    //let advancedFilters = set.find(itm => itm.saved_search_group_name == 'advanced-filters')
                    //let assessment = set.find(itm => itm.saved_search_group_name == 'assessment')

                    return {
                        name: populationParams?.data_set_name || `Data Set ${i + 1}`,
                        summaries: this.configJSON?.dataSetSummaries?.find(itm => itm.groupId == populationParams?.saved_search_group_id)?.summaries || null,
                        searchParams: { ...searchParams },
                        params: {
                            population: {
                                ...populationParams,
                                advancedFilters: { ...advancedFilterParams }
                            },
                            assessment: { ...assessmentParams },
                        },
                        populationParams: { ...populationParams },
                        assessmentParams: { ...assessmentParams },
                    }
                })
            }

            // hybrid demographics
            this.loadEll()
            this.loadEthnicities()
            this.loadGenders()
            this.loadDisabilities()
            this.loadMealStatus()
        },
        methods: {
            UUIDv4() {
                let firstPart = (Math.random() * 46656) | 0
                let secondPart = (Math.random() * 46656) | 0
                let thirdPart = (Math.random() * 46656) | 0
                firstPart = ("000" + firstPart.toString(36)).slice(-3)
                secondPart = ("000" + secondPart.toString(36)).slice(-3)
                thirdPart = ("000" + thirdPart.toString(36)).slice(-3)
                return firstPart + secondPart + thirdPart
            },

            finishStepper(searchParams, populationParams, assessmentParams, summaries) {
                let rec = this.sets.find(r => r.name === this.editing.name)
                let recIndex = this.sets.findIndex(r => r.name === this.editing.name)

                rec.searchParams = searchParams
                rec.populationParams = populationParams
                rec.assessmentParams = assessmentParams
                rec.summaries = summaries

                this.editing = null
                this.sets.splice(recIndex, 1, rec) // rebuild array so computed props see it
            },

            calcSummary(summaries) {
                let pieces = []

                if (summaries?.isImportedFromClassic) {
                    pieces.push('Data set imported from Classic eduCLIMBER')
                }

                if (summaries?.populationSummary?.schoolYear) {
                    pieces.push(summaries?.populationSummary?.schoolYear)
                    if (summaries.populationSummary?.school) {
                        pieces.push(summaries?.populationSummary?.school)
                        if (summaries.populationSummary.grade) {
                            pieces.push(summaries?.populationSummary?.grade)
                        }
                    }
                }

                // Assessment summary is a simple string
                if (pieces.length > 0 && summaries?.assessmentSummary) {
                    pieces.push(summaries.assessmentSummary)
                }

                return (pieces.length > 0 ? pieces.join(' - ') : 'No search parameters defined.')
            },

            byGrade() {
                let r = this.sets[0]
                this.sets = []

                this.$modelGet('grade', { active: 1 }).then(resp => {
                    resp.forEach(g => {
                        this.sets.push({
                            ...this.$_.cloneDeep(r),
                            name: 'Grade: ' + g.name,
                            searchParams: { ...r.searchParams, grade_id: [g.id] },
                            populationParams: { ...r.populationParams, grade_id: [g.id] },
                            params: {
                                population: { ...r.params.population, grade_id: [g.id] },
                                assessment: { ...r.params.assessment },
                            },
                            summaries: {
                                ...r.summaries,
                                populationSummary: {
                                    ...r.summaries.populationSummary,
                                    grade: g.name,
                                }
                            },
                        })
                    })
                })
            },

            bySchool() {
                let r = this.sets[0]
                this.sets = []

                this.$modelGet('school', { active: 1 }).then(resp => {
                    resp.forEach(g => {
                        this.sets.push({
                            ...this.$_.cloneDeep(r),
                            name: 'School: ' + g.name,
                            searchParams: { ...r.searchParams, school_id: [g.id] },
                            populationParams: { ...r.populationParams, school_id: [g.id] },
                            params: {
                                population: { ...r.params.population, school_id: [g.id] },
                                assessment: { ...r.params.assessment },
                            },
                            summaries: {
                                ...r.summaries,
                                populationSummary: {
                                    ...r.summaries.populationSummary,
                                    school: g.name,
                                }
                            },
                        })
                    })
                })
            },

            byEthnicity() {
                let r = this.sets[0]
                this.sets = []

                this.$modelGet('ethnicity', { active: 1 }).then(resp => {
                    // remove duplicate display_names (for groups)
                    let options = resp.map(({ display_name }) => display_name);
                    resp = resp.filter(({ display_name }, index) =>
                        !options.includes(display_name, index + 1));

                    resp.forEach(g => {
                        this.sets.push({
                            ...this.$_.cloneDeep(r),
                            name: 'Ethnicity: ' + (g.display_name || g.value),
                            searchParams: { ...r.searchParams, ethnicity: ([g.display_name_group].toString() || g.value) },
                            populationParams: {
                                ...r.populationParams,
                                advancedFilters: {
                                    ...r.populationParams.advancedFilters,
                                    ethnicity: ([g.display_name_group].toString() || g.value),
                                },
                            },
                            params: {
                                population: {
                                    ...r.params.population,
                                    advancedFilters: {
                                        ...r.params.population.advancedFilters,
                                        ethnicity: ([g.display_name_group].toString() || g.value),
                                    },
                                },
                                assessment: { ...r.params.assessment },
                            },
                            summaries: { ...r.summaries },
                        })
                    })
                })
            },

            byGender() {
                let r = this.sets[0]
                this.sets = []

                this.$modelGet('gender', { active: 1 }).then(resp => {
                    // remove duplicate display_names (for groups)
                    let options = resp.map(({ display_name }) => display_name);
                    resp = resp.filter(({ display_name }, index) =>
                        !options.includes(display_name, index + 1));

                    resp.forEach(g => {
                        this.sets.push({
                            ...this.$_.cloneDeep(r),
                            name: 'Gender: ' + (g.display_name || g.value),
                            searchParams: { ...r.searchParams, gender: ([g.display_name_group].toString() || g.value) },
                            populationParams: {
                                ...r.populationParams,
                                advancedFilters: {
                                    ...r.populationParams.advancedFilters,
                                    gender: ([g.display_name_group].toString() || g.value),
                                },
                            },
                            params: {
                                population: {
                                    ...r.params.population,
                                    advancedFilters: {
                                        ...r.params.population.advancedFilters,
                                        gender: ([g.display_name_group].toString() || g.value),
                                    },
                                },
                                assessment: { ...r.params.assessment },
                            },
                            summaries: { ...r.summaries },
                        })
                    })
                })
            },
            updateReportBy(v) {
                this.reportBy = v
            },

            addCustom() {
                this.sets.push({
                    name: 'Data Set ' + (this.sets.length+1),
                    params: {
                        population: {},
                        assessment: {}
                    }
                })
            },

            rename(index) {
                let rec = this.sets[index]
                this.renameDialog.show = true
                this.renameDialog.index = index
                this.renameDialog.rec = {...rec}
            },

            finishRename() {
                let rec = this.sets[this.renameDialog.index]
                rec.name = this.renameDialog.rec.name
            },

            remove(index) {
                this.sets.splice(index, 1)
            },

            replicate(index) {
                this.sets = [
                    ...this.sets,
                    {
                        ...JSON.parse(JSON.stringify(this.sets[index])),
                        name: `Data Set ${this.sets.length + 1}` // just give it a unique name - everything else gets cloned
                    }
                ]
            },

            cancel() {
                // let i = this.sets.findIndex(r=>r.name === this.editing.name)
                // if (i==-1) return
                // this.sets.splice(i, 1)
                this.editing = null
            },

            async emit() {
                if (this.$hasSecurity('VIEW_EMBARGOED_DATA')) {
                    // Longitudinals are a little more work because they have
                    // unlimited, separate data sets and we have to scour for all
                    // unique data point vars
                    let setsParams = this.sets.map(set => ({
                        school_year_id: set.populationParams.school_year_id,
                        data_point_type_id: set.assessmentParams.data_point_type_id,
                        sub_category_id: set.assessmentParams.sub_category_id,
                        data_point_name_id: set.assessmentParams.data_point_name_id,
                    }))

                    let distinctSetsParams = this.$_.uniqBy(setsParams, itm => JSON.stringify(itm))
                    let promises = distinctSetsParams.map(params => {
                        return this.$axios.get(`targetScores.php?action=get&property=data_point_embargoes&` + this.$objectToParams(params)).then(res => res.data.data_point_embargo)
                    })

                    let promiseResults = await Promise.all(promises)

                    // Separate data sets may have overlaps (e.g. both include FAST 2020SY and
                    // that has embargoes) so make sure to take distinct by embargo id
                    let distinctEmbargoes = this.$_.uniqBy(this.$_.flatten(promiseResults), 'id')

                    if (distinctEmbargoes.length > 0) {
                        this.showEmbargoAcceptDialog = true
                        this.embargoes = distinctEmbargoes
                    } else {
                        this.doSearch()
                    }
                } else {
                    this.doSearch()
                }
            },

            onAcceptEmbargo() {
                this.showEmbargoAcceptDialog = false
                this.doSearch()
            },

            async doSearch() {
                let me = this
                let transactions = []

                this.chartData = []

                // prefer to sort chronologically by school year
                // this does not necessarily handle cases where data set 1 has 2018, 2020
                // and data set 2 has 2019 - seems like an edge case though, and merely results
                // in x axis disorder (data remains accurate)
                let sortedSets = [...this.sets].sort((a, b) => {
                    return a.searchParams.school_year_id[0] - b.searchParams.school_year_id[0]
                })

                let savedSearchParams = []
                let dataSetSummaries = []

                for (let set of sortedSets) {
                    let groupId = this.UUIDv4()

                    // Summary data must be stored in the config_json because its length
                    // may easily exceed the 50 char limit of a saved search arg
                    dataSetSummaries.push({ groupId: groupId, summaries: set.summaries })

                    // If user drills down into the dialog where they pick data, then it'll
                    // ultimately inject advancedFilters into populationParams (this is a bit
                    // of automagic, regrettably).  However, if user is simply loading up an
                    // existing saved search, then that automagic doesn't happen; we DO HAVE
                    // THE DATA, but we need to pull it from the original source.
                    //
                    // Note that we prefer the "from the drilldown" data, because user may have
                    // made edits to it.
                    let advancedFilters = set.populationParams?.advancedFilters || set.params?.population?.advancedFilters
                    if (advancedFilters) this.format(advancedFilters) // handle display_name

                    savedSearchParams.push({ ...set.populationParams, data_set_name: set.name, saved_search_group_id: groupId, saved_search_group_name: 'population' })
                    if (advancedFilters) {
                        let obj = ({ ...advancedFilters, ...set.populationParams?.advancedFilters?.demographics, saved_search_group_name: 'advanced-filters'})
                        delete obj.demographics // all advanced demos get moved to top level to be saved as flat saved search
                        obj.group_by = ['grade'] // whatever... fix later...
                        savedSearchParams.push({ ...obj, saved_search_group_id: groupId })
                    }

                    savedSearchParams.push({ ...set.assessmentParams, saved_search_group_id: groupId, saved_search_group_name: 'assessment' })
                }

                if (this.state?.windowUUID) {
                    this.$store.commit('global/removeDockedWindow', this.$store.getters['global/dockableWindows'].find(w => w.uuid === this.state?.windowUUID))
                }

                this.$setLoading(true)
                await Util.doLongitudinalGraph(
                    this,
                    {
                        savedSearchParamsArray: savedSearchParams,
                        configJSON: {
                            scope: 'district',
                            component: 'longitudinal',
                            dataSetSummaries: dataSetSummaries,
                        },
                    },
                    'district',
                    null,
                    { ...this.state, isDirty: true }
                )
                this.$setLoading(false)
                //targetScores.php?action=get&property=student_trendline&chart_type=LINE&regression=1&series_name=Line%201&school_year_id=17%2C16%2C15%2C14&data_point_type_id=34&sub_category_id=1330&data_point_name_id=10164%2C10165

                this.$emit('close')
            },

            format(p) {
                if (p.disability_type_id && p.disability_type_id[0].allIds) {
                    p.disability_type_id = p.disability_type_id[0].allIds
                    p.disability_code = 'ALLDIS' // use for stepper display if All Disabilities was chosen
                }
                if (p.meal_status_id && p.meal_status_id[0].allIds) {
                    p.meal_status_id = p.meal_status_id[0].allIds
                    p.status_code = 'ALLSTATUS' // use for stepper display if Any Status was chosen
                }
                if (p.intervention_level && p.intervention_level[0].allIds) {
                    p.intervention_level = p.intervention_level[0].allIds
                    p.intervention_code = 'ALLINT' // use for stepper display if All Interventions was chosen
                }

                // HYBRID DEMOS BACKEND PAYLOAD
                // make sure we're sending values to BE for queries (not groups or display_names)
                // ETHNICITY
                if (p.ethnicity) {
                    let ethnicities = ''
                    let selected = p.ethnicity.split(',')
                    this.ethnicity.forEach(x => {
                        if (selected.indexOf(x.display_name) > -1 || selected.indexOf(x.value) > -1) {
                            if (x.display_name_group) {
                                x.display_name_group.forEach(n => {
                                    if (ethnicities.indexOf(n) === -1) {
                                        ethnicities += n + ',' // add name if it doesn't exist in list
                                    }
                                })
                            } else {
                                ethnicities += x.value + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = ethnicities.split(',')
                    tmp = [...new Set(tmp)]
                    p.ethnicity = tmp.slice(0, -1).join(',')
                }
                // GENDER
                if (p.gender) {
                    let genders = ''
                    let selected = p.gender.split(',')
                    this.gender.forEach(x => {
                        if (selected.indexOf(x.display_name) > -1 || selected.indexOf(x.value) > -1) {
                            if (x.display_name_group) {
                                x.display_name_group.forEach(n => {
                                    if (genders.indexOf(n) === -1) {
                                        genders += n + ',' // add name if it doesn't exist in list
                                    }
                                })
                            } else {
                                genders += x.value + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = genders.split(',')
                    tmp = [...new Set(tmp)]
                    p.gender = tmp.slice(0, -1).join(',')
                }
                // DISABILITY
                if (
                    p.disability_type_id &&
                    p.disability_code !== 'ALLDIS' &&
                    p.disability_code !== 'NODIS' &&
                    p.disability_type_id[0].id !== -1 &&
                    p.disability_type_id[0].id !== 0
                ) {
                    let disabilities = ''
                    let selected = p.disability_type_id.split(',')
                    this.disability.forEach(x => {
                        if (selected.indexOf(x.id.toString()) > -1) {
                            if (x.display_name_group) {
                                disabilities += x.display_name_group.toString() + ','
                            } else {
                                disabilities += x.id + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = disabilities.split(',')
                    tmp = [...new Set(tmp)]
                    p.disability_type_id = tmp.slice(0, -1).join(',')
                } else if (p.disability_code === 'ALLDIS' || (p.disability_type_id && p.disability_type_id[0].code === 'ALLDIS')) {
                    p.disability_code = 'ALLDIS'
                }
                // ELP LEVEL
                if (p.ell_level_id) {
                    let levels = []
                    let selected = p.ell_level_id
                    this.elpLevel.forEach(x => {
                        if (selected.indexOf(x.id) > -1) {
                            if (x.display_name_group) {
                                levels = levels.concat(x.display_name_group)
                            } else {
                                levels.push(x.id)
                            }
                        }
                    })
                    levels = [...new Set(levels)] // remove duplicates
                    p.ell_level_id = levels.slice().join(',') // .slice.join prevents infinite loop
                }
                // MEAL STATUS
                if (
                    p.meal_status_id &&
                    p.status_code !== 'ALLSTATUS' &&
                    p.status_code !== 'NOSTATUS' &&
                    p.meal_status_id[0].id !== -1 &&
                    p.meal_status_id[0].id !== 0
                ) {
                    let status = ''
                    let selected = p.meal_status_id.split(',')
                    this.mealStatus.forEach(x => {
                        if (selected.indexOf(x.id.toString()) > -1) {
                            if (x.display_name_group) {
                                status += x.display_name_group.toString() + ','
                            } else {
                                status += x.id + ','
                            }
                        }
                    })
                    // remove duplicates
                    let tmp = status.split(',')
                    tmp = [...new Set(tmp)]
                    p.meal_status_id = tmp.slice(0, -1).join(',')
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.data-set-container {
    background: #FFFFFF;
    border: 1px solid #E0E1E6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px 16px 6px 16px;
    transition: all .4s;

    &:hover {
        cursor: pointer;
        box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.ds-description {
    color: #7E8494;
    font-size: 14px;
}
</style>
