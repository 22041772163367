<template>
    <div style="width: 100%; height: 100%;">
        <ag-grid-vue
            class="ag-theme-alpine guardian-emails-grid"
            style="width: 100%; height: 500px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
        ></ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";

export default {
    components: {
        AgGridVue,
    },
    data() {
        const today = new Date();
        const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;

        return {
            columnDefs: [
                { headerName: 'Last Sent', field: 'lastSent', flex: 0.5, minWidth: 10, sortable: true },
                { headerName: 'Sent By', field: 'sentBy', flex: 1, sortable: true },
                { headerName: 'Template', field: 'template', flex: 1, sortable: true },
                {
                    headerName: 'Download Response Report',
                    headerTooltip: "Download a CSV report to view a list of student guardians, the delivery status, and whether a response has been received.",
                    field: 'downloadResponseReport',
                    cellRenderer: v => {
                        return '<div style="color: #006C90;"><i class="fas fa-arrow-to-bottom"></i> Download Report</div>'
                    },
                    flex: 1,
                    headerComponentParams: {
                        template: '<div>Download Response Report <i style="margin-left: 4px; color: #495B8F;" class="fas fa-info-circle"></i></div>',
                    },
                    sortable: true,
                },
            ],
            rowData: [
                {
                    lastSent: formattedDate,
                    sentBy: 'Cobb, Josephine',
                    template: 'FPS | Reading Support Plan',
                },
                {
                    lastSent: '08/12/24',
                    sentBy: 'Cobb, Josephine',
                    template: 'Mid Year Attendance',
                },
                {
                    lastSent: '08/13/24',
                    sentBy: 'Cobb, Josephine',
                    template: 'aReading Results',
                },
                {
                    lastSent: '08/15/24',
                    sentBy: 'Howlett, Wade',
                    template: 'FPS | Reading Support Plan',
                },
                {
                    lastSent: '08/12/24',
                    sentBy: 'Howlett, Wade',
                    template: 'Mid Year Attendance',
                },
                {
                    lastSent: '08/13/24',
                    sentBy: 'Cobb, Josephine',
                    template: 'aReading Results',
                },
                {
                    lastSent: '08/15/24',
                    sentBy: 'Wilson, Logan',
                    template: 'Mid Year Attendance',
                },
                {
                    lastSent: '08/15/24',
                    sentBy: 'Howlett, Wade',
                    template: 'FPS | Reading Support Plan',
                },
                {
                    lastSent: '08/12/24',
                    sentBy: 'Evans, Sandra',
                    template: 'Mid Year Attendance',
                },
                {
                    lastSent: '08/13/24',
                    sentBy: 'Evans, Sandra',
                    template: 'aReading Results',
                },
            ],
        };
    }
};
</script>

<style>
.guardian-emails-grid .ag-cell,
.guardian-emails-grid .ag-header-cell {
    border-bottom: 1px solid #E0E1E6;
    padding-left: 8px;
    align-content: center;
}

.guardian-emails-grid .ag-root-wrapper {
    border: 1px solid #E0E1E6;
    border-radius: 8px;
}

.guardian-emails-grid .ag-tooltip {
    max-width: 200px;
}
</style>
